/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./formatter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import XMLViewer from "react-xml-viewer";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { getSolution } from "./langchain";
import { Helmet } from "react-helmet";
import { BsStars } from "react-icons/bs";
const XMLFormatter = () => {
  // form validation
  const FormValidationSchema = yup.object({
    inputXmlData: yup.string().notRequired("Json data is required"),
    inputXmlFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    indentationLevel: yup
      .string()
      .required("Incantational level  is required"),
    textMode: yup.string().required("Bracket Style is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputXmlData: "",
      inputXmlFile: "",
      fileEncoding: "UTF-8",
      indentationLevel: "THREE_SPACES",
      textMode: "TRIM_FULL_WHITE",
    },
    resolver: yupResolver(FormValidationSchema),
  });

  const copyJson = () => {
    navigator.clipboard.writeText(formattedJsonValue).then(
      () => {
        toast.success("JSON copied to clipboard");
      },
      () => {
        toast.warning("Failed to copy JSON to clipboard");
      }
    );
  };

    const customTheme = {
      attributeKeyColor: "#0074D9",
      attributeValueColor: "#2ECC40",
    };
  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "formattedXml.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);
setFormattedJsonValue("");
    try {
      // if (data?.inputXmlData && data?.inputXmlFile) {
      //   toast.warning("You need to upload file or use copy Paste only");
      // } else 
      if (data?.inputXmlFile) {
        console.log("I am from File api call")
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputXmlFile", data.inputXmlFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("indentationLevel", data.indentationLevel);
        formData.append("textMode", data.textMode);

        try {
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/formatter/xml-format`, formData, config)
            .then((res) => {
              console.log("response", res);
              if (res.response.data.errorMessage) {
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(res.data.errorMessage);
                return
              }
              setError(false);
              setFormattedJsonValue(res.data.formattedXml);
              setSuccess(true);
              setSuccessMessage("Formatting Done Successfully!");
                //  reset();
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response

                setError(true);
                  setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
                  
              } else {
                // Display a generic error message
                toast.error("An error occurred while processing your request.");
              }
               document.getElementById("editor_main_id")?.scrollIntoView({
                 behavior: "smooth",
                 block: "start",
               });
            });
        } catch (error) {
          console.log("error1", error);
          toast.warning("Something went wrong!");
        }
      } else {
             console.log("I am not from File api call");
        try {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/formatter/xml-format`,
              data
            )
            .then((res) => {
              console.log("response", res);
              
              setError(false);
              setFormattedJsonValue(res.data.formattedXml);
              setSuccess(true);
              setSuccessMessage("Formatting Done Successfully!");
                //  reset();
            

              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response

                setError(true);
                  setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
              } else {
                // Display a generic error message
                toast.error("An error occurred while processing your request.");
              }
               document.getElementById("editor_main_id")?.scrollIntoView({
                 behavior: "smooth",
                 block: "start",
               });
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
           document.getElementById("editor_main_id")?.scrollIntoView({
             behavior: "smooth",
             block: "start",
           });
        }
      }
    } catch (error) {
      console.log("error", error);
       document.getElementById("editor_main_id")?.scrollIntoView({
         behavior: "smooth",
         block: "start",
       });
    }
  };

  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
    setValue("indentationLevel", "THREE_SPACES");
    setValue("textMode", "TRIM_FULL_WHITE");
  }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");
    const htmlContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>HTML Code Viewer</title>
      </head>
      <body>
        <pre id="htmlCode"></pre>
      </body>
      
    </html>
  `;

    // Write the HTML content to the new tab
    newTab.document.write(htmlContent);

    // Get the pre element in the new tab
    const preElement = newTab.document.getElementById("htmlCode");

    // Set the text content of the pre element to the formatted JSON value
    preElement.textContent = formattedJsonValue;
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputXmlFile", selectedFile);
   
      setFile(selectedFile?.name);
          setInputJsonValueFile(selectedFile);
    };
    console.log("fileee", file);

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".xml"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
  const textModeStyleOptions = [
    {
      label: "Normalize (trim and normalize spaces to a single space)",
      value: "NORMALIZE",
    },

    {
      label: "Trim (left and right trimming)",
      value: "TRIM_FULL_WHITE",
    },
    {
      label:
        "Trim whitespace (trim only if whitespace, otherwise keep literal)",
      value: "TRIM",
    },
  ];
  const indentationLevelOptions = [
    {
      label: "2 spaces per indent level",
      value: "TWO_SPACES",
    },
    {
      label: "3 spaces per indent level",
      value: "THREE_SPACES",
    },
    {
      label: "4 spaces per indent level",
      value: "FOUR_SPACES",
    },
    {
      label: "Compact mode",
      value: "COMPACT",
    },
    {
      label: "Tab delimited",
      value: "TABS",
    },
  ];

  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  // };
  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     // const color = chroma(data.color);
  //     console.log({ data, isDisabled, isFocused, isSelected });
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? "#c00eae" : null,
  //       color: isFocused ? "#fff" : null,
  //     };
  //   },
  // };
   const styles = {
     option: (provided, state) => ({
       ...provided,
       fontSize: "14px",
     }),
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       // const color = chroma(data.color);

       return {
         ...styles,
         // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isSelected
         //   ? "#c00eae"
         //   : isFocused
         //   ? "$c00eae"
         //   : undefined,
         backgroundColor: isDisabled
           ? undefined
           : isFocused
           ? "#BF66B6"
           : isSelected
           ? "#c00eae"
           : undefined,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isFocused || isSelected
         //   ? "#c00eae"
         //   : undefined,
         // color: isFocused || isSelected ? "#fff" : "#000",
         color: isDisabled
           ? "#ccc"
           : isFocused || isSelected
           ? "white"
           : data.color,
         ":active": {
           ...styles[":active"],
           backgroundColor: !isDisabled
             ? isSelected
               ? "#c00eae"
               : "#BF66B6"
             : undefined,
         },
       };
     },
   };
   

  const [fileEncodingValue, setFileEncodingValue] = useState(
    fileEncodingOptions[0]
  );
  const [indentationLevelValue, setIndentationLevelValue] = useState(
    indentationLevelOptions[1]
  );
    const [showGenerativeAiButton, setShowGenerativeAiButton] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bracketStyleValue, setBracketStyleValue] = useState(
    textModeStyleOptions[1]
  );
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputXmlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };
const [loading, setLoading] = useState(false);

   const fetchSolution = async () => {
     let inputJson = "";
     try {
       if (inputJsonValueFile) {
         setLoading(true);
         console.log("inputJsonValueFile", inputJsonValueFile);
         const file = inputJsonValueFile;
         const reader = new FileReader();
         reader.onload = async (e) => {
           inputJson = e.target.result;
           console.log("inputJson", inputJson);
           const solution = await getSolution(`Correct this XML ${inputJson}`);
           setFormattedJsonValue(solution);
           setLoading(false);
           setError(false);
         };
         reader.readAsText(file);
       } else {
         setLoading(true);

         // If no file is provided, use a default question or handle the case accordingly
         inputJson = inputJsonValue;
         console.log("inputJson", inputJson);
         const solution = await getSolution(
           `Correct this XML ${inputJsonValue}`
         );

         setFormattedJsonValue(solution);
         setLoading(false);
         setError(false);
       }

       // const ans = await getSolution("Write a code on sum of 2 numbers in js");
       // console.log("langchain.ans", ans);
     } catch (error) {
       console.error("Error fetching solution:", error);
       document.getElementById("editor_main_id")?.scrollIntoView({
         behavior: "smooth",
         block: "start",
       });
     }
   };


  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>Free XML File Formatter & Beautifier Online | SDE Tools</title>

        <meta
          name="description"
          content="Beautify your XML file with the free online XML Formatter from SDE Tools. Effortlessly minify your XML file and improve code readability with this tool "
        />
        <meta
          name="keywords"
          content="XML Formatter, XML Beautify, Free Online XML Formatter"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/formatter/xml-formatter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Free XML File Formatter & Beautifier Online | SDE Tools"
        />
        <meta
          property="og:description"
          content="Beautify your XML file with the free online XML Formatter from SDE Tools. Effortlessly minify your XML file and improve code readability with this tool "
        />
        <meta
          property="og:url"
          content="https://sdetools.io/formatter/xml-formatter"
        />
      </Helmet>

      {/* {error && (
        <>
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai ">
              <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
                Format XML Using Generative Ai
              </button>
            </div>
          )}
        </>
      )} */}
      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />
      {success && (
        <div className="success-message">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste your XML here</label>
              </div>
              <div>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputXmlData", e.target.value);
                  }}
                  placeholder="Copy-paste your XML here"
                  className="text-area-formatter-new-formatter"
                />
              </div>
            </div>
            <div className="col-12 col-md-2 column-2-center mobile-responsive-col left-container-padding">
              <div className="copy-paste ">
                <label>Upload XML file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".xml"
                  inputContent={<CustomInputContent />}
                />
              </div>

              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button className="format-btn" type="submit">
                  Format XML
                </button>
              </div>
            </div>
            <div className="col-12 col-md-5 mobile-responsive-col">
              <div className="row mobile-res-row " id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Formatted XML</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div
                className={`editor-border-new-fomatter ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                {formattedJsonValue && (
                  <div className="max-h-60   react-json-editor ">
                    <XMLViewer
                      xml={formattedJsonValue}
                      theme={customTheme}
                      collapsible
                    />
                  </div>
                )}
              </div>
              {/*<Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
            <div className="button-section row">
              <div className="col-span-1 copy-paste col-12 col-md-4  bg-gray-200 py-2 text-start">
                <label className="label-drop-down">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>
              <div className="col-span-1 copy-paste col-12 col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Text mode</label>
                <Select
                  options={textModeStyleOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={textModeStyleOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setBracketStyleValue(e);
                    setValue("textMode", e.value);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    // setValue("courseName", selectedValues);
                  }}
                  classNamePrefix="select"
                  value={bracketStyleValue}
                />
              </div>
              <div className="col-span-1 copy-paste col-12 col-md-4 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Indentation level</label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);

                    setValue("indentationLevel", e.value);
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="heading-formatter">
          <h1 className="heading-formatter-text">
            Free XML File Formatter & Beautifier Online{" "}
          </h1>
          <p className="heading-paragraph">
            Transform your XML code to a well-structured masterpiece with our
            Free Online XML Formatter. This professional tool empowers
            developers to effortlessly format and validate their XML code,
            ensuring clarity and efficiency. Depending on your selections, it
            indents components automatically, aligns features, and makes sure
            that formatting is unchanged. It assists you in three text modes
            with normalizing spaces to single space, left and right trimming,
            and trimming whitespace. Uses UTF-8 and UTF-16 for encoding the text
            and offers a total of five levels of indentation: 2 spaces, 3
            spaces, 4 spaces, tab delimited, and compact mode.
          </p>
        </div>
        <div className="sub-heading">
          <h2 className="main-heading">Benefits of Our XML Formatter</h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Formatting:
              </h3>
              Paste your XML code into the provided input box, and our tool
              instantly generates a beautifully formatted version with proper
              indentation so you can easily navigate and comprehend the
              hierarchical structure of your data.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Strong Validation:
              </h3>
              Our XML Formatter incorporates strong validation functionalities
              to identify syntax errors and invalid XML structures within your
              code. This proactive approach saves valuable debugging time by
              catching issues early in the development process.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Better Code Readability:
              </h3>
              A well-formatted XML code significantly improves readability, not
              only for you but also for those collaborating on the project.
              Clear indentation and organized structure contribute to better
              code maintainability and prevent potential confusion down the
              line.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Online Accessibility and Free Use:
              </h3>
              Our XML Formatter is readily available online, accessible from any
              device with an internet connection. There's no need for downloads
              or installations; simply copy and paste your code, and receive
              your formatted XML in seconds!
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is XML?</h6>
          <p className="main-heading-paragraph">
            Wrong formatting can make XML (Extensible Markup Language) data hard
            to read and interpret particularly when extensive element stacking
            is involved.It is primarily used to describe data and exchange
            information. With the help of an additional bracket formatting
            feature, line breaks, and consistent indentation, the XML Formatter
            automatically formats unformatted XML code which improves the
            understanding of your data's organization and structure.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why use XML?</h6>

          <ul className="sub-list-ul">
            <li>
              <strong>Maintenance of Code:</strong> As the code is formatted, it
              makes it easier to update and alter your XML data later on.
            </li>
            <li>
              <strong> Enhancing Readability:</strong> Due to the properly
              formatted XML, the interpretation of data becomes simple
              particularly for big and complicated documents. When you
              consistently indent your XML data, it becomes easier to see how
              elements relate to each other and to their content.
            </li>
            <li>
              <strong>Minimize Errors:</strong> A well-organized code makes it
              easier to spot mistakes and inconsistencies.
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default XMLFormatter;
