import { createSlice } from "@reduxjs/toolkit";

export const blogSlice = createSlice({
  name: "Blog", // ensure consistency in naming
  initialState: {
    data: [],
    editData: [],
    isEditModalOpen: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEditModalOpen: (state, action) => {
      state.isEditModalOpen = action.payload;
    },
    setEditBlogData: (state, action) => {
      state.editData = action.payload;
    },
  },
});

export const { setData, setEditBlogData, setEditModalOpen } = blogSlice.actions;
export default blogSlice.reducer;
