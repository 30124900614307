import React from "react";
import { useLocation } from "react-router-dom";

const JSONtoXMLconverter = () => {
  const location = useLocation();
  // Retrieve the formattedXml data from props
  const formattedXml = location.state?.htmlContent;
console.log("formattedXml", formattedXml);
  return (
    <div>
      {/* Render the XML content */}
      <pre>{formattedXml}</pre>
    </div>
  );
};

export default JSONtoXMLconverter;
