/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./converter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactJson from "react-json-view";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { getSolution } from "../formatters/langchain";
import { Helmet } from "react-helmet";


import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/mode-javascript";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/ext-language_tools";

import { BsStars } from "react-icons/bs";
const YAMLtoJSON = () => {
  const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // form validation
  const FormValidationSchema = yup.object({
    inputYamlData: yup.string().notRequired("Json data is required"),
    inputYamlFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup.string().required("Indentation Level  is required"),
    bracketStyle: yup.string().required("Bracket Style Level  is required"),
 
 
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputYamlData: "",
      inputYamlFile: "",
      fileEncoding: "UTF-8",

      incantationalLevel: `THREE_SPACES`,
      bracketStyle: `COLLAPSE`,
    },
    resolver: yupResolver(FormValidationSchema),
  });
  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
  
  
    setValue("incantationalLevel", "THREE_SPACES");
    setValue("bracketStyle", "COLLAPSE");
    
  }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const [indentationLevel, setIndentationLevel] = useState(3);
    const [bracketStylesView, setBracketStylesView] = useState(false);
const copyJson = () => {
  navigator.clipboard
    .writeText(JSON.stringify(formattedJsonValue, null, indentationLevel))
    .then(
      () => {
        if (formattedJsonValue != "") {
          toast.success("JSON copied to clipboard");
        }
      },
      () => {
        if (formattedJsonValue != "") {
          toast.warning("Failed to copy JSON to clipboard");
        }
      }
    );
};

  const downloadJson = () => {
    const jsonBlob = new Blob([JSON.stringify(formattedJsonValue)], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "convertedXml.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  const onSubmit = async (data) => {
    
    setFormattedJsonValue("");

    try {
   if (data?.inputYamlFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputYamlFile", data.inputYamlFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("bracketStyle", data.bracketStyle);

        formData.append("csvHeaderStatus", data.csvHeaderStatus);
        formData.append("emptyValuesOption", data.emptyValuesOption);
        formData.append("csvDelimiter", data.csvDelimiter);
        formData.append("csvQuoteCharacter", data.csvQuoteCharacter);

        try {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/converter/yaml-to-json`,
              formData,
              config
            )
            .then((res) => {
              console.log("response", res);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              // setFormattedJsonValue(res.data.data);
                //  setFormattedJsonValue(JSON.parse(res?.data?.data));
                if (indentationLevel == 10 || indentationLevel == 9) {
                 
                  setFormattedJsonValue(res?.data?.data);
                } else {
                   
                  setFormattedJsonValue(JSON.parse(res?.data?.data));
                }
                setSuccess(true);
                setError(false)
                setSuccessMessage("Conversion Done Successfully!");
                  //  reset();
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                setShowGenerativeAiButton(true);
                  setSuccess(false);

                // setErrorMessage(error.response.data);
                    const errorMessage =
                      typeof error.response.data === "object" &&
                      error.response.data.errorMessage
                        ? error.response.data.errorMessage
                        : JSON.stringify(error.response.data);
                    setErrorMessage(errorMessage);
              } else {
                // Display a generic error message
                setError(true);
                setShowGenerativeAiButton(true);
                  setSuccess(false);

                // setErrorMessage(error.response.data);
                    const errorMessage =
                      typeof error.response.data === "object" &&
                      error.response.data.errorMessage
                        ? error.response.data.errorMessage
                        : JSON.stringify(error.response.data);
                    setErrorMessage(errorMessage);
              }
            });
        } catch (error) {
          console.log("error1", error);
          // toast.warning("Something went wrong!");
        }
      } else {
        try {
          let formatData = {
            inputYamlData: data.inputYamlData,
          };
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/converter/yaml-to-json`, data)
            .then((res) => {
              console.log("response", res);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              // setFormattedJsonValue(res.data.data);
                //  setFormattedJsonValue(JSON.parse(res?.data?.data));
                if (indentationLevel == 10 || indentationLevel == 9) {
                 
                  setFormattedJsonValue(res?.data?.data);
                } else {
                  
                  setFormattedJsonValue(JSON.parse(res?.data?.data));
                }
               setSuccess(true);

               setSuccessMessage("Conversion Done Successfully!");
              setError(false);
                //  reset();
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                setShowGenerativeAiButton(true);
                  setSuccess(false);

                // setErrorMessage(error.response.data);
                    const errorMessage =
                      typeof error.response.data === "object" &&
                      error.response.data.errorMessage
                        ? error.response.data.errorMessage
                        : JSON.stringify(error.response.data);
                    setErrorMessage(errorMessage);
              } else {
                // Display a generic error message
                // toast.error("An error occurred while processing your request.");
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const XMLtoHTMLConverter = ({ xml }) => {
    console.log("xml", xml);
    // Convert XML to HTML
    const convertToHtml = (xml) => {
      // Your XML to HTML conversion logic here
      return `<div>${xml}</div>`; // For demonstration, just wrapping XML in a <div>
    };

    const htmlContent = convertToHtml(xml);
    console.log("htmlContent", htmlContent);
    // Get the navigate function for programmatic navigation

    // Navigate to a new page with the converted HTML content
    // navigate("/html-page", { state: { htmlContent } });
    <Link
      to={{
        pathname: "/converted",
        state: { htmlContent: formattedJsonValue },
      }}
      target="_blank" // Open the link in a new tab
    ></Link>;

    // This component doesn't render anything directly as it's just for conversion and navigation
    return null;
  };

  const handleClick = () => {
    setRedirectToHtmlPage(true);
  };

  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>XML Converted Page</title>
        </head>
        <body>
        <XMLDisplay/>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(<XMLtoHTMLConverter xml={formattedJsonValue} />);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputYamlFile", selectedFile);
      setFile(selectedFile?.name);
      setInputJsonValueFile(selectedFile);
    };
    console.log("fileee", file);

    if (redirectToHtmlPage) {
      return <XMLtoHTMLConverter xml={formattedJsonValue} />;
    }

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".yaml"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
  const csvQuoteCharacter = [
    {
      label: "Single quote (')",
      value: "SINGLE_QUOTE",
    },
    {
      label: `Double quote (")`,
      value: "DOUBLE_QUOTE",
    },
  ];
  const emptyvaluesOptions = [
    {
      label: "null",
      value: "null",
    },
    {
      label: `Empty strings (key:"")`,
      value: "empty",
    },
  ];
  const jsonBracketStyle = [
    {
      label: "Collapsed (braces on same line)",
      value: "COLLAPSE",
    },
    {
      label: "Expanded (braces on different line)",
      value: "EXPAND",
    },
  ];
  const indentationLevelOptions = [
    {
      label: "2 spaces per indent level",
      value: "TWO_SPACES",
    },
    {
      label: "3 spaces per indent level",
      value: "THREE_SPACES",
    },
    {
      label: "4 spaces per indent level",
      value: "FOUR_SPACES",
    },
    {
      label: "Tab delimited",
      value: "TABS",
    },
    {
      label: "Compact (1 line)",
      value: "COMPACT",
    },
  ];
  const csvfileContainsOptions = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];
  const CSVDelimiterCharacter = [
    {
      label: "Comma(,)",
      value: ",",
    },
    {
      label: "Colon (:)",
      value: ":",
    },
    {
      label: "Semicolon (;)",
      value: ";",
    },
    {
      label: "Dot (.)",
      value: ".",
    },
    {
      label: "Tabs (\t)",
      value: "\t",
    },
  ];

   const styles = {
     option: (provided, state) => ({
       ...provided,
       fontSize: "14px",
     }),
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       // const color = chroma(data.color);

       return {
         ...styles,
         // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isSelected
         //   ? "#c00eae"
         //   : isFocused
         //   ? "$c00eae"
         //   : undefined,
         backgroundColor: isDisabled
           ? undefined
           : isFocused
           ? "#BF66B6"
           : isSelected
           ? "#c00eae"
           : undefined,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isFocused || isSelected
         //   ? "#c00eae"
         //   : undefined,
         // color: isFocused || isSelected ? "#fff" : "#000",
         color: isDisabled
           ? "#ccc"
           : isFocused || isSelected
           ? "white"
           : data.color,
         ":active": {
           ...styles[":active"],
           backgroundColor: !isDisabled
             ? isSelected
               ? "#c00eae"
               : "#BF66B6"
             : undefined,
         },
       };
     },
   };
   

  const [fileEncodingValue, setFileEncodingValue] = useState(fileEncodingOptions[0]);
  const [indentationLevelValue, setIndentationLevelValue] = useState(indentationLevelOptions[1]);
  const [emptyValue, setEmptyValue] = useState();
  const [csvQuoteCharacterValue, setCsvQuoteCharacterValue] = useState();
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

  const [CSVDelimitier, setCSVDelimiter] = useState();
  const [csvHeaderValue, setCsvHeader] = useState();
  const [bracketStyleValue, setBracketStyleValue] = useState(
    jsonBracketStyle[1]
  );
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);
     const [showGenerativeAiButton, setShowGenerativeAiButton] =
       useState(false);
  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputYamlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };
   const [loading, setLoading] = useState(false);

   const fetchSolution = async () => {
     let inputJson = "";
     try {
       if (inputJsonValueFile) {
         setLoading(true);
       
         const file = inputJsonValueFile;
         const reader = new FileReader();
         reader.onload = async (e) => {
           inputJson = e.target.result;
            
           const solution = await getSolution(
             `Convert the following whole with data YAML into JSON and return the JSON only ${inputJson}`
           );
           
           setFormattedJsonValue(JSON.parse(solution));
           setLoading(false);
           setError(false);
         };
         reader.readAsText(file);
       } else {
         setLoading(true);

         // If no file is provided, use a default question or handle the case accordingly
         inputJson = inputJsonValue;
         console.log("inputJson", inputJson);
         const solution = await getSolution(
           `Convert the following YAML into JSON and return the JSON only  ${inputJsonValue}`
         );
       

         setFormattedJsonValue(JSON.parse(solution));
         setLoading(false);
         setError(false);
       }

       // const ans = await getSolution("Write a code on sum of 2 numbers in js");
       // console.log("langchain.ans", ans);
     } catch (error) {
       console.error("Error fetching solution:", error);
       document.getElementById("editor_main_id")?.scrollIntoView({
         behavior: "smooth",
         block: "start",
       });
     }
   };


  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>Convert YAML file to JSON Online | SDE Tools</title>

        <meta
          name="description"
          content="Transform your YAML file data into JSON format with SDE Tools' online converter tool for free! Upload YAML file & convert easily into JSON format now!"
        />
        <meta
          name="keywords"
          content="Online YAML to JSON Converter, YAML to JSON Converter"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/converters/yaml-json-converter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Convert YAML file to JSON Online | SDE Tools"
        />

        <meta
          property="og:description"
          content="Transform your YAML file data into JSON format with SDE Tools' online converter tool for free! Upload YAML file & convert easily into JSON format now!"
        />
        <meta
          property="og:url"
          content="https://sdetools.io/converters/yaml-json-converter"
        />
      </Helmet>

      {/* {error && (
        <>
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai ">
              <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
                Convert Using Generative Ai
              </button>
            </div>
          )}
        </>
      )} */}

      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste your YAML here</label>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputYamlData", e.target.value);
                  }}
                  placeholder="Copy-paste your YAML here"
                  className="text-area-formatter-new"
                />
              </div>

              <div className="button-section-2 row  d-none">
                <div className="col-span-1 col-12 col-md-6   py-2 text-center">
                  <button
                    className="format-btn"
                    onClick={() => setShowFormatNewWindow(false)}
                    type="submit"
                  >
                    Convert YAML to JSON
                  </button>
                </div>
                <div className="col-12 d-none col-md-6 mobile-responsive-view-btn-container">
                  <button
                    onClick={() => {
                      if (formattedJsonValue !== "") {
                        handleClick();

                        setShowFormatNewWindow(true);
                      }
                    }}
                    type="submit"
                    className="col-span-1 format-btn  py-2 text-center"
                  >
                    Convert YAML to JSON in new window
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-2 column-2-center mobile-responsive-col left-container-padding">
              <div className="copy-paste ">
                <label>Upload YAML file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".yaml"
                  inputContent={<CustomInputContent />}
                />
              </div>

              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button className="format-btn" type="submit">
                  Convert
                </button>
              </div>
            </div>

            <div className="col-12 col-md-5 mobile-responsive-col ">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Converted JSON</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div
                className={`editor-border-new ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                {formattedJsonValue && (
                  <div className="max-h-60   react-json-editor ">
                    {indentationLevel != 10 && indentationLevel != 9 ? (
                      // <ReactJson
                      //   displayArrayKey={false}
                      //   enableClipboard={false}
                      //   displayObjectSize={false}
                      //   displayDataTypes={false}
                      //   indentWidth={indentationLevel || 3}
                      //   src={formattedJsonValue}
                      //   collapsed={bracketStylesView}
                      //   name={false}
                      // />

                      <AceEditor
                        placeholder="Placeholder Text"
                        mode="json"
                        theme="textmate"
                        name="blah2"
                        readOnly
                        wrapEnabled={bracketStylesView}
                        fontSize={24}
                        lineHeight={40}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={false}
                        value={`${
                          formattedJsonValue
                            ? JSON?.stringify(formattedJsonValue, null, 2)
                            : ""
                        }`}
                        setOptions={{
                          enableBasicAutocompletion: false,
                          enableLiveAutocompletion: false,
                          enableSnippets: true,
                          enableMobileMenu: true,
                          showLineNumbers: true,
                          highlightGutterLine: false,
                          cursorStyle: "slim",
                          tabSize: 2,
                        }}
                      />
                    ) : (
                      <Editor
                        value={formattedJsonValue}
                        onValueChange={(code) => setFormattedJsonValue(code)}
                        highlight={(code) => highlight(code, languages.js)}
                        padding={10}
                        readOnly
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                          // border: "2px solid ",
                          borderColor: "#e4e4e4",
                          borderRadius: "10px",
                          minHeight: "80%",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* <Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
            <div className="button-section row">
              <div className="col-span-1 col-12 col-md-4 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>

              <div className="col-span-1 col-12 col-md-4 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Indentation level</label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);

                    setValue("incantationalLevel", e.value);
                    setFormattedJsonValue("");
                    setSuccess(false);
                    setValue("incantationalLevel", e.value);
                    switch (e.value) {
                      case "TWO_SPACES":
                        setIndentationLevel(2);
                        break;
                      case "THREE_SPACES":
                        setIndentationLevel(3);
                        break;
                      case "FOUR_SPACES":
                        setIndentationLevel(4);
                        break;
                      case "TABS":
                        setIndentationLevel(8);
                        break;
                      case "COMPACT":
                        setIndentationLevel(9);
                        break;
                      case "JAVASCRIPT":
                        setIndentationLevel(10);
                        break;
                      default:
                        setIndentationLevel(3);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
              <div className="col-span-1 col-12 col-md-4 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">JSON Brace style</label>
                <Select
                  options={jsonBracketStyle}
                  isClearable={false}
                  styles={styles}
                  defaultValue={jsonBracketStyle[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setBracketStyleValue(e);

                    setValue("bracketStyle", e.value);
                    switch (e.value) {
                      case "COLLAPSE":
                        setBracketStylesView(true);
                        break;
                      case "EXPAND":
                        setBracketStylesView(false);
                        break;

                      default:
                        setBracketStylesView(true);
                        break;
                    }
                  }}
                  classNamePrefix="select"
                  value={bracketStyleValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h1 className="heading-formatter-text">
              Convert YAML file to JSON Online{" "}
            </h1>
            <p className="heading-paragraph">
              The YAML to JSON Converter easily converts YAML (Yet Another
              Markup Language) data into well-structured JSON (JavaScript Object
              Notation) documents. It generates a similar form in JSON by
              utilizing key-value strings and layered structures and
              interpreting the indentation-based syntax and key-colon language
              of YAML. The tool uses UTF-8 and UTF-16 for encoding the text and
              offers a total of five levels of indentation: 2 spaces, 3 spaces,
              4 spaces, tab delimited, and compact (1 line).
            </p>
          </div>
          <h2 className="main-heading">
            Benefits of Utilizing Our YAML to JSON Converter:
          </h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Conversion:
              </h3>
              Simply paste your YAML code into the provided input field, and our
              tool will instantly generate the equivalent JSON representation.
              No complex setups or technical expertise required – just copy and
              convert!
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Precise Data Mapping:
              </h3>
              Our converter is accurate in meticulously mapping YAML key-value
              pairs and nested structures to their corresponding JSON elements
              and attributes.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Simplified Data Consumption:
              </h3>
              By converting your YAML data to JSON format, you can make it
              readily consumable by a wider range of applications and tools that
              natively support JSON. This facilitates easier data processing and
              communication across systems.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Online Accessibility and Free Use:
              </h3>
              Our YAML to JSON Converter is readily available online, accessible
              from any device with an internet connection. There's no need for
              downloads or installations; simply copy and paste your YAML data,
              and receive your converted JSON in seconds.
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is YAML to JSON Converter?</h6>
          <p className="main-heading-paragraph">
            Although they are popularly used for sharing data, JSON and YAML are
            not the same in terms of structure. This converter fills the space
            by converting YAML data into an equivalent JSON file. It generates a
            similar form in JSON utilizing key-value strings and layered
            structures by interpreting the indentation-based syntax and
            key-colon language of YAML.
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">Why use YAML to JSON Converter?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>It transforms YAML data into easily readable JSON format.</li>
              <li>
                YAML includes a structure that is easily readable by humans
                because of the presence of more indent and spaced format.
              </li>
              <li>
                When it comes to language, YAML is more versatile than JSON.
                With the help of this converter, you may use YAML elements like
                remarks and identities, which are useful for setting up files
                and information.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default YAMLtoJSON;
