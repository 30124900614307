import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { MdFormatIndentIncrease } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { TbFileTypeXml } from "react-icons/tb";
import { TiArrowMinimise } from "react-icons/ti";
import { LuFileJson, LuText } from "react-icons/lu";
import { BsFiletypeHtml } from "react-icons/bs";
import { BsFiletypeSql } from "react-icons/bs";
import { BsFiletypeXml } from "react-icons/bs";
import { BsFiletypeJson } from "react-icons/bs";
import { LuUtilityPole } from "react-icons/lu";
import { BsFiletypeCsv } from "react-icons/bs";
import { SiCss3, SiYaml } from "react-icons/si";
import { CiCalendarDate } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import { RiAiGenerate } from "react-icons/ri";
import { TbArrowsRandom } from "react-icons/tb";
import { MdOutlineDifference } from "react-icons/md";
import { BsArrowRepeat } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { GiStopwatch } from "react-icons/gi";
import { ImStopwatch } from "react-icons/im";
import { SiConvertio } from "react-icons/si";
import { TbJson } from "react-icons/tb";
import { RiTimeZoneLine } from "react-icons/ri";
export const SidebarData = [
  {
    title: "Formatter",
    path: "/overview",
    icon: <MdFormatIndentIncrease />,
    iconClosed: <FaChevronRight />,
    iconOpened: <FaChevronDown />,

    subNav: [
      {
        title: "JSON Formatter",
        path: "/formatter/json-formatter",
        icon: <LuFileJson />,
      },
      {
        title: "XML Formatter",
        path: "/formatter/xml-formatter",
        icon: <BsFiletypeXml />,
      },

      {
        title: "HTML Formatter",
        path: "/formatter/html-formatter",
        icon: <BsFiletypeHtml />,
      },
      {
        title: "SQL Formatter",
        path: "/formatter/sql-formatter",
        icon: <BsFiletypeSql />,
      },
    ],
  },
  {
    title: "Converters",
    path: "/overview",
    icon: <BsArrowRepeat />,
    iconClosed: <FaChevronRight />,
    iconOpened: <FaChevronDown />,

    subNav: [
      {
        title: "JSON to XML",
        path: "/converters/json-xml-converter",
        icon: <BsFiletypeJson />,
      },
      {
        title: "XML to JSON",
        path: "/converters/xml-json-converter",
        icon: <BsFiletypeXml />,
      },
      {
        title: "CSV to JSON",
        path: "/converters/csv-json-converter",
        icon: <BsFiletypeCsv />,
      },
      {
        title: "CSV to XML",
        path: "/converters/csv-xml-converter",
        icon: <BsFiletypeXml />,
      },
      {
        title: "JSON to YAML",
        path: "/converters/json-yaml-converter",
        icon: <SiYaml />,
      },
      {
        title: "YAML to JSON",
        path: "/converters/yaml-json-converter",
        icon: <BsFiletypeJson />,
      },
    ],
  },
  {
    title: "Generator",
    path: "/overview",
    icon: <RiAiGenerate />,
    iconClosed: <FaChevronRight />,
    iconOpened: <FaChevronDown />,

    subNav: [
      {
        title: "QR Code",
        path: "/generator/qr-code-generator",
        icon: <IoQrCodeOutline />,
      },
      {
        title: "Random Number",
        path: "/generator/random-number-generator",
        icon: <TbArrowsRandom />,
      },
      {
        title: "Difference Checker",
        path: "/generator/difference-checker",
        icon: <MdOutlineDifference />,
      },
      {
        title: "Lorem Ipsum",
        path: "/generator/lorem-ipsum",
        icon: <LuText  />,
      },
    ],
  },
  {
    title: "Date Time",
    path: "/overview",
    icon: <SlCalender />,
    iconClosed: <FaChevronRight />,
    iconOpened: <FaChevronDown />,

    subNav: [
      {
        title: "Date Time Converters",
        path: "/converters/epoch-timestamp-to-date-converter",
        icon: <CiCalendarDate />,
      },

      {
        title: "Online Stop Watch",
        path: "/date-time/online-stop-watch",
        icon: <FaIcons.FaStopwatch20 />,
      },
      {
        title: "Online Counter",
        path: "/date-time/online-count-down",
        icon: <ImStopwatch />,
      },
      {
        title: "Time Zone Converter",
        path: "/date-time/time-zone-converter",
        icon: <RiTimeZoneLine />,
      },
    ],
  },
  {
    title: "Escaper",
    path: "/escaper",
    icon: <LuUtilityPole />,
    iconClosed: <FaChevronRight />,
    iconOpened: <FaChevronDown />,

    subNav: [
      {
        title: "Json Escaper",
        path: "/escaper/json-escaper",
        icon: <TbJson />,
      },

       
    ],
  },
  {
    title: "Minify",
    path: "/minify",
    icon: <TiArrowMinimise />,
    iconClosed: <FaChevronRight />,
    iconOpened: <FaChevronDown />,

    subNav: [
      {
        title: "Css Minify",
        path: "/minify/css-minify",
        icon: <SiCss3 />,
      },
      {
        title: "Js Minify",
        path: "/minify/js-minify",
        icon: <IoIcons.IoLogoJavascript  />,
      },

       
    ],
  },
];
