/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
 
import { Link, useParams } from "react-router-dom";

import "./tag-details.css";
import { CgCopy } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import {
  FaFacebook,
  FaPinterest,
  FaRegClock,
  FaXTwitter,
} from "react-icons/fa6";
import IM from "../../../assets/blog/interview-anxiety-80x80.avif";

import IM1 from "../../../assets/blog/remote-interview-550x350.avif";
import Footer from "../Footer/footer";
import Subscribe from "../subscribe/Subscribe";
import axios from "axios";
import Loader from "../../Loader/Loader";
const Tag = ({ isSidebarOpen }) => {
  const [blogDetails, setBlogDetails] = useState(null);
  const [topOnTheWeek, setTopOnTheWeek] = useState([]);

   

  const { slug } = useParams();
 

const [loading, setLoading] = useState(true);
  
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = window.location.href;
    }
    window.scrollTo(0, 0);
  }, []);

  

   
const fetchData = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/blog/tags/get-blog/${slug}`
    );
    const data = response.data;

    // Format blog details
    setBlogDetails({
      name: data.data.name,
      totalPost: data.data.totalPost,
      blogs: data.data.blogs,
    });

    // Format top on the week data
    setTopOnTheWeek(
      data.data.topOnTheWeeks.map((item, index) => ({
        id: index + 1,
        image: `${process.env.REACT_APP_BACKEND_URL}${item.image}`,
        title: item.title,
        slug: item.slug,
      }))
    );
         setLoading(false);
  } catch (error) {
    console.error("Error fetching data", error);
         setLoading(false);
  }
};
  

   useEffect(() => {
     

     fetchData();
   }, [slug]);
   useEffect(() => {
     

     fetchData();
   }, []);

         if (loading) {
           return <Loader />;
         }
 
  return (
    <>
      <div
        className={`blog-container ${
          isSidebarOpen ? "container" : " container-lg"
        }   `}
      >
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="cs-entry__header cs-entry__header-standard">
              <div className="cs-entry__header-inner">
                <span className="cs-page__subtitle">Browsing Tag</span>
                <h1 className="cs-page__title">{blogDetails?.name}</h1>
                <div className="cs-page__archive-count">
                  {blogDetails?.totalPost} post
                </div>
                <div className="blog-grid">
                  {blogDetails?.blogs.map((article) => (
                    <article key={article.id} className="blog-article">
                      <div className="article-wrapper">
                        <figure>
                          <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_BACKEND_URL}${article.image}`}
                            alt={article.title}
                          />
                        </figure>
                        <div className="article-body">
                          <h6 className="category-title">{article.category}</h6>
                          <h2 className="blog-title">{article.title}</h2>
                          {/*<p
                            className="blog-description"
                            dangerouslySetInnerHTML={{
                              __html: article.description,
                            }}
                          ></p>*/}
                          <p className="blog-description">
                            {article.description.replace(/<\/?[^>]+(>|$)/g, "")}
                          </p>
                          <p className="blog-date">{article.date}</p>
                          <br />
                          <hr />
                          <Link
                            to={`/blog/${article.slug}`}
                            className="read-more"
                          >
                            {article.readTime}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 mt-5 mt-lg-0">
            <aside className="top-on-the-weeks widget">
              <div className="cs-sidebar__inner">
                <h2 className="wp-block-heading is-style-cs-heading-sidebar">
                  Top on the week
                </h2>
                <hr className="mb-5" />
              </div>
              <div className="cs-posts-area__main ">
                {topOnTheWeek.map((article, index) => (
                  <article className="top-on-the-teams-inside" key={article.id}>
                    <div className="cs-entry__outer ">
                      <div className="row">
                        <div className="col-3 left-image">
                          <div className="cs-entry__inner cs-entry__thumbnail">
                            <div className="cs-overlay-background">
                              <img
                                loading="lazy"
                                src={article.image}
                                alt="Thumbnail Image"
                                className="thumbnail-image"
                              />
                              <div className="overlay">
                                <span className="overlay-text">
                                  {index + 1}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <Link to={`/blog/${article?.slug}`}>
                            <h2 className="cs-text-right">
                              <Link>{article.title}</Link>
                            </h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  );
};

export default Tag;
