import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";
import Loader from "../pages/Loader/Loader";

const ProtectedRoute = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <Loader />;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/auth/login" replace />
  );
};

export default ProtectedRoute;
