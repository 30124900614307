import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <div className="cs-footer cs-footer-three">
      <div className="cs-container_new ">
        <div className="cs-footer__item">
          <div className="cs-footer__col cs-col-left">
            <div className="cs-footer__inner">
              <div className="cs-footer__copyright">
                All Rights Reserved © 2024 SDE Tools
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer