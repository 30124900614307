export const timeZones = [
    { label: "IST", value: "Asia/Kolkata" },
  { label: "ACDT", value: "Australia/Adelaide" },
  { label: "ACST", value: "Australia/Darwin" },
  { label: "ACT", value: "America/Eirunepe" },
  { label: "ACWST", value: "Australia/Eucla" },
  { label: "ADT", value: "America/Halifax" },
  { label: "AEDT", value: "Australia/Sydney" },
  { label: "AEST", value: "Australia/Brisbane" },
  { label: "AFT", value: "Asia/Kabul" },
  { label: "AKDT", value: "America/Juneau" },
  { label: "AKST", value: "America/Anchorage" },
  { label: "ALMT", value: "Asia/Almaty" },
  { label: "AMST", value: "America/Campo_Grande" },
  { label: "AMT", value: "America/Boa_Vista" },
  { label: "ANAST", value: "Asia/Anadyr" },
  { label: "ANAT", value: "Asia/Anadyr" },
  { label: "AQTT", value: "Asia/Aqtobe" },
  { label: "ART", value: "America/Argentina/Buenos_Aires" },
  { label: "AST", value: "America/Anguilla" },
  { label: "AWST", value: "Australia/Perth" },
  { label: "AZOST", value: "Atlantic/Azores" },
  { label: "AZOT", value: "Atlantic/Azores" },
  { label: "AZT", value: "Asia/Baku" },
  { label: "BDT", value: "Asia/Dhaka" },
  { label: "BIOT", value: "Indian/Chagos" },
  { label: "BIT", value: "Pacific/Pago_Pago" },
  { label: "BOT", value: "America/La_Paz" },
  { label: "BRST", value: "America/Sao_Paulo" },
  { label: "BRT", value: "America/Sao_Paulo" },
  { label: "BST", value: "Europe/London" },
  { label: "BTT", value: "Asia/Thimphu" },
  { label: "CAT", value: "Africa/Harare" },
  { label: "CCT", value: "Indian/Cocos" },
  { label: "CDT", value: "America/Chicago" },
  { label: "CEST", value: "Europe/Berlin" },
  { label: "CET", value: "Europe/Paris" },
  { label: "CHADT", value: "Pacific/Chatham" },
  { label: "CHAST", value: "Pacific/Chatham" },
  { label: "CHOT", value: "Asia/Choibalsan" },
  { label: "CHOST", value: "Asia/Choibalsan" },
  { label: "CHUT", value: "Pacific/Chuuk" },
  { label: "CIST", value: "Pacific/Rarotonga" },
  { label: "CIT", value: "Asia/Makassar" },
  { label: "CKT", value: "Pacific/Rarotonga" },
  { label: "CLST", value: "America/Santiago" },
  { label: "CLT", value: "America/Santiago" },
  { label: "COST", value: "America/Bogota" },
  { label: "COT", value: "America/Bogota" },
  { label: "CST", value: "America/Chicago" },
  { label: "CT", value: "America/Chicago" },
  { label: "CVT", value: "Atlantic/Cape_Verde" },
  { label: "CWST", value: "Australia/Eucla" },
  { label: "CXT", value: "Indian/Christmas" },
  { label: "DAVT", value: "Antarctica/Davis" },
  { label: "DDUT", value: "Antarctica/DumontDUrville" },
  { label: "DFT", value: "Europe/Paris" },
  { label: "EASST", value: "Pacific/Easter" },
  { label: "EAST", value: "Pacific/Easter" },
  { label: "EAT", value: "Africa/Nairobi" },
  { label: "ECT", value: "America/Guayaquil" },
  { label: "EDT", value: "America/New_York" },
  { label: "EEST", value: "Europe/Kiev" },
  { label: "EET", value: "Europe/Sofia" },
  { label: "EGST", value: "Atlantic/Reykjavik" },
  { label: "EGT", value: "Atlantic/Reykjavik" },
  { label: "EST", value: "America/New_York" },
  { label: "ET", value: "America/New_York" },
  { label: "FET", value: "Europe/Kaliningrad" },
  { label: "FJT", value: "Pacific/Fiji" },
  { label: "FKST", value: "Atlantic/Stanley" },
  { label: "FKT", value: "Atlantic/Stanley" },
  { label: "FNT", value: "America/Noronha" },
  { label: "GALT", value: "Pacific/Galapagos" },
  { label: "GAMT", value: "Pacific/Gambier" },
  { label: "GET", value: "Asia/Tbilisi" },
  { label: "GFT", value: "America/Cayenne" },
  { label: "GILT", value: "Pacific/Tarawa" },
  { label: "GIT", value: "Pacific/Pitcairn" },
  { label: "GMT", value: "Europe/London" },
  { label: "GST", value: "Asia/Dubai" },
  { label: "GYT", value: "America/Guyana" },
  { label: "HDT", value: "Pacific/Honolulu" },
  { label: "HAEC", value: "Europe/Paris" },
  { label: "HST", value: "Pacific/Honolulu" },
  { label: "HKT", value: "Asia/Hong_Kong" },
  { label: "HMT", value: "Indian/Kerguelen" },
  { label: "HOVT", value: "Asia/Hovd" },
  { label: "ICT", value: "Asia/Bangkok" },
  { label: "IDLW", value: "Etc/GMT+12" },
  { label: "IDT", value: "Asia/Jerusalem" },
  { label: "IOT", value: "Indian/Chagos" },
  { label: "IRDT", value: "Asia/Tehran" },
  { label: "IRKT", value: "Asia/Irkutsk" },
  { label: "IRST", value: "Asia/Tehran" },
  { label: "JST", value: "Asia/Tokyo" },
  { label: "KGT", value: "Asia/Bishkek" },
  { label: "KOST", value: "Pacific/Kosrae" },
  { label: "KRAT", value: "Asia/Krasnoyarsk" },
  { label: "KST", value: "Asia/Seoul" },
  { label: "LHST", value: "Australia/Lord_Howe" },
  { label: "LINT", value: "Pacific/Kiritimati" },
  { label: "MAGT", value: "Asia/Magadan" },
  { label: "MART", value: "Pacific/Marquesas" },
  { label: "MAWT", value: "Antarctica/Mawson" },
  { label: "MDT", value: "America/Denver" },
  { label: "MET", value: "Europe/Paris" },
  { label: "MEST", value: "Europe/Paris" },
  { label: "MHT", value: "Pacific/Majuro" },
  { label: "MIST", value: "Antarctica/Macquarie" },
  { label: "MIT", value: "Pacific/Apia" },
  { label: "MMT", value: "Asia/Rangoon" },
  { label: "MSK", value: "Europe/Moscow" },
  { label: "MST", value: "America/Denver" },
  { label: "MT", value: "America/Denver" },
  { label: "MUT", value: "Indian/Mauritius" },
  { label: "MVT", value: "Indian/Maldives" },
  { label: "MYT", value: "Asia/Kuala_Lumpur" },
  { label: "NCT", value: "Pacific/Noumea" },
  { label: "NDT", value: "America/St_Johns" },
  { label: "NFT", value: "Pacific/Norfolk" },
  { label: "NPT", value: "Asia/Kathmandu" },
  { label: "NST", value: "America/St_Johns" },
  { label: "NT", value: "America/St_Johns" },
  { label: "NUT", value: "Pacific/Niue" },
  { label: "NZDT", value: "Pacific/Auckland" },
  { label: "NZST", value: "Pacific/Auckland" },
  { label: "OMST", value: "Asia/Omsk" },
  { label: "ORAT", value: "Asia/Oral" },
  { label: "PDT", value: "America/Los_Angeles" },
  { label: "PET", value: "America/Lima" },
  { label: "PETT", value: "Asia/Kamchatka" },
  { label: "PGT", value: "Pacific/Port_Moresby" },
  { label: "PHOT", value: "Pacific/Enderbury" },
  { label: "PHT", value: "Asia/Manila" },
  { label: "PKT", value: "Asia/Karachi" },
  { label: "PMDT", value: "America/Miquelon" },
  { label: "PMST", value: "America/Miquelon" },
  { label: "PONT", value: "Pacific/Pohnpei" },
  { label: "PST", value: "America/Los_Angeles" },
  { label: "PT", value: "America/Los_Angeles" },
  { label: "PWT", value: "Pacific/Palau" },
  { label: "PYST", value: "America/Asuncion" },
  { label: "PYT", value: "America/Asuncion" },
  { label: "RET", value: "Indian/Reunion" },
  { label: "ROTT", value: "Antarctica/Rothera" },
  { label: "SAKT", value: "Asia/Sakhalin" },
  { label: "SAMT", value: "Europe/Samara" },
  { label: "SAST", value: "Africa/Johannesburg" },
  { label: "SBT", value: "Pacific/Guadalcanal" },
  { label: "SCT", value: "Indian/Mahe" },
  { label: "SDT", value: "Pacific/Pago_Pago" },
  { label: "SGT", value: "Asia/Singapore" },
  { label: "SLST", value: "Asia/Colombo" },
  { label: "SRET", value: "Asia/Srednekolymsk" },
  { label: "SRT", value: "America/Paramaribo" },
  { label: "SST", value: "Pacific/Apia" },
  { label: "SYOT", value: "Antarctica/Syowa" },
  { label: "TAHT", value: "Pacific/Tahiti" },
  { label: "THA", value: "Asia/Bangkok" },
  { label: "TFT", value: "Indian/Kerguelen" },
  { label: "TJT", value: "Asia/Dushanbe" },
  { label: "TKT", value: "Pacific/Fakaofo" },
  { label: "TLT", value: "Asia/Dili" },
  { label: "TMT", value: "Asia/Ashgabat" },
  { label: "TRT", value: "Europe/Istanbul" },
  { label: "TOT", value: "Pacific/Tongatapu" },
  { label: "TVT", value: "Pacific/Funafuti" },
  { label: "ULAST", value: "Asia/Ulaanbaatar" },
  { label: "ULAT", value: "Asia/Ulaanbaatar" },
  { label: "UTC", value: "Etc/UTC" },
  { label: "UYST", value: "America/Montevideo" },
  { label: "UYT", value: "America/Montevideo" },
  { label: "UZT", value: "Asia/Tashkent" },
  { label: "VET", value: "America/Caracas" },
  { label: "VLAT", value: "Asia/Vladivostok" },
  { label: "VOLT", value: "Europe/Volgograd" },
  { label: "VOST", value: "Antarctica/Vostok" },
  { label: "VUT", value: "Pacific/Efate" },
  { label: "WAKT", value: "Pacific/Wake" },
  { label: "WAST", value: "Africa/Windhoek" },
  { label: "WAT", value: "Africa/Lagos" },
  { label: "WEST", value: "Europe/Lisbon" },
  { label: "WET", value: "Europe/Lisbon" },
  { label: "WIB", value: "Asia/Jakarta" },
  { label: "WIT", value: "Asia/Jayapura" },
  { label: "WITA", value: "Asia/Makassar" },
  { label: "WGST", value: "America/Godthab" },
  { label: "WGT", value: "America/Godthab" },
  { label: "WST", value: "Australia/Perth" },
  { label: "YAKT", value: "Asia/Yakutsk" },
  { label: "YEKT", value: "Asia/Yekaterinburg" },
];
