import React, { useEffect, useRef, useState } from 'react'
import "./blog.css"
import IM1 from "../../../assets/blog/remote-interview-550x350.avif"
import IM2 from "../../../assets/blog/software-engineering-roles-550x350.avif";
import IM3 from "../../../assets/blog/personal-branding-550x350.avif"
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "swiper/css/navigation";
  import Loader from "../../Loader/Loader"
import { Navigation } from "swiper/modules";
import { Autoplay } from "swiper/modules";

const BlogSlider = () => {

    const [blogArticles, setBlogArticles] = useState([]);
  const [loading, setLoading] = useState(true);
 

const swiperRef = useRef(null);

const goToNextSlide = () => {
  if (swiperRef.current) {
    swiperRef.current.swiper.slideNext();
  }
};

const goToPrevSlide = () => {
  if (swiperRef.current) {
    swiperRef.current.swiper.slidePrev();
  }
};


 






    
 

     useEffect(() => {
       const fetchLatestBlogs = async () => {
         try {
           const response = await fetch(
             `${process.env.REACT_APP_BACKEND_URL}/blog/latest-blogs`
           );
           const result = await response.json();
           setBlogArticles(result.data);
            setLoading(false); 
         } catch (error) {
           setLoading(false);
           console.error("Error fetching latest blogs:", error);
         }
       };

       fetchLatestBlogs();
     }, []);


     if (loading) {
    return <Loader />;  
  }
  return (
    <div className="articles row">
      <div className={`carousel-container `}>
        <h4
          style={{ textTransform: "uppercase" }}
          className="heading-formatter-text "
        >
          Latest Blogs
        </h4>
        <div className={`mobileSHow mt-4 mb-4 `}>
          <button onClick={goToPrevSlide} className="rotateImage">
            <FaArrowLeft className="arrow-btn" />
          </button>

          <button onClick={goToNextSlide} className="ms-3">
            <FaArrowRight className="arrow-btn" />
          </button>
        </div>
      </div>
      {/*center-article*/}
      <Swiper
        navigation={false}
        className={`swiper-wrapper `}
        speed={3000}
        slidesPerView={3}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        modules={[Autoplay, Navigation]}
        ref={swiperRef}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 20 },
          425: { slidesPerView: 2, spaceBetween: 20 },
          720: { slidesPerView: 3, spaceBetween: 10 },
          1024: { slidesPerView: 3, spaceBetween: 10 },
          1229: { slidesPerView: 3, spaceBetween: 10 },
          1396: { slidesPerView: 3, spaceBetween: 10 },
          1536: { slidesPerView: 3, spaceBetween: 10 },
        }}
      >
        {blogArticles.map((article, index) => (
          <SwiperSlide key={index}>
            <Link to={`/blog/${article?.slug}`} className="read-more">
              <article className="article-width ">
                <div className="article-wrapper">
                  <figure>
                    <img
                      loading="lazy"
                      src={`https://sdetools.io${article.blog_Image}`}
                      alt={article.title}
                    />
                  </figure>
                  <div className="article-body">
                    <h6 className="category-title">{article.category?.name}</h6>
                    <h2 className="blog-title">{article.title}</h2>
                    {/* <p
                      className="blog-description"
                      dangerouslySetInnerHTML={{ __html: article.description }}
                    ></p>*/}
                    <p className="blog-description">
                      {article.description.replace(/<\/?[^>]+(>|$)/g, "")}
                    </p>

                    <p className="blog-date">{article.published_at}</p>
                    <br />
                    <hr />
                    <Link to={`/blog/${article?.slug}`} className="read-more">
                      {article.readTime}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </article>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default BlogSlider