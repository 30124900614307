import React from 'react'
import "./Header.css";
import { SidebarData } from '../SideBar/SidebarData';
import { LiaSearchSolid } from "react-icons/lia";
 
 
import BreadcrumbComponent from './Breadcrumb';
const Header = () => {
  return (
    <div className="header-container">
      <div className="search-box hidden d-none">
        <input type="search" placeholder="Search here" />
        <LiaSearchSolid className="search-box-icons" />
      </div>
      <div>
       
      </div>
    </div>
  );
}

export default Header