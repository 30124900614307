/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./formatter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { MdOutlineClose } from "react-icons/md";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Helmet } from "react-helmet";
import { BsStars } from "react-icons/bs";

import {
  docco,
  dark,
  atelierCaveDark,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { getSolution } from "./langchain";

const HtmlFormatter = () => {
  // form validation
  const FormValidationSchema = yup.object({
    rowHtmlData: yup.string().notRequired("Json data is required"),
    inputHtmlFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
     
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      rowHtmlData: "",
      inputHtmlFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      
    },
    resolver: yupResolver(FormValidationSchema),
  });
const customStyle = {
  lineHeight: "1.5",
  fontSize: "1rem",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  padding: "20px",
};
  const copyJson = () => {
    navigator.clipboard.writeText(formattedJsonValue).then(
      () => {
        toast.success("JSON copied to clipboard");
      },
      () => {
        toast.warning("Failed to copy JSON to clipboard");
      }
    );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/html",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "FormattedHtml.html";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };
const handleButtonClick = () => {
  const newTab = window.open("", "_blank");
  const htmlContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>HTML Code Viewer</title>
      </head>
      <body>
        <pre id="htmlCode"></pre>
      </body>
      
    </html>
  `;

  // Write the HTML content to the new tab
  newTab.document.write(htmlContent);

  // Get the pre element in the new tab
  const preElement = newTab.document.getElementById("htmlCode");

  // Set the text content of the pre element to the formatted JSON value
  preElement.textContent = formattedJsonValue;
};

  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);

    try {
      // if (data?.rowHtmlData && data?.inputHtmlFile) {
      //   toast.warning("You need to upload file or use copy Paste only");
      // } else
      
      if (data?.inputHtmlFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputHtmlFile", data.inputHtmlFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        

        try {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/formatter/html-format`,
              formData,
              config
            )
            .then((res) => {
              console.log("response", res);
  setError(false);
              setFormattedJsonValue(res.data.formattedHtml);
          setSuccess(true);
          setError(false)
                 
          setSuccessMessage("Formatting Done Successfully!");
            //  reset();
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response
                setError(true);
                setSuccess(false);
                         setShowGenerativeAiButton(true);
                setErrorMessage(error.response.data.errorMessage);
              } else {
                // Display a generic error message
                // toast.error("An error occurred while processing your request.");
                setError(true);
                setSuccess(false);
                         setShowGenerativeAiButton(true);
                setErrorMessage(error.response.data.errorMessage);
              }
               document.getElementById("editor_main_id")?.scrollIntoView({
                 behavior: "smooth",
                 block: "start",
               });
            });
        } catch (error) {
          console.log("error1", error);
           document.getElementById("editor_main_id")?.scrollIntoView({
             behavior: "smooth",
             block: "start",
           });
          // toast.warning("Something went wrong!");
        }
      } else {
        try {

          let formattedData = {
            rowHtmlData: data.rowHtmlData,
            incantationalLevel:data.incantationalLevel,
            fileEncoding:data.fileEncoding
          };
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/formatter/html-format`, formattedData)
            .then((res) => {
              console.log("response", res);

              setFormattedJsonValue(res.data.formattedHtml);
              setSuccess(true);
              setSuccessMessage("Formatting Done Successfully!");
              // reset();
                setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response
                setError(true);
                setSuccess(false);
                         setShowGenerativeAiButton(true);
                setErrorMessage(error.response.data.errorMessage);
              } else {
                // Display a generic error message
                // toast.error("An error occurred while processing your request.");
                setError(true);
                         setShowGenerativeAiButton(true);
                setErrorMessage(error.response.data);
                setSuccess(false);
              }
               document.getElementById("editor_main_id")?.scrollIntoView({
                 behavior: "smooth",
                 block: "start",
               });
            });
        } catch (error) {
          console.log("error2", error);
           document.getElementById("editor_main_id")?.scrollIntoView({
             behavior: "smooth",
             block: "start",
           });
          // toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
       document.getElementById("editor_main_id")?.scrollIntoView({
         behavior: "smooth",
         block: "start",
       });
    }
  };

  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
    setValue("incantationalLevel", "THREE_SPACES");
    
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log("errors", errors);
   const CustomInputContent = () => {
     const fileInputRef = useRef(null);

     const [file, setFile] = useState("");
     let fileName;
     const handleBrowseClick = () => {
       fileInputRef.current.click(); // Trigger click event on file input
     };

     const handleFileChange = (e) => {
       // Handle file change event here
       const selectedFile = e.target.files[0];
       console.log("Selected,file", selectedFile.name);
       fileName = selectedFile.name;
       setValue("inputHtmlFile", selectedFile);
       setFile(selectedFile?.name);
       setInputJsonValueFile(selectedFile);
     };
     console.log("fileee", file);

     return (
       <div className="main-container">
         <div className="custom-dnd">
           <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

           <h6 className="custom-input-text">
             Choose a file
           </h6>
         </div>
         <div>
           <button
             type="button"
             onClick={handleBrowseClick}
             className="browse-file-button"
           >
             Browse File
           </button>
           <input
             accept=".html"
             type="file"
             multiple={false}
             ref={fileInputRef}
             style={{ display: "none" }}
             onChange={handleFileChange}
           />
         </div>
         {file && <h6 className="custom-input-text">{file}</h6>}
       </div>
     );
   };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
   
  const indentationLevelOptions = [
    {
      label: "2 spaces per indent level",
      value: "TWO_SPACES",
    },
    {
      label: "3 spaces per indent level",
      value: "THREE_SPACES",
    },
    {
      label: "4 spaces per indent level",
      value: "FOUR_SPACES",
    },
    {
      label: "Tab delimited",
      value: "TABS",
    },
     
  ];

  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  // };

  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     // const color = chroma(data.color);
  //     console.log({ data, isDisabled, isFocused, isSelected });
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? "#c00eae" : null,
  //       color: isFocused ? "#fff" : null,
  //     };
  //   },
  // };
   const styles = {
     option: (provided, state) => ({
       ...provided,
       fontSize: "14px",
     }),
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       // const color = chroma(data.color);

       return {
         ...styles,
         // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isSelected
         //   ? "#c00eae"
         //   : isFocused
         //   ? "$c00eae"
         //   : undefined,
         backgroundColor: isDisabled
           ? undefined
           : isFocused
           ? "#BF66B6"
           : isSelected
           ? "#c00eae"
           : undefined,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isFocused || isSelected
         //   ? "#c00eae"
         //   : undefined,
         // color: isFocused || isSelected ? "#fff" : "#000",
         color: isDisabled
           ? "#ccc"
           : isFocused || isSelected
           ? "white"
           : data.color,
         ":active": {
           ...styles[":active"],
           backgroundColor: !isDisabled
             ? isSelected
               ? "#c00eae"
               : "#BF66B6"
             : undefined,
         },
       };
     },
   };
   
  const [fileEncodingValue, setFileEncodingValue] = useState();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  const [indentationLevelValue, setIndentationLevelValue] = useState();
    const [showGenerativeAiButton, setShowGenerativeAiButton] = useState(false);

  const [bracketStyleValue, setBracketStyleValue] = useState();
  const [inputJsonValue, setInputJsonValue] = useState("");
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputHtmlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "${process.env.REACT_APP_BACKEND_URL}/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };

  // const handleChangeStatus = ({ xhr }) => {
  //   if (xhr) {
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         const result = JSON.parse(xhr.response);
  //         console.log(result);
  //       }
  //     };
  //   }
  // };

  // Function to format JSON data based on selected options

  const [loading, setLoading] = useState(false);

  const fetchSolution = async () => {
    let inputJson = "";
    try {
      if (inputJsonValueFile) {
        setLoading(true);
        console.log("inputJsonValueFile", inputJsonValueFile);
        const file = inputJsonValueFile;
        const reader = new FileReader();
        reader.onload = async (e) => {
          inputJson = e.target.result;
          console.log("inputJson", inputJson);
          const solution = await getSolution(`Correct this HTML ${inputJson}`);
          setFormattedJsonValue(solution);
          setLoading(false);
          setError(false);
        };
        reader.readAsText(file);
      } else {
        setLoading(true);

        // If no file is provided, use a default question or handle the case accordingly
        inputJson = inputJsonValue;
        console.log("inputJson", inputJson);
        const solution = await getSolution(
          `Correct this HTML ${inputJsonValue}`
        );

        setFormattedJsonValue(solution);
        setLoading(false);
        setError(false);
      }

      // const ans = await getSolution("Write a code on sum of 2 numbers in js");
      // console.log("langchain.ans", ans);
    } catch (error) {
      console.error("Error fetching solution:", error);
      document.getElementById("editor_main_id")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>
          Free HTML Code Formatter Online | Beautify Code on SDE Tools
        </title>

        <meta
          name="description"
          content="Clean up your HTML Code with the Free HTML Formatter of SDE Tools. You can beautify your HTML code and enhance readability instantly with this online tool"
        />
        <meta
          name="keywords"
          content="HTML Formatter, HTML Formatter Online, Free HTML Formatter"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/formatter/html-formatter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Free HTML Code Formatter Online | Beautify HTML Code on SDE Tools"
        />
        <meta
          property="og:description"
          content="Clean up your HTML code with the Free HTML Formatter of SDE Tools. You can beautify your HTML code and enhance readability instantly with this online tool"
        />
        <meta
          property="og:url"
          content="https://sdetools.io/formatter/html-formatter"
        />


         
 
 
<meta property="og:description" content="Clean up your HTML Code with the Free HTML Formatter of SDE Tools. You can beautify your HTML code and enhance readability instantly with this online tool"/>
<meta property="og:url" content="https://sdetools.io/formatter/html-formatter"/>
      </Helmet>

      {/* {error && (
        <>
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai d-none">
              <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
                Format HTML Using Generative Ai
              </button>
            </div>
          )}
        </>
      )} */}
      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste your HTML here</label>
              </div>
              <div>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("rowHtmlData", e.target.value);
                  }}
                  placeholder="Copy-paste your HTML here"
                  className="text-area-formatter-new-formatter"
                />
              </div>
            </div>

            <div className="col-12 col-md-2 column-2-center mobile-responsive-col left-container-padding">
              <div className="copy-paste ">
                <label>Upload HTML file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".html"
                  inputContent={<CustomInputContent />}
                />
              </div>

              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button className="format-btn" type="submit">
                  Format HTML
                </button>
              </div>
            </div>
            <div className="col-12 col-md-5 mobile-responsive-col">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Formatted HTML</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div
                className={`editor-border-new-fomatter ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                <SyntaxHighlighter
                  className="syntax-hiligheter-sql"
                  language="html"
                  style={docco}
                  customStyle={customStyle}
                >
                  {formattedJsonValue}
                </SyntaxHighlighter>
              </div>
              {/*<Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
            <div className="row">
              <div className="col-span-full copy-paste col-12 col-md-6 bg-gray-200 py-2 text-start col-4">
                <label className="label-drop-down">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>

              <div className="col-span-1 copy-paste col-12 col-md-6 bg-gray-200 py-2 text-start col-4">
                <label className="label-drop-down">Indentation level</label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    // setValue("courseName", selectedValues);
                    setValue("incantationalLevel", e.value);
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h1 className="heading-formatter-text">
              Free HTML Code Formatter Online{" "}
            </h1>
            <p className="heading-paragraph">
              This intuitive tool beautifies your HTML code to make it easier to
              read. It enables the formatting of text to improve its visual
              attractiveness. Uses UTF-8 and UTF-16 for encoding the text and
              offers a total of four levels of indentation: 2 spaces, 3 spaces,
              4 spaces, and tab delimited.
            </p>
          </div>
          <h2 className="main-heading">
            Benefits of Utilizing Our HTML Formatter:
          </h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Formatting:
              </h3>
              Simply paste your HTML code into the designated input field, and
              our tool will instantly generate a beautifully formatted version
              with proper indentation and line breaks. This enhances readability
              and makes it easier to understand the structure of your web page.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Error Detection and Validation:
              </h3>
              Our HTML Formatter has strong validation functionalities to
              identify syntax errors, broken links, and invalid HTML elements
              within your code.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Improved Code Maintainability:
              </h3>
              Well-formatted HTML code is not only easier to read but also
              significantly easier to maintain and update. Clear indentation and
              organized structure allow you to make changes and additions to
              your code with greater efficiency.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Enhanced SEO Potential:
              </h3>
              Clean and well-structured HTML code can contribute positively to
              your website's search engine optimization (SEO). Search engines
              may favor pages with clear code, potentially improving your
              website's ranking in search results.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Online Accessibility and Free Use:
              </h3>
              Our HTML Formatter is readily available online, accessible from
              any device with an internet connection. There's no need for
              downloads or installations; simply copy and paste your code, and
              receive your formatted HTML in seconds.
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is HTML?</h6>
          <p className="main-heading-paragraph">
            Web page structure and content are determined by HTML (HyperText
            Markup Language) programming. However, just like XML, the HTML code
            can prove to be complicated and challenging to maintain particularly
            for intricate web pages. In order to solve this, the HTML Formatter
            automatically applies uniform formatting styles such as line breaks
            and standard indentation.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why use HTML?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                <strong> Effective Debugging:</strong> You can find and correct
                HTML code mistakes more rapidly when the formatting is clear.
              </li>
              <li>
                <strong>Enhancing Readability: </strong>Due to the properly
                formatted HTML, the interpretation of data becomes simple
                particularly for big and complicated documents. When you
                consistently indent your HTML data, it becomes easier to see how
                elements relate to each other and to their content.
              </li>
              <li>
                <strong>Minimize Errors:</strong> An HTML code that is properly
                placed reduces possible browser rendering errors and assures
                that the code is valid.
              </li>
            </ul>
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HtmlFormatter;
