import React, { useEffect, useState } from 'react'
 
import TableList from './table';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
 
import { Link } from 'react-router-dom';
import { IoAddOutline } from "react-icons/io5";
import "./category.css"
import { setData } from '../../redux/features/tags/tagsSlice';
import Loader from '../../Loader/Loader';
const Tags = () => {
  const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
     const categoryData = useSelector((state) => state.tags.data);
    const dispatch=useDispatch()
  useEffect(() => {
    // Define the async function to fetch data
    const fetchData = async () => {
      try {
        // Use REACT_APP_BACKEND_URL from environment variables
        const url = `${process.env.REACT_APP_BACKEND_URL}/blog/tags`;
        const response = await axios.get(url).then((res)=>{
   
          dispatch(setData(res.data.data));
        })
     
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, []); 

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="container">
        <div className="table-btn">
          <h2 className="title-admin">Tags</h2>
          <Link to={"/admin/tags/add-tag"}>
            <button className="admin-button">
              <IoAddOutline size={25} /> Add
            </button>
          </Link>
        </div>
        <div>
          <TableList data={categoryData} />
        </div>
      </div>
    </div>
  );
}

export default Tags