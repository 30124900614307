import React, { useState } from "react";
import "./login.css"; // Ensure the CSS file is correctly linked
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/Logo/logo.png"
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
const Login = () => {
 const [showPassword, setShowPassword] = useState(false);

 const togglePasswordVisibility = () => {
   setShowPassword(!showPassword);
 };

 const navigte=useNavigate()
   const formik = useFormik({
     initialValues: {
       email: "",
       password: "",
     },
     validationSchema: Yup.object({
       email: Yup.string().required("Email is required"),
       password: Yup.string().required("Password is required"),
     }),
     onSubmit: async(values) => {
      
try {
         
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
          values,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
 const { token, user } = response.data;

 // Store token and user details in session storage
 sessionStorage.setItem("token", token);
 sessionStorage.setItem("user", JSON.stringify(user));

 sessionStorage.setItem("isAuth", "true");

            toast.success("Login Success!");
            navigte("/admin/admin-dashboard");
        // Handle success
        
        // Optionally, you can redirect or show a success message here
      } catch (error) {
        // Handle error
        console.error('Error logging in:', error);
         toast.error("Login failed. Please try again.");
        // Optionally, you can show an error message here
      }


        
     },
   });
  return (
    <div className="container-login">
      <div className="row login-row-class">
        <div className="col-4 col-4-login-card">
          <div className="login-main">
            <div className="login-logo">
              <Link to={"#"}>
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <h2 className="login-text">Login</h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="input-email">
              <input
                className="input-field"
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="password-field">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <button
                type="button"
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FaEyeSlash className="eye-button-password" />
                ) : (
                  <FaEye className="eye-button-password" />
                )}
              </button>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
            <button className="login-btn" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
