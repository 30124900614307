/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setData, setEditData } from "../../../redux/features/tags/tagsSlice";
import Loader from "../../../Loader/Loader";
 
const EditTag = () => {
  const tagsEditData = useSelector((state) => state.tags.editData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);


   const { id } = useParams();

      
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Tags Name is required")
      .min(2, "Tags Name must be at least 2 characters")
      .max(50, "Tags Name must be less than 50 characters"),
  });

  // Define the initial values
  const initialValues = {
    name: tagsEditData?.name || "",
  };
  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/tags`;
      const response = await axios.get(url);
    
      dispatch(setData(response.data.data)); // Assuming setData is an action to update your Redux store
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  // Handle form submission
  const onSubmit = async (values, { resetForm }) => {
    try {
      // Perform the POST request to add a new category
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/blog/tags/${id}`,
          { name: values.name } // Ensure the field name matches the form field
        )
        .then(async (res) => {
          // Notify success and perform additional actions
          toast.success("Tags Updated Successfully!");

          // Fetch updated data
          await fetchData();

          // Navigate to a different route
          navigate("/admin/tags");

          // Reset the form
          resetForm();
        });
    } catch (error) {
      // Handle errors
      console.error("Error adding category:", error);
      let errorMessage = "Something Went Wrong!"; // Default error message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message; // Extract error message from API response
      }

      console.error("Error adding category:", error);
      toast.error(errorMessage);
    }
  };


   useEffect(() => {
     // Define the async function to fetch data
     const fetchDataEdit = async () => {
       try {
         // Use REACT_APP_BACKEND_URL from environment variables
         const url = `${process.env.REACT_APP_BACKEND_URL}/blog/tags/${id}`;
         const response = await axios.get(url).then((res) => {
          //  setFieldValue("name", response.data.data.name || "");
           dispatch(setEditData(res.data.data));
         });
       } catch (err) {
       } finally {
         setLoading(false);
       }
     };

     fetchDataEdit();
   }, []); 

    if (loading) {
      return <Loader />;
    }
  return (
    <div className="container">
      <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm p-4">
        <h3 className="mb-4 title-admin">Edit Tag</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="name ">
                  Tags Name<span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Enter tag name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger mt-1"
                />
              </div>
              <button
                type="submit"
                className="admin-button  mt-3"
                disabled={isSubmitting}
              >
                Update
              </button>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default EditTag;
