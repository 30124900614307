/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, forwardRef } from "react";

import Card from "react-bootstrap/Card";

import TZSelect from "./TZSelect";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "./DateTimeTools.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaRegCalendar } from "react-icons/fa";

import { BsCalendarDate } from "react-icons/bs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
dayjs.extend(utc);
dayjs.extend(timezone);
const estTimezone = "America/New_York";
const estTimezoneTwo = "Asia/Kolkata";
const TimezoneConverter = () => {
  const currentDateTimeInEST = dayjs().tz(estTimezone);
  const currentDateTimeInIST = dayjs().tz(estTimezoneTwo);
  const [timezoneOne, setTimezoneOne] = useState({
    label: "EST",
    value: "America/New_York",
  });
  const [timezoneTwoSelectTwo, setTimezoneSelectTwo] = useState({
    label: "IST",
    value: "Asia/Kolkata",
  });
  const [timezone, setTimezone] = useState("America/New_York");
  const [timezoneTwo, setTimezoneTwo] = useState("Asia/Kolkata");
  const [selectedDate, setSelectedDate] = useState(currentDateTimeInEST);
  const [selectedDateTwo, setSelectedDateTwo] = useState(currentDateTimeInIST);
  const [selectedTime, setSelectedTime] = useState(currentDateTimeInEST);
  const [selectedTimeTwo, setSelectedTimeTwo] = useState(currentDateTimeInIST);

  const handleTimezoneChange = (selectedTimezone) => {
    const currentTimeInNewTimezone = dayjs().tz(selectedTimezone);
    setSelectedDate(currentTimeInNewTimezone);
    setSelectedTime(currentTimeInNewTimezone);
    setTimezone(selectedTimezone);
  };
  const handleTimezoneChangeTwo = (selectedTimezone) => {
    const currentTimeInNewTimezone = dayjs().tz(selectedTimezone);
    setSelectedDateTwo(currentTimeInNewTimezone);
    setSelectedTimeTwo(currentTimeInNewTimezone);
    setTimezoneTwo(selectedTimezone);
  };
useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  const handleTimeChange = (newValue) => {
    // Calculate the time difference in milliseconds between newValue and selectedTime
    const timeDiff = dayjs(newValue).diff(selectedTime, "millisecond");

    // Update selectedTime
    setSelectedTime(newValue);

    // Calculate the equivalent time in timezoneTwo (IST) by adding the time difference
    const selectedTimeTwoAdjusted = dayjs(selectedTimeTwo)
      .add(timeDiff, "millisecond")
      .tz(timezoneTwo);

    // Update selectedTimeTwo to reflect the adjusted time in timezoneTwo
    setSelectedTimeTwo(selectedTimeTwoAdjusted);

    // Check if date needs adjustment
    if (
      dayjs(selectedDateTwo).format("YYYY-MM-DD") !==
      dayjs(selectedTimeTwoAdjusted).format("YYYY-MM-DD")
    ) {
      // Update selectedDateTwo to match selectedTimeTwo's new date
      setSelectedDateTwo(selectedTimeTwoAdjusted);
    }
  };

  const handleTimeChangeTwo = (newValue) => {
    // Calculate the time difference in milliseconds between newValue and selectedTime
    const timeDiff = dayjs(newValue).diff(selectedTimeTwo, "millisecond");

    // Update selectedTime
    setSelectedTimeTwo(newValue);

    // Calculate the equivalent time in timezoneTwo (IST) by adding the time difference
    const selectedTimeTwoAdjusted = dayjs(selectedTime)
      .add(timeDiff, "millisecond")
      .tz(timezone);

    // Update selectedTimeTwo to reflect the adjusted time in timezoneTwo
    setSelectedTime(selectedTimeTwoAdjusted);

    // Check if date needs adjustment
    if (
      dayjs(selectedDate).format("YYYY-MM-DD") !==
      dayjs(selectedTimeTwoAdjusted).format("YYYY-MM-DD")
    ) {
      // Update selectedDateTwo to match selectedTimeTwo's new date
      setSelectedDate(selectedTimeTwoAdjusted);
    }
  };

  const handleDateChange = (newDate, timezoneToUpdate) => {
    // Define the default time to set (8:00 AM)
    const defaultTime = { hour: 8, minute: 0, meridiem: "AM" };

    // Update selectedDate and selectedTime in the appropriate timezone
    if (timezoneToUpdate === timezone) {
      setSelectedDate(newDate);

      // Update selectedTime to default time (8:00 AM)
      setSelectedTime(dayjs(newDate).set(defaultTime));

      // Check if selectedTimeTwo needs to be updated to match current time
      if (
        dayjs(newDate).format("YYYY-MM-DD") !==
        dayjs(selectedDateTwo).format("YYYY-MM-DD")
      ) {
        setSelectedDateTwo(dayjs(newDate).set(defaultTime));
        setSelectedTimeTwo(dayjs(newDate).set(defaultTime));
      }
    } else if (timezoneToUpdate === timezoneTwo) {
      setSelectedDateTwo(newDate);

      // Update selectedTimeTwo to default time (8:00 AM)
      setSelectedTimeTwo(dayjs(newDate).set(defaultTime));

      // Check if selectedTime needs to be updated to match current time
      if (
        dayjs(newDate).format("YYYY-MM-DD") !==
        dayjs(selectedDate).format("YYYY-MM-DD")
      ) {
        setSelectedDate(dayjs(newDate).set(defaultTime));
        setSelectedTime(dayjs(newDate).set(defaultTime));
      }
    }
  };

  return (
    <div className="container">
      <form>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="  col-12 col-md-6 mb-3 mb-md-0">
              <div className="row">
                <div className="border-time-zone">
                  <div className="time-zone-select">
                    <div className="col-sm-12 col-md-12">
                      <label className="d-none">Select Timezone</label>
                      <TZSelect
                        value={timezoneOne}
                        onChange={(value) => {
                          // setTimezone(value);
                          setTimezoneOne(value);
                          handleTimezoneChange(value?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <div className="time-picker-container">
                          <MobileTimePicker
                            className="mobile-time-picker"
                            value={selectedTime}
                            onChange={(newValue) => handleTimeChange(newValue)}
                          />
                          <MdKeyboardArrowDown className="arrow-icon" />
                        </div>
                      </DemoItem>
                    </LocalizationProvider>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <div className="time-picker-container container-date-time">
                          <MobileDatePicker
                            className="mobile-date-picker"
                            value={selectedDate}
                            onChange={(newValue) =>
                              handleDateChange(newValue, timezone)
                            }
                          />
                          <FaRegCalendar className="callender-icon" />
                        </div>
                      </DemoItem>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="border-time-zone">
                  <div className="time-zone-select">
                    <div className="col-sm-12 col-md-12">
                      <label className="d-none">Select Timezone</label>
                      <TZSelect
                        value={timezoneTwoSelectTwo}
                        onChange={(value) => {
                          setTimezoneSelectTwo(value);
                          handleTimezoneChangeTwo(value?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <div clssName="time-picker-container  mobile-time-picker-two">
                          <MobileTimePicker
                            className="mobile-time-picker"
                            value={selectedTimeTwo}
                            onChange={(newValue) =>
                              handleTimeChangeTwo(newValue)
                            }
                          />
                          <MdKeyboardArrowDown className="arrow-icon-two" />
                        </div>
                      </DemoItem>
                    </LocalizationProvider>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <div className="time-picker-container mobile-time-picker-three">
                          <MobileDatePicker
                            className="mobile-date-picker"
                            value={selectedDateTwo}
                            onChange={(newValue) =>
                              handleDateChange(newValue, timezoneTwo)
                            }
                          />
                          <FaRegCalendar className="callender-icon-two" />
                        </div>
                      </DemoItem>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">Time Zone Converter</h4>
            <p className="heading-paragraph">
              Planning meetings, coordinating events, or simply keeping track of
              friends and family across different time zones can be challenging.
              Our Time Zone Converter tool makes it easy to convert time between
              various time zones quickly and accurately.
            </p>
          </div>
          <h6 className="main-heading">About the SDE Tools Editor</h6>
          <p className="main-heading-paragraph">
            The SDE Tools Editor is a flexible code editor designed specifically
            for software development. It offers various features and programming
            languages such as formatting tools integrations, highlighting
            syntax, and completion of code. Additionally, it also provides
            built-in integrated converters for different formats that helps you
            in exchange of your data by easily converting your data to various
            formats like JSON, XML, CSV, and YAML. SDE Tools Editor helps you in
            converting timestamps to date using Epoch and Unix Timestamp
            Converter. Now, with the assistance of QR Code Generator, you can
            easily generate QR Code by just providing the URL or text that you
            desire to encode. The seamless difference checker lets you quickly
            identify the difference between your input code enabling you to keep
            track of changes in the version.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is a QR Code Generator?</h6>
          <p className="main-heading-paragraph">
            QR Codes (Quick Response Codes) are two-dimensional barcodes that
            can store a wide range of data such as E-tickets, website URLs,
            payment links, and many more. They can be scanned with a phone's
            camera or specialized QR code scanner app to redirect people to
            various websites, exchange contact information, or even start apps.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why use a QR Code Generator?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                You can easily convert the URLs of your website or any other
                information to QR Code.
              </li>
              <li>
                Users can access your website, apps, or other details by just
                scanning the QR code.
              </li>
              <li>
                Encode data fast and effortlessly without requiring manual
                setup.
              </li>
            </ul>
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TimezoneConverter;
