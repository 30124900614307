/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, forwardRef } from "react";
import "./generattor.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { useQRCode } from "next-qrcode";
import { saveAs } from "file-saver";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { FaRegCopy } from "react-icons/fa6";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";

const RandomNumberGenerator = () => {
    const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // form validation
    const FormValidationSchema = yup.object({
        inputJsonData: yup.string().notRequired("Json data is required"),
        inputJsonFile: yup.mixed().notRequired("Json file  is required"),
        fileEncoding: yup.string().required("Json file  is required"),
        incantationalLevel: yup
            .string()
            .required("Incantational level  is required"),
        nameOfRootElement: yup.string().required("Root element name  is required"),
        elementNameForJsonArrays: yup
            .string()
            .required("Root element name  is required"),
    });
    let parseJSON;

    const {
        register,
        reset,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm({
        defaultValues: {
            inputJsonData: "",
            inputJsonFile: "",
            fileEncoding: "UTF-8",
            incantationalLevel: "THREE_SPACES",
            nameOfRootElement: "root",
            elementNameForJsonArrays: "element",
        },
        resolver: yupResolver(FormValidationSchema),
    });



    const downloadJson = () => {
        const jsonBlob = new Blob([formattedJsonValue], {
            type: "application/txt",
        });
        const url = URL.createObjectURL(jsonBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "convertedXml.txt";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const onSubmit = async (data) => {
        console.log("submitedDAtaJsonFormatter", data);
        setFormattedJsonValue("");

        try {
            if (data?.inputJsonData && data?.inputJsonFile) {
                toast.warning("You need to upload file or use copy Paste only");
            } else if (data?.inputJsonFile) {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                };
                let formData = new FormData();

                formData.append("inputJsonFile", data.inputJsonFile);
                formData.append("fileEncoding", data.fileEncoding);
                formData.append("incantationalLevel", data.incantationalLevel);
                formData.append("rootElement", data.nameOfRootElement);
                formData.append(
                    "elementNameForJsonArrays",
                    data.elementNameForJsonArrays
                );

                try {
                    axios
                        .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formData, config)
                        .then((res) => {
                            console.log("response", res);
                            if (showFormatNewWindow) {
                                setShowEditor(false);
                            } else {
                                setShowEditor(true);
                            }
                            setFormattedJsonValue(res.data.data);
                            toast.success("Formatting done successfully!");
                            setError(false);
                            document.getElementById("editor_main_id")?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                        })
                        .catch((error) => {
                            console.error("API request failed:", error);
                            if (error.response && error.response.data) {
                                // Display the error message from the API response
                                setError(true);
                                setErrorMessage(error.response.data);
                            } else {
                                // Display a generic error message
                                setError(true);
                                setErrorMessage(error.response.data);
                            }
                        });
                } catch (error) {
                    console.log("error1", error);
                    toast.warning("Something went wrong!");
                }
            } else {
                try {
                    let formatData = {
                        inputJsonData: data.inputJsonData,
                        incantationalLevel: data.incantationalLevel,
                        rootElement: data.nameOfRootElement,
                        elementNameForJsonArrays: data.elementNameForJsonArrays,
                    };
                    axios
                        .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formatData)
                        .then((res) => {
                            console.log("response", res);
                            if (showFormatNewWindow) {
                                setShowEditor(false);
                            } else {
                                setShowEditor(true);
                            }
                            setFormattedJsonValue(res.data.data);
                            toast.success("Formatting done successfully!");
                            setError(false);
                            document.getElementById("editor_main_id")?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                            // }
                        })
                        .catch((error) => {
                            console.error("API request failed:", error);
                            if (error.response && error.response.data) {
                                // Display the error message from the API response
                                setError(true);
                                setErrorMessage(error.response.data);
                            } else {
                                // Display a generic error message
                                toast.error("An error occurred while processing your request.");
                            }
                        });
                } catch (error) {
                    console.log("error2", error);
                    toast.warning("Something went wrong!");
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const QRCode = forwardRef((props, ref) => {
        const { Canvas } = useQRCode();

        return (
            <div ref={ref}>
                <Canvas {...props} />
            </div>
        );
    });
    const qrRef = useRef();

    const [maxCount, setMaxCount] = useState(1);
    const [showUUIDCode, setShowUUIDCode] = useState(false);
    const [errorMessageQR, setErrorMessageQR] = useState(false);
    const [inputJsonValue, setInputJsonValue] = useState();
    const [inputJsonValueFile, setInputJsonValueFile] = useState();
    const [formattedJsonValue, setFormattedJsonValue] = useState("");
    const [showEditor, setShowEditor] = useState(false);
    const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);
    const [uuids, setUuids] = useState([]);
    const [uuidsSingle, setUuidsSingle] = useState("");
    const [isFileDropped, setIsFileDropped] = useState(false);

   const onDownload = () => {
     const text = uuids.join("\n");
     const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
     saveAs(blob, "uuids.txt");
   };
    const generateUUIDs = () => {
        const newUuids = [];
        for (let i = 0; i < maxCount; i++) {
            newUuids.push(uuidv4());
        }
        setUuids(newUuids);
    };



const generateSingleUUID = () => {
  setUuidsSingle(uuidv4());
};

useEffect(()=>{
generateSingleUUID();
},[])
const copyUUID = () => {
  navigator.clipboard.writeText(uuidsSingle).then(
    () => {
      if (uuidsSingle != "") {
        toast.success("UUID copied to clipboard");
      }
    },
    () => {
      if (uuidsSingle != "") {
        toast.warning("Failed to copy UUID to clipboard");
      }
    }
  );
};
    return (
      <div className="container container-random-number">
        {errorMessageQR && (
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setErrorMessageQR(false)} />
            </button>
            Maximum 500 is allowed!
          </div>
        )}
        <Card className="card-formatter randomUuidDisplay  border border-0 card rounded-md bg-white shadow shadow-sm">
          <h4 className="uuid-display uuid-display-heading">Random UUID</h4>
          <div className="uuid-display uuid-cpy">
            {" "}
            {uuidsSingle} <FaRegCopy onClick={copyUUID} />
          </div>
        </Card>
        <form>
          <Card className="card-formatter  mt-3 border border-0 card rounded-md bg-white shadow shadow-sm">
            <div className="row">
              <div className="col-12 col-md-6 mobile-responsive-col ">
                <div className="copy-paste">
                  <label>Generate Multiple UUID</label>
                  <input
                    type="number"
                    onChange={(e) => {
                      if (e.target.value > 500) {
                        setErrorMessageQR(true);
                      }
                      setMaxCount(parseInt(e.target.value));
                      setValue("inputJsonData", e.target.value);
                    }}
                    max={500}
                    placeholder="Max 500"
                    className="epoch-converter-input"
                  />
                </div>

                <div className="button-section-2 cursor-pointer row ">
                  <div className="col-12 col-md-6    py-2 text-center">
                    <button
                      className=" format-btn "
                      onClick={(e) => {
                        if (maxCount < 501) {
                          generateUUIDs();
                          setShowUUIDCode(true);
                          setErrorMessageQR(false);
                        } else {
                          setShowUUIDCode(false);
                          setErrorMessageQR(true);
                        }
                      }}
                      type="button"
                    >
                      Generate
                    </button>
                  </div>
                  <div className="col-12 col-md-6     py-2 text-center">
                    <button
                      className=" format-btn "
                      onClick={() => {
                        if (showUUIDCode) {
                          onDownload();
                        }
                      }}
                      type="button"
                    >
                      Download to a file
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mobile-responsive-col  uuid-code-image-wrapper">
                <div className="container-uuid">
                  {showUUIDCode && (
                    <>
                      {uuids.length > 1
                        ? uuids.map((uuid, index) => (
                            <div className="uuid-display" key={index}>
                              {uuid},
                            </div>
                          ))
                        : uuids.map((uuid, index) => (
                            <div className="uuid-display" key={index}>
                              {uuid}
                            </div>
                          ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </form>

        <div className="main-content-container">
          <div className="sub-heading">
            <div className="heading-formatter">
              <h4 className="heading-formatter-text">
                Random Number Generator
              </h4>
              <p className="heading-paragraph">
                SDE Tools Editors brings you its integrated Random Number
                Generator with which randomness can easily be incorporated into
                your code. You can easily generate your preferred UUIDs using
                this tool. Our generator ensures uniqueness and trustworthiness
                by generating random UUIDs per well-recognized guidelines with
                only a single button click. You can generate up to 500 random
                numbers and also download the file using our tool.
              </p>
            </div>
            <h6 className="main-heading">About the SDE Tools Editor</h6>
            <p className="main-heading-paragraph">
              The SDE Tools Editor is a flexible code editor designed
              specifically for software development. It offers various features
              and programming languages such as formatting tools integrations,
              highlighting syntax, and completion of code. Additionally, it also
              provides built-in integrated converters for different formats that
              helps you in exchange of your data by easily converting your data
              to various formats like JSON, XML, CSV, and YAML. SDE Tools Editor
              helps you in converting timestamps to date using Epoch and Unix
              Timestamp Converter. Now, with the assistance of QR Code
              Generator, you can easily generate QR Code by just providing the
              URL or text that you desire to encode. The seamless difference
              checker lets you quickly identify the difference between your
              input code enabling you to keep track of changes in the version.
            </p>
          </div>
          <div className="sub-heading">
            <h6 className="main-heading">What is a Random Number Generator?</h6>
            <p className="main-heading-paragraph">
              Random Number Generator chooses values at random from a
              predetermined range giving unpredictable results. You can use this
              functionality to build actual scenarios for testing or collections
              of data and to add a random aspect to your work. They consider a
              wide range of importance in different software development
              scenarios such as password generation, testing, simulations, and
              many more.
            </p>
          </div>
          <div className="sub-heading">
            <h6 className="main-heading">Why use a Random Number Generator?</h6>

            <p className="main-heading-paragraph">
              <ul className="sub-list-ul">
                <li>
                  You can generate random numbers directly and easily using our
                  generator without the need for any other third-party
                  applications
                </li>
                <li>
                  Choose a random number range and amount of components easily
                  to meet your requirements.
                </li>
                <li>
                  Produce several random number sets using identical parameters
                  to provide consistency in testing or research.
                </li>
              </ul>
            </p>
          </div>
          <div className="sub-heading">
            <h6 className="main-heading">
              Why use SDE Tools Validator and Formatter?
            </h6>
            <p className="main-heading-paragraph">
              It automatically formats your code in accordance with the coding
              standards. SDE Tools offers you various advantages:
            </p>

            <p className="main-heading-paragraph">
              <ul className="sub-list-ul">
                <li>
                  It helps in increasing the productivity as it provides you
                  features like code completion and highlighting the syntax
                  minimizing errors.
                </li>
                <li>
                  To fit your coding style, the editor lets you adjust
                  formatting choices and handles several file encodings.
                </li>

                <li>
                  It enhances the readability by providing clarity to all the
                  developers.
                </li>
              </ul>
            </p>
          </div>

          <div className="sub-heading">
            <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
            <p className="main-heading-paragraph">
              The validator functions similarly to a computerized code reviewer.
              It goes line by line through your code. It checks:
            </p>
            <p className="main-heading-paragraph">
              <ul className="sub-list-ul">
                <li>
                  Mistakes such as mismatched braces, improper function calls,
                  and misplaced semicolons.
                </li>
                <li>
                  The coding style differentiation such as indentation spaces.
                </li>

                <li>
                  A report is provided by the validator which includes
                  identified problems and enables you to fix those problems.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
};

export default RandomNumberGenerator;
