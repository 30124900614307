import React, { useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./admin-sidebar.css"

import { BiLogOutCircle } from "react-icons/bi";

import { MdDashboard } from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { TbHexagonLetterB } from "react-icons/tb";
import { FaHashtag } from "react-icons/fa6";
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
const AdminSideBar = () => {
   const location = useLocation();
  const [showModal, setShowModal] = useState(false);
 const navigate = useNavigate();

  const handleLogout = () => {
    setShowModal(true);
  };

  

   const confirmLogout = () => {
     setShowModal(false);
     sessionStorage.removeItem("token");
     sessionStorage.removeItem("user");
     toast.success("Logout successfully!");
     navigate("/admin/auth/login");
   };

    const cancelLogout = () => {
      setShowModal(false);
    };
  return (
    <div>
      <Sidebar className="sidebar-container" backgroundColor={"#fff"}>
        <Menu
          rootStyles
          menuItemStyles={{
            button: {
              [`&:hover`]: {
                backgroundColor: "#c00eae",
                color: "#fff",
              },
              [`&.active`]: {
                backgroundColor: "#c00eae",
                color: "#fff",
              },
            },
          }}
        >
          <MenuItem
            icon={<MdDashboard />}
            color="white"
            className={
              location.pathname === "/admin/admin-dashboard" ? "ps-active" : ""
            }
            component={<Link to="/admin/admin-dashboard" />}
          >
            {" "}
            Dashboard
          </MenuItem>
          <MenuItem
            className={
              location.pathname === "/admin/category" ? "ps-active" : ""
            }
            icon={<TbCategoryPlus />}
            component={<Link to="/admin/category" />}
          >
            {" "}
            Category
          </MenuItem>
          <MenuItem
            className={location.pathname === "/admin/tags" ? "ps-active" : ""}
            icon={<FaHashtag />}
            component={<Link to="/admin/tags" />}
          >
            {" "}
            Tags
          </MenuItem>
          <MenuItem
            className={location.pathname === "/admin/blogs" ? "ps-active" : ""}
            icon={<TbHexagonLetterB />}
            component={<Link to="/admin/blogs" />}
          >
            Blogs
          </MenuItem>
          <MenuItem
            className={location.pathname === "/admin/" ? "ps-active" : ""}
            icon={<BiLogOutCircle />}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </Sidebar>

      {/* Logout Confirmation Modal */}
      <Modal show={showModal} onHide={cancelLogout}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelLogout}>
            No
          </Button>
          <Button variant="danger" onClick={confirmLogout}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminSideBar