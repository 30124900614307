/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, forwardRef } from "react";
import "./DateTimeTools.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useQRCode } from "next-qrcode";
import { saveAs } from "file-saver";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { FaFacebook, FaPinterest, FaWhatsapp } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { SocialIcon } from "react-social-icons";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdShare } from "react-icons/md";
import Stopwatch from "./StopWatch";
import Counter from "./Counter";

const OnlineCountDown = () => {
  const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // form validation
  const FormValidationSchema = yup.object({
    inputJsonData: yup.string().notRequired("Json data is required"),
    inputJsonFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
    nameOfRootElement: yup.string().required("Root element name  is required"),
    elementNameForJsonArrays: yup
      .string()
      .required("Root element name  is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputJsonData: "",
      inputJsonFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      nameOfRootElement: "root",
      elementNameForJsonArrays: "element",
    },
    resolver: yupResolver(FormValidationSchema),
  });

  const copyJson = () => {
    navigator.clipboard.writeText(formattedJsonValue).then(
      () => {
        if (formattedJsonValue != "") {
          toast.success("QR Code copied to clipboard");
        }
      },
      () => {
        if (formattedJsonValue != "") {
          toast.warning("Failed to copy QR code to clipboard");
        }
      }
    );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "convertedXml.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);
    setFormattedJsonValue("");

    try {
      if (data?.inputJsonData && data?.inputJsonFile) {
        toast.warning("You need to upload file or use copy Paste only");
      } else if (data?.inputJsonFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputJsonFile", data.inputJsonFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("rootElement", data.nameOfRootElement);
        formData.append(
          "elementNameForJsonArrays",
          data.elementNameForJsonArrays
        );

        try {
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formData, config)
            .then((res) => {
              console.log("response", res);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              setFormattedJsonValue(res.data.data);
              toast.success("Formatting done successfully!");
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                setErrorMessage(error.response.data);
              } else {
                // Display a generic error message
                setError(true);
                setErrorMessage(error.response.data);
              }
            });
        } catch (error) {
          console.log("error1", error);
          toast.warning("Something went wrong!");
        }
      } else {
        try {
          let formatData = {
            inputJsonData: data.inputJsonData,
            incantationalLevel: data.incantationalLevel,
            rootElement: data.nameOfRootElement,
            elementNameForJsonArrays: data.elementNameForJsonArrays,
          };
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formatData)
            .then((res) => {
              console.log("response", res);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              setFormattedJsonValue(res.data.data);
              toast.success("Formatting done successfully!");
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                setErrorMessage(error.response.data);
              } else {
                // Display a generic error message
                toast.error("An error occurred while processing your request.");
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const QRCode = forwardRef((props, ref) => {
    const { Canvas } = useQRCode();

    return (
      <div ref={ref}>
        <Canvas {...props} />
      </div>
    );
  });
  const qrRef = useRef();

  const [urlLink, setUrlLink] = useState("");
  const [showQrCode, setShowQRCode] = useState(false);
  const [errorMessageQR, setErrorMessageQR] = useState(false);
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);

  const [isFileDropped, setIsFileDropped] = useState(false);

   
  
  return (
    <div className="container date-time-container">
      <Card className="card-formatter container-stop-watch border border-0 card rounded-md bg-white shadow shadow-sm">
        <Counter />
      </Card>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">Online Counter</h4>
            <p className="heading-paragraph">
              An online counter is a user-friendly and straightforward digital
              timer that lets you count your desired time setup. You can set
              your countdown timer at your desired rate of time. Using SDE Tools
              Editor, you can now set up the time according to your choice in
              various durations such as hours, minutes, or seconds. It also
              allows you to set up the countdown multiple times and also reset
              the time.
            </p>
          </div>
          <h6 className="main-heading">About the SDE Tools Editor</h6>
          <p className="main-heading-paragraph">
            The SDE Tools Editor is a flexible code editor designed specifically
            for software development. It offers various features and programming
            languages such as formatting tools integrations, highlighting
            syntax, and completion of code. Additionally, it also provides
            built-in integrated converters for different formats that helps you
            in exchange of your data by easily converting your data to various
            formats like JSON, XML, CSV, and YAML. SDE Tools Editor helps you in
            converting timestamps to date using Epoch and Unix Timestamp
            Converter. Now, with the assistance of QR Code Generator, you can
            easily generate QR Code by just providing the URL or text that you
            desire to encode. The seamless difference checker lets you quickly
            identify the difference between your input code enabling you to keep
            track of changes in the version.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is an Online Counter?</h6>
          <p className="main-heading-paragraph">
            An online timer is like a virtual timer that can be accessed through
            any device on the web. It offers a practical way to schedule
            activities, keep track of how long things take, and set countdowns
            for them right on your desktop or smartphone.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why use Online Counter?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>It helps you to set your desired countdown time easily.</li>
              <li>
                It eliminates the need for a third-party application or a
                physical countdown timer.
              </li>
              <li>
                It specifies the desired countdown duration in hours, minutes,
                or seconds to handle a range of work.
              </li>
              <li>
                It helps you maintain concentration and efficiently manage your
                calendar by showing you how much time is left in a simple visual
                format.
              </li>
            </ul>
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnlineCountDown;
