import React, { useState, useEffect, useRef } from "react";
import "./DateTimeTools.css";
import { FaArrowLeft } from "react-icons/fa";
function Counter() {
  const [isIntervalDataSet, setIsIntervalData] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isStoped, setIsStopped] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [elapsedTimeStored, setElapsedTimeStored] = useState(0);
  const intervalIdRef = useRef(null);
  const startTimeRef = useRef(0);

  useEffect(() => {
    if (isRunning) {
        if(elapsedTime>=0){
 intervalIdRef.current = setInterval(() => {
        // setElapsedTime(Date.now() - startTimeRef.current);
        // setElapsedTime(startTimeRef.current - Date.now());
      
           const remainingTime = startTimeRef.current - Date.now();
           setElapsedTime(Math.max(remainingTime, 0)); 
         
          if (Math.max(remainingTime, 0) === 0) {
            setIsStopped(true);
          
          }
      }, 10);
        }else 
        {
            setIsRunning(false)
            setElapsedTime(0)
        }
     
    }

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [isRunning]);
 useEffect(() => {
   window.scrollTo(0, 0);
 }, []);
  function start(e) {
    e.preventDefault();
    // setIsRunning(true);
    setIsRunning(!isRunning);
    setIsStart(true);

    // startTimeRef.current = Date.now() - elapsedTime;
     startTimeRef.current = Date.now() + elapsedTime;
  }

  function stop(e) {
    e.preventDefault();
    setIsRunning(false);
    setIsStart(false);
  }

  function reset(e) {
    e.preventDefault();
    setElapsedTime(0);
    setIsRunning(false);
  }
  function resetTimer2(e) {
    e.preventDefault();
    setElapsedTime(elapsedTimeStored);
    setIsRunning(false);
    setIsStopped(false)
  }

  function formatTime() {
    
    let hours = Math.floor(elapsedTime / (1000 * 60 * 60));
    let minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
    let seconds = Math.floor((elapsedTime / 1000) % 60);
    let milliseconds = Math.floor((elapsedTime % 1000) / 10);

    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    milliseconds = String(milliseconds).padStart(2, "0");

    // return `${minutes}:${seconds}:${milliseconds}`;
    return {
      mainTime: `${hours}:${minutes}:${seconds}`,
      milliseconds: milliseconds,
    };
  }
  const { mainTime, milliseconds } = formatTime();


 
// function handleNumberClick(number) {
//   // If interval is not set, do nothing
//   if (!isIntervalDataSet) {
//     return;
//   }

//   // Extract hours, minutes, and seconds from elapsed time
//   let remainingTime = elapsedTime;
//   let hours = Math.floor(remainingTime / (1000 * 60 * 60));
//   remainingTime %= 1000 * 60 * 60;
//   let minutes = Math.floor(remainingTime / (1000 * 60));
//   remainingTime %= 1000 * 60;
//   let seconds = Math.floor(remainingTime / 1000);

//   // Update the respective part based on the clicked number
//   if (String(seconds).length < 2) {
//     seconds = Number(String(seconds) + String(number).padStart(1, "0"));
//   } else if (String(minutes).length < 2) {
//     minutes = Number(String(minutes) + String(number).padStart(1, "0"));
//   } else if (String(hours).length < 2) {
//     hours = Number(String(hours) + String(number).padStart(1, "0"));
//   }

//   // Combine hours, minutes, and seconds back into elapsed time
//   const newElapsedTime = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
// setElapsedTimeStored(newElapsedTime);
//   // Update elapsed time
//   setElapsedTime(newElapsedTime);
// }
function handleNumberClick(number) {
  // If interval is not set, do nothing
  if (!isIntervalDataSet) {
    return;
  }

  // Extract hours, minutes, and seconds from elapsed time
  let remainingTime = elapsedTime;
  let hours = Math.floor(remainingTime / (1000 * 60 * 60));
  remainingTime %= 1000 * 60 * 60;
  let minutes = Math.floor(remainingTime / (1000 * 60));
  remainingTime %= 1000 * 60;
  let seconds = Math.floor(remainingTime / 1000);
console.log("hours", hours);
console.log("hours.minutes", minutes);
console.log("hours.seconds", seconds);
  // Update the respective part based on the clicked number
  if (String(seconds).length < 2) {
    seconds = Number(String(seconds) + String(number).padStart(1, "0"));
  } else if (String(minutes).length < 2) {
    minutes = Number(String(minutes) + String(number).padStart(1, "0"));
  } else if (String(hours).length < 2) {
    hours = Number(String(hours) + String(number).padStart(1, "0"));
  }

  // Combine hours, minutes, and seconds back into elapsed time
  const newElapsedTime = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
  setElapsedTimeStored(newElapsedTime);
  // Update elapsed time
  setElapsedTime(newElapsedTime);
}

 


 useEffect(() => {
   function handleKeyPress(event) {
     const number = parseInt(event.key, 10);
     if (!isNaN(number)) {
       handleNumberClick(number);
     }
   }

   // Attach the event listener
   document.addEventListener("keydown", handleKeyPress);

   // Clean up the event listener on component unmount
   return () => {
     document.removeEventListener("keydown", handleKeyPress);
   };
 }, [elapsedTime, isIntervalDataSet]);
 

 

  function resetTimer() {
    setElapsedTime(0);
    setIsRunning(false);
  }
 
  return (
    <div className="stopwatch main-container">
      {/* <div className="display text-center">{formatTime()}</div>*/}

      {isIntervalDataSet ? (
        <div className="buttons-container">
          <div className="counter-container">
            <div className="display text-end inner-container-container">
              {mainTime}
              <sup className="milliseconds mt-2 ml-2">{milliseconds}</sup>
            </div>
          </div>
          <div className="row">
            <div className="col-8 ps-5">
              <div className="row justify-content-between web-view-counter">
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(5)}
                    className="button-number"
                    type="button"
                  >
                    5
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(6)}
                    className="button-number"
                    type="button"
                  >
                    6
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(7)}
                    className="button-number"
                    type="button"
                  >
                    7
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(8)}
                    className="button-number"
                    type="button"
                  >
                    8
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(9)}
                    className="button-number"
                    type="button"
                  >
                    9
                  </button>
                </div>
              </div>
              <div className="row justify-content-between mt-2 web-view-counter">
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(0)}
                    className="button-number"
                    type="button"
                  >
                    0
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(1)}
                    className="button-number"
                    type="button"
                  >
                    1
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(2)}
                    className="button-number"
                    type="button"
                  >
                    2
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(3)}
                    className="button-number"
                    type="button"
                  >
                    3
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleNumberClick(4)}
                    className="button-number"
                    type="button"
                  >
                    4
                  </button>
                </div>{" "}
              </div>
            </div>
            <div className="col-4 pe-0 web-view-counter">
              <div className="row main-number-container-right">
                <div className="col-6">
                  <button
                    onClick={() => setIsIntervalData(false)}
                    className="button-number-2"
                    type="button"
                  >
                    Set
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={resetTimer}
                    className="button-number-2"
                    type="button"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row mobile-view">
              <div className="col-12 ">
                <div className="row mb-2 justify-content-between mt-2 ">
                  <div className="col-3 mb-2 ">
                    <button
                      onClick={() => handleNumberClick(0)}
                      className="button-number"
                      type="button"
                    >
                      0
                    </button>
                  </div>
                  <div className="col-3 mb-2 ">
                    <button
                      onClick={() => handleNumberClick(1)}
                      className="button-number"
                      type="button"
                    >
                      1
                    </button>
                  </div>
                  <div className="col-3  ">
                    <button
                      onClick={() => handleNumberClick(2)}
                      className="button-number"
                      type="button"
                    >
                      2
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      onClick={() => handleNumberClick(3)}
                      className="button-number"
                      type="button"
                    >
                      3
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      onClick={() => handleNumberClick(4)}
                      className="button-number"
                      type="button"
                    >
                      4
                    </button>
                  </div>{" "}
                  <div className="col-3">
                    <button
                      onClick={() => handleNumberClick(5)}
                      className="button-number"
                      type="button"
                    >
                      5
                    </button>
                  </div>
                  <div className="col-3 mb-2 ">
                    <button
                      onClick={() => handleNumberClick(6)}
                      className="button-number"
                      type="button"
                    >
                      6
                    </button>
                  </div>
                  <div className="col-3 mb-2 ">
                    <button
                      onClick={() => handleNumberClick(7)}
                      className="button-number"
                      type="button"
                    >
                      7
                    </button>
                  </div>
                </div>
                <div className="row justify-content-between ">
                  <div className="col-3">
                    <button
                      onClick={() => handleNumberClick(8)}
                      className="button-number"
                      type="button"
                    >
                      8
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      onClick={() => handleNumberClick(9)}
                      className="button-number"
                      type="button"
                    >
                      9
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => setIsIntervalData(false)}
                      className="button-number button-third-row"
                      type="button"
                    >
                      Set
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      onClick={resetTimer}
                      className="button-number-2 button-number-2-clear"
                      type="button"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`counter-container ${isStoped ? "timer-stopped" : ""}`}
          >
            <div className="display text-end inner-container-container">
              {mainTime}
              <sup className="milliseconds mt-2 ml-2">{milliseconds}</sup>
            </div>
          </div>
          <div className="controls row">
            <button
              type="button"
              onClick={() => {
                setIsIntervalData(true);
                resetTimer();
              }}
              className={` back-button col-5 col-md-3  `}
            >
              <FaArrowLeft />
            </button>
            <button
              type="button"
              onClick={start}
              className={` col-5 col-md-3 ${
                isStoped ? "hidden" : "start-button"
              }`}
            >
              {!isStoped && elapsedTime > 0 && !isRunning ? "Start" : " Pause"}
            </button>
             
            <button
              type="button"
              onClick={resetTimer2}
              className="stop-button  col-12 col-md-3"
            >
              Clear
            </button>
          </div>
        </>
      )}
    </div>
  );
}
export default Counter;
