import * as moment from "moment-timezone";
import React from "react";
import Select from "react-select";
import "./DateTimeTools.css"
import { timeZones } from "./TimeZone";
const timeZoneList = moment.tz.names();


const TZSelect = (props) => {
  

  const tzList = timeZoneList.map((tz) => ({
    value: tz,
    label: tz,
  }));
 

  const styles = {
    control: (provided, state) => ({
      ...provided,
      //   border: "none",
      boxShadow: "none",
      borderRadius: 2,
      //    borderColor: state.isFocused ? "white" : "white",
      borderColor: "white", // Set border color to white for both focused and unfocused states
      "&:hover": {
        borderColor: "white", // Apply white color when hovered over
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white", // Default selected option color
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white", // Text color while typing
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? "#BF66B6"
          : isSelected
          ? "#c00eae"
          : undefined,

        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };
  return (
    <Select
      styles={styles}
      {...props}
      options={timeZones}
      menuPlacement="auto"
      menuPosition="fixed"
    />
  );
};

export default TZSelect;
