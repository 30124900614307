/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../redux/features/category/categorySlice";
import "./edit.css";
import Select from "react-select";

import EditorToolbar, { modules, formats } from "../add/EditorToolbar";
import "react-quill/dist/quill.snow.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { setEditBlogData } from "../../../redux/features/Blog/blogSlice";
import Loader from "../../../Loader/Loader";
const EditBlog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogEditData = useSelector((state) => state.blog.editData);

  const { id } = useParams();

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(
    `${process.env.REACT_APP_BACKEND_URL}/${blogEditData?.blog_Image}`
  );

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused = false, isSelected }) => {
      // const color = chroma(data.color);
      console.log("isFocused", isFocused);
      return {
        ...styles,

         

        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#BF66B6"
          : isFocused
          ? "#c00eae"
          : undefined,

        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState(blogEditData?.category_id);
  const [tagValue, setTagValue] = useState(null);
  const [value, setValue] = useState("");

  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .min(2, "Title must be at least 2 characters")
      .max(100, "Title must be less than 100 characters"),

    description: Yup.string().required("Description is required"),
    slug: Yup.string().required("Slug is required"),
    metaTitle: Yup.string().required("Meta title is required"),
    metaDescription: Yup.string().required("Meta Description is required"),

    canonical_url: Yup.string().required("Canonical url is required"),
    og_title: Yup.string().required("Og title is required"),
    og_description: Yup.string().required("Og Description is required"),
    og_site_name: Yup.string().required("Og site name is required"),
    og_type: Yup.string().required("Og type is required"),
    og_url: Yup.string().required("Og url is required"),
    reading_time: Yup.string().required("Reading Time is required"),
    // .matches(/^\d+$/, "Reading Time must be a number"), // Assuming reading_time is a number in string format

    category_id: Yup.string()
      .required("Category ID is required")
      .matches(
        /^[a-fA-F0-9]{24}$/,
        "Category ID must be a valid MongoDB ObjectId"
      ), // Adjust pattern as needed for your use case

    blog_Image: Yup.mixed().notRequired(),

    tags: Yup.array()
      .of(Yup.string().required("Each tag is required")) // Validate each tag as a string
      .min(1, "At least one tag is required") // Ensure at least one tag is provided
      .required("Tags are required"),
  });

  const initialValues = {
    title: blogEditData?.title,
    description: blogEditData?.description,
    slug: blogEditData?.slug,
    metaTitle: blogEditData?.metaTitle,
    metaDescription: blogEditData?.metaDescription,

    canonical_url: blogEditData?.canonical_url,
    og_title: blogEditData?.og_title,
    og_description: blogEditData?.og_description,
    og_site_name: blogEditData?.og_site_name,
    og_type: blogEditData?.og_type,
    og_url: blogEditData?.og_url,

    reading_time: blogEditData?.reading_time,
    category_id: blogEditData?.category_id,
    blog_Image: null,
    tags: blogEditData?.tags?.map((tag) => tag._id) || [],
  };
  const fetchData = async () => {
    try {
      setLoading(true)
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/category/get-category`;
      const response = await axios.get(url);
    
      const formattedOptions = response.data.data.map((category) => ({
        label: category.name, // Display name
        value: category._id, // Value to use in form submission
      }));

      const defaultMappedCategory = formattedOptions.find(
        (data) => data.value == blogEditData?.category_id
      );
      setCategoryValue(defaultMappedCategory);

      setCategoryOptions(formattedOptions);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const fetchDataTags = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/tags`;
      const response = await axios.get(url);
      console.log("response", response);
      const formattedOptions = response.data.data.map((category) => ({
        label: category.name,
        value: category._id,
      }));
      setTagOptions(formattedOptions);

      const selectedTags = formattedOptions.filter((option) =>
        blogEditData?.tags?.some((tag) => tag._id === option.value)
      );

      const selectedValues = selectedTags.map((option) => option.value);
      setTagValue(selectedTags);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  const fetchDataEdit = async () => {
    try {
    
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/get-blog/${id}`;
      const response = await axios.get(url).then((res) => {
        //  setFieldValue("name", response.data.data.name || "");
        dispatch(setEditBlogData(res.data.data));
      });
    } catch (err) {
    } finally {
      
    }
  };
  useEffect(() => {
    fetchData();
    fetchDataTags();
    fetchDataEdit();
    
    
  }, []);

  useEffect(() => {
    let timer;
    if (blogEditData && blogEditData.description) {
      setValue(blogEditData.description);
      // Show loading for an extra 3 seconds
      timer = setTimeout(() => setLoading(false), 2000);
    }

    // Cleanup timer if the component unmounts or if data changes
    return () => clearTimeout(timer);
  }, [blogEditData]);

  // Handle form submission
  const onSubmit = async (values, { resetForm }) => {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("newSlug", values.slug);
      formData.append("metaTitle", values.metaTitle);
      formData.append("metaDescription", values.metaDescription);

      formData.append("canonical_url", values.canonical_url);
      formData.append("og_title", values.og_title);
      formData.append("og_description", values.og_description);
      formData.append("og_site_name", values.og_site_name);
      formData.append("og_type", values.og_type);
      formData.append("og_url", values.og_url);
      formData.append("reading_time", values.reading_time);
      formData.append("category_id", values.category_id);

      // Append each tag individually
      values.tags.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag);
      });

      // Append the image file
      if (values.blog_Image) {
        formData.append("blog_Image", values.blog_Image);
      }

      // Send the POST request with the FormData object
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/blog/update-blog/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (res) => {
          toast.success("Blog Updated Successfully!");

          // Fetch updated data
          await fetchData();

          // Navigate to a different route
          navigate("/admin/blogs");

          // Reset the form
          resetForm();
        });
    } catch (error) {
      console.error("Error adding blog:", error);
      let errorMessage = "Something Went Wrong!";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      toast.error(errorMessage);
    }
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      setSelectedFile(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="container">
      <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm p-4">
        <h3 className="mb-4 title-admin">View Blog</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="grid-container">
                <div className="grid-item">
                  <label>
                    Select Category <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    options={categoryOptions}
                    isClearable={false}
                    styles={styles}
                    onChange={(e) => {
                      console.log("onchange", e);
                      setCategoryValue(e);

                      setFieldValue("category_id", e.value);
                    }}
                    classNamePrefix="select"
                    value={categoryValue}
                  />
                  <ErrorMessage
                    name="category_id"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="title ">
                      Blog Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="title"
                      name="name"
                      className="form-control"
                      placeholder="Enter blog title"
                      value={values.title}
                      onChange={(e) => {
                        setFieldValue("title", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label>Select Tags</label>
                  <Select
                    options={tagOptions}
                    isClearable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={styles}
                    onChange={(e) => {
                      setTagValue(e);
                      const selectedValues = e.map((option) => option.value);
                      console.log("selectedValues", selectedValues);
                      setFieldValue("tags", selectedValues);
                    }}
                    classNamePrefix="select"
                    value={tagValue}
                  />
                  <ErrorMessage
                    name="tags"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="slug ">
                      Slug<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                      placeholder="e.g., understanding-the-power-of-docker-in-devops"
                      onChange={(e) => {
                        setFieldValue("slug", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="slug"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="metaTitle ">
                    Meta Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="metaTitle"
                    name="metaTitle"
                    className="form-control"
                    placeholder="Enter your meta title"
                    onChange={(e) => {
                      setFieldValue("metaTitle", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="metaTitle"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="metaDescription ">
                    Meta Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="metaDescription"
                    name="metaDescription"
                    className="form-control"
                    placeholder="Enter your meta description"
                    onChange={(e) => {
                      setFieldValue("metaDescription", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="metaDescription"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="canonical_url ">
                    Canonical Url<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="canonical_url"
                    name="canonical_url"
                    className="form-control"
                    placeholder="Enter your Canonical url"
                    onChange={(e) => {
                      setFieldValue("canonical_url", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="canonical_url"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="og_title ">
                    Og Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="og_title"
                    name="og_title"
                    className="form-control"
                    placeholder="Enter your og title"
                    onChange={(e) => {
                      setFieldValue("og_title", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="og_title"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="og_description ">
                    Og Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="og_description"
                    name="og_description"
                    className="form-control"
                    placeholder="Enter your og description"
                    onChange={(e) => {
                      setFieldValue("og_description", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="og_description"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="og_site_name ">
                    Og site name<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="og_site_name"
                    name="og_site_name"
                    className="form-control"
                    placeholder="Enter your og site name"
                    onChange={(e) => {
                      setFieldValue("og_site_name", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="og_site_name"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="og_type ">
                    Og Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="og_type"
                    name="og_type"
                    className="form-control"
                    placeholder="Enter your og site name"
                    onChange={(e) => {
                      setFieldValue("og_type", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="og_type"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="og_url ">
                    Og URL<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="og_url"
                    name="og_url"
                    className="form-control"
                    placeholder="Enter your og url"
                    onChange={(e) => {
                      setFieldValue("og_url", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="og_url"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>

              <div className="grid-item">
                <label>
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <div className="text-editor">
                  {/*<ReactQuill
                    theme="snow"
                    value={value}
                    onChange={(e) => {
                      setValue(e);
                      setFieldValue("description", e);
                    }}
                  />*/}

                  <EditorToolbar />
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={(e) => {
                      setValue(e);
                      setFieldValue("description", e);
                    }}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                    formats={formats}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-container">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="reading_time ">
                      Reading Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="reading_time"
                      name="name"
                      className="form-control"
                      value={values.reading_time}
                      placeholder="Enter reading time"
                      onChange={(e) => {
                        setFieldValue("reading_time", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="reading_time"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item mt-4">
                  <div className="form-group">
                    <div className="image-upload-preview">
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        onChange={(e) => {
                          console.log("e", e.target.files[0]);
                          setFieldValue("blog_Image", e?.target?.files[0]);
                          handleFileChange(e);
                        }}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleButtonClick}
                      >
                        {selectedFile ? selectedFile : "Choose file"}
                      </button>
                    </div>
                    <ErrorMessage
                      name="blog_Image"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item mt-4 ">
                  {previewUrl && (
                    <div className="image-preview">
                      <img
                        src={previewUrl}
                        alt="Selected Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100px",
                          borderRadius: "1rem",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="admin-button  mt-3"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default EditBlog;
