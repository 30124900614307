import { createSlice } from "@reduxjs/toolkit";

 

export const categorySlice = createSlice({
  name: "Category", // ensure consistency in naming
  initialState: {
    data: [],
    editData: [],
    isEditModalOpen: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEditModalOpen: (state, action) => {
      state.isEditModalOpen = action.payload;
    },
    setEditData: (state, action) => {
      state.editData = action.payload;
    },
  },
});

export const { setData, setEditData, setEditModalOpen } = categorySlice.actions;
export default categorySlice.reducer;
