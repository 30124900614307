/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-unused-vars */
import SideBar from "./components/SideBar/SideBar";
import "./index.css"; 
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";

import JsonFormatters from "./pages/formatters/JsonFormatters";
import HtmlFormatter from "./pages/formatters/HtmlFormatter";
import XMLFormatter from "./pages/formatters/XMLFormatter";
import SQLFormatter from "./pages/formatters/SQLFormatter";
import jsonFormatterNewWindow from "./pages/formatter-new-window-pages/jsonformatter-new-window";
import Header from "./components/Header/Header";

 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard/welcome";
import { useEffect, useState } from "react";
import JsonToXMLConverter from "./pages/Converter/JsonToXMLConverter";
import JSONtoXMLconverter from "./pages/formatter-new-window-pages/JSONtoXMLconverter";
import XMLtoJSONConverter from "./pages/Converter/XMLtoJSONConverter";
import CSVToJSON from "./pages/Converter/CSVToJSON";
import CSVtoXMLConverter from "./pages/Converter/CSVtoXMLConverter";
import JSONtoYAML from "./pages/Converter/JSONtoYAML";
import YAMLtoJSON from "./pages/Converter/YAMLtoJSON";
import EpochToDate from "./pages/Converter/EpochToDate";
import QrCodeGenerator from "./pages/generator/QrCodeGenerator";
import RandomNumberGenerator from "./pages/generator/RandomNumberGenerator";
import DifferenceChecker from "./pages/generator/DifferenceChecker";
import OnlineStopWatch from "./pages/DateTimeTools/OnlineStopWatch";
import OnlineCountDown from "./pages/DateTimeTools/OnlineCountDown";
import TimezoneConverter from "./pages/DateTimeTools/TimezoneConverter";
import JsonEscaper from "./pages/Escaper/JsonEscaper";
import CssManify from "./pages/Minify/CssManify";
import JsMinifier from "./pages/Minify/JsMinifier";
import LoremIpsum from "./pages/lorem-ipsum/LoremIpsum";
import DetailBlog from "./pages/blogs/blog-details/DetailBlog";

import Tags from "./pages/blogs/tag/tags";
import AdminSideBar from "./pages/admin/sidebar/adminSidebar";
import Login from "./pages/admin/login/login";
import AdminDashboard from "./pages/admin/Dashboard/dashboard";
import Blog from "./pages/admin/Blogs/blog";
import Category from "./pages/admin/category/category";
import AddCategory from "./pages/admin/category/add/AddCategory";
import EditCategory from "./pages/admin/category/edit/EditCategory";
import ViewCategory from "./pages/admin/category/view/ViewCategory";
import AddBlog from "./pages/admin/Blogs/add/AddBlog";
import EditBlog from "./pages/admin/Blogs/edit/EditBlog";
import ViewBlog from "./pages/admin/Blogs/view/ViewBlog";
import Tag from "./pages/admin/tags/tags";
import AddTag from "./pages/admin/tags/add/AddTag";
import EditTag from "./pages/admin/tags/edit/EditTag";
import ViewTag from "./pages/admin/tags/view/ViewTag";
import CategoryDetails from "./pages/blogs/category/category";
import ProtectedRoute from "./hooks/ProtectedRoute";
import NotFound from "./components/NotFound/NotFound";
  

function Content({isSidebarOpen}) {

    const location = useLocation();
    const { pathname } = location;

    const isAdminLoginRoute =  pathname.startsWith("/admin"); 

    
  return (
    <div
      className={`${isSidebarOpen ? "content " : "content_two "} ${
        isAdminLoginRoute ? "loginScreen" : ""
      }`}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      {/* Content of the page */}
      <Routes>
        <Route path="/" element={<Dashboard isSidebarOpen={isSidebarOpen} />} />
        {/* formatters */}
        <Route path="/formatter/json-formatter" element={<JsonFormatters />} />
        <Route path="/formatter/html-formatter" element={<HtmlFormatter />} />
        <Route path="/formatter/xml-formatter" element={<XMLFormatter />} />
        <Route path="/formatter/sql-formatter" element={<SQLFormatter />} />
        <Route
          path="/formatter/json-formatted"
          element={<jsonFormatterNewWindow />}
        />
        {/* converters */}
        <Route
          path="/converters/json-xml-converter"
          element={<JsonToXMLConverter />}
        />
        <Route
          path="/converters/xml-json-converter"
          element={<XMLtoJSONConverter />}
        />
        <Route
          path="/converters/csv-xml-converter"
          element={<CSVtoXMLConverter />}
        />
        <Route
          path="/converters/json-yaml-converter"
          element={<JSONtoYAML />}
        />
        <Route
          path="/converters/yaml-json-converter"
          element={<YAMLtoJSON />}
        />
        <Route
          path="/converters/epoch-timestamp-to-date-converter"
          element={<EpochToDate />}
        />
        <Route path="/converters/csv-json-converter" element={<CSVToJSON />} />
        <Route path="/converted" element={<JSONtoXMLconverter />} />
        {/*Generator  */}
        <Route
          path="/generator/qr-code-generator"
          element={<QrCodeGenerator />}
        />
        <Route
          path="/generator/random-number-generator"
          element={<RandomNumberGenerator />}
        />
        <Route
          path="/generator/difference-checker"
          element={<DifferenceChecker />}
        />
        <Route
          path="/date-time/online-stop-watch"
          element={<OnlineStopWatch />}
        />
        <Route
          path="/date-time/online-count-down"
          element={<OnlineCountDown />}
        />
        <Route
          path="/date-time/time-zone-converter"
          element={<TimezoneConverter />}
        />
        <Route path="/escaper/json-escaper" element={<JsonEscaper />} />
        <Route path="/minify/css-minify" element={<CssManify />} />
        <Route path="/minify/js-minify" element={<JsMinifier />} />
        <Route path="/generator/lorem-ipsum" element={<LoremIpsum />} />
        {/*Blog routes */}
        <Route
          path="/blog/:slug"
          element={<DetailBlog isSidebarOpen={isSidebarOpen} />}
        />

        <Route
          path="/blog/yaml-to-json-converter-guide"
          element={
            <Navigate
              to="/blog/yaml-to-json-converter-a-step-by-step-guide"
              replace
            />
          }
        />
        <Route
          path="/blog/csv-to-xml-converter-guide"
          element={
            <Navigate
              to="/blog/csv-to-xml-converter-a-step-by-step-conversion-guide"
              replace
            />
          }
        />
        <Route
          path="/blog/xml-to-json-converter-guide"
          element={
            <Navigate
              to="/blog/xml-to-json-converter-a-step-by-step-conversion-guide"
              replace
            />
          }
        />
        <Route
          path="/blog/yaml-to-json-converter-guide"
          element={
            <Navigate
              to="/blog/yaml-to-json-converter-a-step-by-step-guide"
              replace
            />
          }
        />
        <Route
          path="/blog/json-to-xml-converter-guide"
          element={
            <Navigate
              to="/blog/json-to-xml-converter-a-step-by-step-guide"
              replace
            />
          }
        />

        <Route
          path="/blog/step-by-step-guide"
          element={<Navigate to="/category/tools" replace />}
        />
        <Route
          path="/formatter/xml-formatte"
          element={<Navigate to="/category/tools" replace />}
        />
        <Route
          path="/blog/step-by-step-formatting-guide-sde-tools"
          element={<Navigate to="/category/tools" replace />}
        />

        <Route
          path="/tag/:slug"
          element={<Tags isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/category/:slug"
          element={<CategoryDetails isSidebarOpen={isSidebarOpen} />}
        />
        <Route path="/admin/auth/login" element={<Login />} />
        {/*   <Route path="/admin/admin-dashboard" element={<AdminDashboard />} />*/}
        {/*<Route
          path="/admin/admin-dashboard"
          element={<ProtectedRoute element={<AdminDashboard />} />}
        />
        <Route
          path="/admin/category"
          element={<ProtectedRoute element={<Category />} />}
        />
        element={<ProtectedRoute element={<Category />} />}
        <Route
          path="/admin/category/add-category"
          element={<ProtectedRoute element={<AddCategory />} />}
        />
        <Route
          path="/admin/category/edit-category/:id"
          element={<ProtectedRoute element={<EditCategory />} />}
        />
        <Route
          path="/admin/category/view-category/:id"
          element={<ProtectedRoute element={<ViewCategory />} />}
        />
       
        <Route
          path="/admin/tags"
          element={<ProtectedRoute element={<Tag />} />}
        />
        <Route
          path="/admin/tags/add-tag"
          element={<ProtectedRoute element={<AddTag />} />}
        />
        <Route
          path="/admin/tags/edit-tag/:id"
          element={<ProtectedRoute element={<EditTag />} />}
        />
        <Route
          path="/admin/tags/view-tag/:id"
          element={<ProtectedRoute element={<ViewTag />} />}
        />
      
        <Route
          path="/admin/blogs"
          element={<ProtectedRoute element={<Blog />} />}
        />
        <Route
          path="/admin/blogs/add-blog"
          element={<ProtectedRoute element={<AddBlog />} />}
        />
        <Route
          path="/admin/blogs/edit-blog/:id"
          element={<ProtectedRoute element={<EditBlog />} />}
        />
        <Route
          path="/admin/blogs/view-blog/:id"
          element={<ProtectedRoute element={<ViewBlog />} />}
         
        />*/}

        <Route element={<ProtectedRoute />}>
          <Route path="/admin/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin/category" element={<Category />} />
          <Route
            path="/admin/category/add-category"
            element={<AddCategory />}
          />
          <Route
            path="/admin/category/edit-category/:id"
            element={<EditCategory />}
          />
          <Route
            path="/admin/category/view-category/:id"
            element={<ViewCategory />}
          />

          <Route path="/admin/tags" element={<Tag />} />
          <Route path="/admin/tags/add-tag" element={<AddTag />} />
          <Route path="/admin/tags/edit-tag/:id" element={<EditTag />} />
          <Route path="/admin/tags/view-tag/:id" element={<ViewTag />} />

          <Route path="/admin/blogs" element={<Blog />} />
          <Route path="/admin/blogs/add-blog" element={<AddBlog />} />
          <Route path="/admin/blogs/edit-blog/:id" element={<EditBlog />} />
          <Route path="/admin/blogs/view-blog/:id" element={<ViewBlog />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}


// function SidebarSwitcher({ isSidebarOpen, toggleSidebar }) {
//   const location = useLocation();

//   const isAdminRoute = location.pathname.startsWith('/admin') || location.pathname === '/login';

//   return isAdminRoute ? (
//     <AdminSideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
//   ) : (
//     <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
//   );
// }
function SidebarSwitcher({ isSidebarOpen, toggleSidebar }) {
  const location = useLocation();
  const { pathname } = location;

  const token = sessionStorage.getItem("token");

  const isAdminLoginRoute = pathname === "/admin/auth/login" && !token;
  const isAdminRoute = pathname.startsWith("/admin") && !isAdminLoginRoute && token ;

  if (isAdminLoginRoute) {
    return null; // No sidebar for the admin login route
  }

  return isAdminRoute ? (
    <AdminSideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
  ) : (
    <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
  );
}

 
function App() {
  //  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   const toggleSidebar = () => {
    
     setIsSidebarOpen(!isSidebarOpen);
   };
  
    
    //  useEffect(() => {
    //    const handleResize = () => {
    //      if (window.innerWidth <= 768) {
    //        setIsSidebarOpen(false);
    //      } else {
    //        setIsSidebarOpen(true);
    //      }
    //    };

    //    // Initial check
    //    handleResize();

    //    // Listen for resize events
    //    window.addEventListener("resize", handleResize);

    //    // Cleanup listener on component unmount
    //    return () => {
    //      window.removeEventListener("resize", handleResize);
    //    };
    //  }, []);
 
  return (
    <div className="app-container">
      {/*  <BrowserRouter>
        <SideBar />
        <Routes>
          <Route
            path="/formatter/json-formatter"
            element={<JsonFormatters />}
          />
        </Routes>
  </BrowserRouter>*/}
      <BrowserRouter>
        {/* <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> */}
        <SidebarSwitcher
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />

        <Content isSidebarOpen={isSidebarOpen} />
      </BrowserRouter>
    </div>
  );
}

export default App;
