import React, { useState, useEffect, useRef } from "react";
import "./DateTimeTools.css";
function Stopwatch() {
  const [isRunning, setIsRunning] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalIdRef = useRef(null);
  const startTimeRef = useRef(0);

  useEffect(() => {
    if (isRunning) {
      intervalIdRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTimeRef.current);
      }, 10);
    }

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [isRunning]);

  function start(e) {
      e.preventDefault();
    setIsRunning(true);
    setIsStart(true);

    startTimeRef.current = Date.now() - elapsedTime;
  }

  function stop(e) {
      e.preventDefault();
    setIsRunning(false);
    setIsStart(false);
  }

  function reset(e) {
      e.preventDefault();
    setElapsedTime(0);
    setIsRunning(false);
  }

  function formatTime() {
    let hours = Math.floor(elapsedTime / (1000 * 60 * 60));
    let minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
    let seconds = Math.floor((elapsedTime / 1000) % 60);
    let milliseconds = Math.floor((elapsedTime % 1000) / 10);

    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    milliseconds = String(milliseconds).padStart(2, "0");

    // return `${minutes}:${seconds}:${milliseconds}`;
    return {
      mainTime: `${hours}:${minutes}:${seconds}`,
      milliseconds: milliseconds,
    };
  }
   const { mainTime, milliseconds } = formatTime();

  return (
    <div className="stopwatch main-container">
      {/* <div className="display text-center">{formatTime()}</div>*/}
      <div className="counter-container ">
        <div className="display text-end inner-container-container">
          {mainTime}
          <sup className="milliseconds mt-2 ml-2">{milliseconds}</sup>
        </div>
      </div>
      <div className="controls row">
        <button
          type="button"
          onClick={start}
          className="start-button col-12 col-md-3"
        >
          {elapsedTime > 0 && !isRunning ? "Continue" : "Start"}
        </button>
         
        <button
          type="button"
          onClick={stop}
          className="stop-button col-12  col-md-3"
        >
          Stop
        </button>
        <button
          type="button"
          onClick={reset}
          className="reset-button col-12 col-md-3"
        >
          Reset
        </button>
      </div>
    </div>
  );
}
export default Stopwatch;
