/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, forwardRef } from "react";
import "./DateTimeTools.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useQRCode } from "next-qrcode";
import { saveAs } from "file-saver";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { FaFacebook, FaPinterest, FaWhatsapp } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { SocialIcon } from "react-social-icons";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdShare } from "react-icons/md";
import Stopwatch from "./StopWatch";

const OnlineStopWatch = () => {
  const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // form validation
  const FormValidationSchema = yup.object({
    inputJsonData: yup.string().notRequired("Json data is required"),
    inputJsonFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
    nameOfRootElement: yup.string().required("Root element name  is required"),
    elementNameForJsonArrays: yup
      .string()
      .required("Root element name  is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputJsonData: "",
      inputJsonFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      nameOfRootElement: "root",
      elementNameForJsonArrays: "element",
    },
    resolver: yupResolver(FormValidationSchema),
  });
useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const copyJson = () => {
    navigator.clipboard.writeText(formattedJsonValue).then(
      () => {
        if (formattedJsonValue != "") {
          toast.success("QR Code copied to clipboard");
        }
      },
      () => {
        if (formattedJsonValue != "") {
          toast.warning("Failed to copy QR code to clipboard");
        }
      }
    );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "convertedXml.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);
    setFormattedJsonValue("");

    try {
      if (data?.inputJsonData && data?.inputJsonFile) {
        toast.warning("You need to upload file or use copy Paste only");
      } else if (data?.inputJsonFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputJsonFile", data.inputJsonFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("rootElement", data.nameOfRootElement);
        formData.append(
          "elementNameForJsonArrays",
          data.elementNameForJsonArrays
        );

        try {
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formData, config)
            .then((res) => {
              console.log("response", res);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              setFormattedJsonValue(res.data.data);
              toast.success("Formatting done successfully!");
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                setErrorMessage(error.response.data);
              } else {
                // Display a generic error message
                setError(true);
                setErrorMessage(error.response.data);
              }
            });
        } catch (error) {
          console.log("error1", error);
          toast.warning("Something went wrong!");
        }
      } else {
        try {
          let formatData = {
            inputJsonData: data.inputJsonData,
            incantationalLevel: data.incantationalLevel,
            rootElement: data.nameOfRootElement,
            elementNameForJsonArrays: data.elementNameForJsonArrays,
          };
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formatData)
            .then((res) => {
              console.log("response", res);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }
              setFormattedJsonValue(res.data.data);
              toast.success("Formatting done successfully!");
              setError(false);
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                setErrorMessage(error.response.data);
              } else {
                // Display a generic error message
                toast.error("An error occurred while processing your request.");
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const QRCode = forwardRef((props, ref) => {
    const { Canvas } = useQRCode();

    return (
      <div ref={ref}>
        <Canvas {...props} />
      </div>
    );
  });
  const qrRef = useRef();

  const [urlLink, setUrlLink] = useState("");
  const [showQrCode, setShowQRCode] = useState(false);
  const [errorMessageQR, setErrorMessageQR] = useState(false);
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);

  const [isFileDropped, setIsFileDropped] = useState(false);

  const onDownload = () => {
    const canva = document.getElementsByTagName("canvas")[0];
    canva.toBlob((blob) => {
      saveAs(blob, "qr-code.png");
    });
  };
  const copyQRCodeToClipboard = () => {
    const canvas = qrRef.current?.querySelector("canvas");
    if (canvas) {
      canvas.toBlob((blob) => {
        navigator.clipboard
          .write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ])
          .then(() => {
            toast.success("QR code copied to clipboard successfully!");
          })
          .catch((error) => {
            console.error("Failed to copy QR code to clipboard:", error);
            toast.error("Failed to copy QR code to clipboard.");
          });
      });
    }
  };

  // const shareToSocialMedia = async (platform) => {
  //   const canvas = qrRef.current?.querySelector("canvas");
  //   if (canvas) {

  //     canvas.toBlob(async (blob) => {
  //       try {
  //         // Upload the image to your server
  //         const formData = new FormData();
  //         formData.append("file", blob, "qr-code.png");
  //         const uploadResponse = await axios.post(
  //           "${process.env.REACT_APP_BACKEND_URL}/converter/upload-image",
  //           formData,
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         );
  //         const imageUrl = uploadResponse.data.url;

  //         // Share to the specified social media platform
  //         let shareUrl;
  //         if (platform === "twitter") {
  //           shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
  //             imageUrl
  //           )}&text=${encodeURIComponent("Check out this QR code!")}`;
  //         } else if (platform === "facebook") {
  //           shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //             imageUrl
  //           )}`;
  //         } else if (platform === "instagram") {
  //           // Instagram does not support direct URL sharing for images
  //           toast.info("Please share the image manually on Instagram.");
  //           return;
  //         }

  //         window.open(shareUrl, "_blank");
  //       } catch (error) {
  //         console.error("Failed to upload and share QR code:", error);
  //         toast.error("Failed to share QR code.");
  //       }
  //     });
  //   }
  // };
  const shareToSocialMedia = async (platform) => {
    const canvas = qrRef.current?.querySelector("canvas");
    if (canvas) {
      canvas.toBlob(async (blob) => {
        try {
          // Upload the image to your server
          const formData = new FormData();
          formData.append("file", blob, "qr-code.png");
          const uploadResponse = await axios.post(
            "${process.env.REACT_APP_BACKEND_URL}/converter/upload-image",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          const imageUrl = uploadResponse.data.url;
          console.log("Image url", encodeURIComponent(imageUrl));
          console.log("Image url", imageUrl);
          // Share to the specified social media platform
          let shareUrl;
          switch (platform) {
            case "twitter":
            case "x":
              shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                imageUrl
              )}&text=${encodeURIComponent("Check out this QR code!")}`;
              break;
            case "facebook":
              shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                imageUrl
              )}`;
              break;
            case "instagram":
              toast.info("Please share the image manually on Instagram.");
              return;
            case "whatsapp":
              shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
                "Check out this QR code! " + imageUrl
              )}`;
              break;
            case "pinterest":
              shareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
                imageUrl
              )}&media=${encodeURIComponent(
                imageUrl
              )}&description=${encodeURIComponent("Check out this QR code!")}`;
              break;
            default:
              toast.error("Unsupported platform.");
              return;
          }

          window.open(shareUrl, "_blank");
        } catch (error) {
          console.error("Failed to upload and share QR code:", error);
          toast.error("Failed to share QR code.");
        }
      });
    }
  };

  return (
    <div className="container date-time-container">
      <Card className="card-formatter container-stop-watch border border-0 card rounded-md bg-white shadow shadow-sm">
        <Stopwatch />
      </Card>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">Online Stop Watch</h4>
            <p className="heading-paragraph">
              An online stopwatch is a user-friendly and straightforward digital
              timer that lets you measure the time that has passed. It has the
              accuracy of milliseconds and offers you features like Start, stop,
              and reset.
            </p>
          </div>
          <h6 className="main-heading">About the SDE Tools Editor</h6>
          <p className="main-heading-paragraph">
            The SDE Tools Editor is a flexible code editor designed specifically
            for software development. It offers various features and programming
            languages such as formatting tools integrations, highlighting
            syntax, and completion of code. Additionally, it also provides
            built-in integrated converters for different formats that helps you
            in exchange of your data by easily converting your data to various
            formats like JSON, XML, CSV, and YAML. SDE Tools Editor helps you in
            converting timestamps to date using Epoch and Unix Timestamp
            Converter. Now, with the assistance of QR Code Generator, you can
            easily generate QR Code by just providing the URL or text that you
            desire to encode. The seamless difference checker lets you quickly
            identify the difference between your input code enabling you to keep
            track of changes in the version.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is an Online Stopwatch?</h6>
          <p className="main-heading-paragraph">
            An online stopwatch is like a digital timer that helps you calculate
            the time on a web software. It offers a practical way of calculating
            time simply on the device you're using, cutting away with the need
            for actual stopwatches. It provides you the option for starting and
            stopping the timer at your comfort.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why use Online Stopwatch?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>It helps you to measure time easily on your web devices.</li>
              <li>
                It is perfect for performing tasks seeking great accuracy as
                this tool has the precision of a millisecond.
              </li>
              <li>
                It has a clean and simple interface that you can easily use to
                get accurate time measurements.
              </li>
            </ul>
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnlineStopWatch;
