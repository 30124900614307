
// import { OpenAI } from '@langchain/openai';
import { OpenAI } from '@langchain/openai';


const llm = new OpenAI({
  openAIApiKey: process.env.REACT_APP_OPEN_AI_API_KEY,
  model: "gpt-4o-mini",
});
 
 export const getSolution=async(Question)=>{
    
    let answer=""
    try{
    // answer= await llm.predict(Question)
    answer= await llm.invoke(Question)
  
    }catch(error)
    {
        console.log("error",error)
        //  throw new Error('Maximum retries reached. Please check your quota and try again later.');
    }

return answer

}