/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./formatter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Helmet } from "react-helmet";
import { BsStars } from "react-icons/bs";
import {
  docco,
  dark,
  atelierCaveDark,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { getSolution } from "./langchain";

const SQLFormatter = () => {
  // form validation
  const FormValidationSchema = yup.object({
    inputSqlData: yup.string().notRequired("Json data is required"),
    inputSqlFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
    changeOfKeyWords: yup.string().required("change of keyword Style is required"),
    caseOfIdentifiers: yup.string().required("Case identifiers  is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputSqlData: "",
      inputSqlFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      changeOfKeyWords: "preserve",
      caseOfIdentifiers: "preserve",
    },
    resolver: yupResolver(FormValidationSchema),
  });

  const copyJson = () => {
    navigator.clipboard.writeText(formattedJsonValue).then(
      () => {
        toast.success("SQL copied to clipboard");
      },
      () => {
        toast.warning("Failed to copy SQL to clipboard");
      }
    );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/sql",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "FormattedSql.sql";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  const onSubmit = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);

    try {
      // if (data?.inputSqlData && data?.inputSqlFile) {
      //   toast.warning("You need to upload file or use copy Paste only");
      // } else
       if (data?.inputSqlFile) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();

        formData.append("inputSqlFile", data.inputSqlFile);
        formData.append("fileEncoding", data.fileEncoding);
        formData.append("incantationalLevel", data.incantationalLevel);
        formData.append("keywordCase", data.changeOfKeyWords);
        formData.append("identifiersCase", data.caseOfIdentifiers);

        try {
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/formatter/sql-format`, formData, config)
            .then((res) => {
              console.log("response", res);
  setError(false);
   setSuccess(true);
            //  reset();
   setSuccessMessage("Formatting Done Successfully!");
              setFormattedJsonValue(res.data.formattedSql);
              // toast.success("Formatting done successfully!");
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
              } else {
                // Display a generic error message
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
              }
            });
        } catch (error) {
          console.log("error1", error);
          toast.warning("Something went wrong!");
        }
      } else {
        try {
  
          const formattedData = {
            rowSqlData: data.inputSqlData,
            fileEncoding: data.fileEncoding,
            incantationalLevel: data.incantationalLevel,
            keywordCase: data.changeOfKeyWords,
            identifiersCase: data.caseOfIdentifiers,
          };
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/formatter/sql-format`, formattedData)
            .then((res) => {
              console.log("response", res);
  setError(false);
   setSuccess(true);

   setSuccessMessage("Formatting Done Successfully!");
              setFormattedJsonValue(res.data.formattedSql);
                        // reset();
              // toast.success("Formatting done successfully!");
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data.errorMessage) {
                // Display the error message from the API response
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
              } else {
                // Display a generic error message
                setError(true);
                setShowGenerativeAiButton(true);
                setSuccess(false);
                setErrorMessage(error.response.data.errorMessage);
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
    setValue("incantationalLevel", "THREE_SPACES");
    setValue("changeOfKeyWords", "preserve");
    setValue("caseOfIdentifiers", "preserve");
  }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>JSON Formatted Page</title>
        </head>
        <body>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(htmlContent);
  };

  console.log("errors", errors);
   const CustomInputContent = () => {
     const fileInputRef = useRef(null);

     const [file, setFile] = useState("");
     let fileName;
     const handleBrowseClick = () => {
       fileInputRef.current.click(); // Trigger click event on file input
     };

     const handleFileChange = (e) => {
       // Handle file change event here
       const selectedFile = e.target.files[0];
       console.log("Selected,file", selectedFile.name);
       fileName = selectedFile.name;
       setValue("inputJsonFile", selectedFile);
       setFile(selectedFile?.name);
         setInputJsonValueFile(selectedFile);
     };
     console.log("filee", file);

     return (
       <div className="main-container">
         <div className="custom-dnd">
           <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

           <h6 className="custom-input-text">
             Choose a file 
           </h6>
         </div>
         <div>
           <button
             type="button"
             onClick={handleBrowseClick}
             className="browse-file-button"
           >
             Browse File
           </button>
           <input
             accept=".sql"
             type="file"
             multiple={false}
             ref={fileInputRef}
             style={{ display: "none" }}
             onChange={handleFileChange}
           />
         </div>
         {file && <h6 className="custom-input-text">{file}</h6>}
       </div>
     );
   };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
  const changeOfKeyWordsOptions = [
    {
      label: "Modify to upper case",
      value: "upper",
    },
    {
      label: "Modify to lower case",
      value: "lower",
    },
    {
      label: "Do not modify case",
      value: "preserve",
    },
  ];
  const caseOfIdentifiersOptions = [
    {
      label: "Modify to upper case",
      value: "upper",
    },
    {
      label: "Modify to lower case",
      value: "lower",
    },
    {
      label: "Do not modify case",
      value: "preserve",
    },
  ];
  const indentationLevelOptions = [
    {
      label: "2 spaces per indent level",
      value: "TWO_SPACES",
    },
    {
      label: "3 spaces per indent level",
      value: "THREE_SPACES",
    },
    {
      label: "4 spaces per indent level",
      value: "FOUR_SPACES",
    },
    {
      label: "Tab delimited",
      value: "TABS",
    },
     
  ];

  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  // };
   const styles = {
     option: (provided, state) => ({
       ...provided,
       fontSize: "14px",
     }),
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       // const color = chroma(data.color);

       return {
         ...styles,
         // backgroundColor: isFocused ? "#c00eae" : isSelected ? "#c00eae" : null,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isSelected
         //   ? "#c00eae"
         //   : isFocused
         //   ? "$c00eae"
         //   : undefined,
         backgroundColor: isDisabled
           ? undefined
           : isFocused
           ? "#BF66B6"
           : isSelected
           ? "#c00eae"
           : undefined,
         // backgroundColor: isDisabled
         //   ? undefined
         //   : isFocused || isSelected
         //   ? "#c00eae"
         //   : undefined,
         // color: isFocused || isSelected ? "#fff" : "#000",
         color: isDisabled
           ? "#ccc"
           : isFocused || isSelected
           ? "white"
           : data.color,
         ":active": {
           ...styles[":active"],
           backgroundColor: !isDisabled
             ? isSelected
               ? "#c00eae"
               : "#BF66B6"
             : undefined,
         },
       };
     },
   };
   

  const [fileEncodingValue, setFileEncodingValue] = useState();
  const [indentationLevelValue, setIndentationLevelValue] = useState();
  const [changeOfKeyWordsStyleValue, setChangeOfKeyWordsValue] = useState();
  const [caseIdentifiersStyleValue, setCaseIdentifiersValue] = useState();
  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");

    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

  const [isFileDropped, setIsFileDropped] = useState(false);
      const [error, setError] = useState(false);
          const [showGenerativeAiButton, setShowGenerativeAiButton] =
            useState(false);
      const [errorMessage, setErrorMessage] = useState("");

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputSqlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };
  const customStyle = {
    lineHeight: "1.5",
    fontSize: "1rem",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    padding: "20px",
  };

  // const handleChangeStatus = ({ xhr }) => {
  //   if (xhr) {
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         const result = JSON.parse(xhr.response);
  //         console.log(result);
  //       }
  //     };
  //   }
  // };

  // Function to format JSON data based on selected options
  const [loading, setLoading] = useState(false);

  const fetchSolution = async () => {
    let inputJson = "";
    try {
      if (inputJsonValueFile) {
        setLoading(true);
        console.log("inputJsonValueFile", inputJsonValueFile);
        const file = inputJsonValueFile;
        const reader = new FileReader();
        reader.onload = async (e) => {
          inputJson = e.target.result;
          console.log("inputJson", inputJson);
          const solution = await getSolution(`Correct this SQL ${inputJson}`);
          setFormattedJsonValue(solution);
          setLoading(false);
          setError(false);
        };
        reader.readAsText(file);
      } else {
        setLoading(true);

        // If no file is provided, use a default question or handle the case accordingly
        inputJson = inputJsonValue;
        console.log("inputJson", inputJson);
        const solution = await getSolution(
          `Correct this SQL ${inputJsonValue}`
        );

        setFormattedJsonValue(solution);
        setLoading(false);
        setError(false);
      }

      // const ans = await getSolution("Write a code on sum of 2 numbers in js");
      // console.log("langchain.ans", ans);
    } catch (error) {
      console.error("Error fetching solution:", error);
      document.getElementById("editor_main_id")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };


  return (
    <div className="container-right mt-5 mt-md-0">
      <Helmet>
        <meta charset="UTF-8" />

        <title>
          Free SQL Formatter Online | Beautify SQL Queries on SDE Tools
        </title>

        <meta
          name="description"
          content="SDE Tools' free online SQL formatter beautifies your SQL queries instantly! Just paste your SQL code or upload a file to improve readability with this tool."
        />
        <meta
          name="keywords"
          content="SQL Formatter, SQL Beautify, Free SQL Formatter"
        />

        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://sdetools.io/formatter/sql-formatter"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Free SQL Formatter Online | Beautify SQL Queries on SDE Tools"
        />
        <meta
          property="og:description"
          content="SDE Tools' free online SQL formatter beautifies your SQL queries instantly! Just paste your SQL code or upload a file to improve readability with this tool."
        />
        <meta
          property="og:url"
          content="https://sdetools.io/formatter/sql-formatter"
        />
      </Helmet>

      {/* {error && (
        <>
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai d-none">
              <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
                Format SQL Using Generative Ai
              </button>
            </div>
          )}
        </>
      )} */}

      {error && (
        <>
          <div className="error-message">
            <div className="row">
              <div className="col-8">{errorMessage}</div>
              <div className="col-4">
                <div className="row">
                  <div className="col-10 gen-ai-button">
                    {showGenerativeAiButton && (
                      <button
                        className="btn-hover color-11"
                        onClick={fetchSolution}
                        type="button"
                      >
                        <BsStars
                          className="mr-2"
                          style={{
                            marginRight: "8px",
                            transform: "rotate(-90deg)",
                            transition: "transform 0.3s ease-in-out", // Optional: adds smooth rotation effect
                          }}
                          size={25}
                        />
                        Smart Fix
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    {" "}
                    <button className="closeButton">
                      <MdOutlineClose onClick={() => setError(false)} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="error-message d-none">
            <button className="closeButton">
              <MdOutlineClose onClick={() => setError(false)} />
            </button>
            {errorMessage}
          </div>
          {showGenerativeAiButton && (
            <div className="format-button-generative-ai  d-none">
              {/* <button
                className="format-btn format-button-generative-ai"
                onClick={fetchSolution}
                type="button"
              >
               <BsStars /> Format JSON Using Generative Ai
              </button>*/}
              <button
                className="btn-hover color-11"
                onClick={fetchSolution}
                type="button"
              >
                <BsStars size={25} />
                Smart Fix
              </button>
            </div>
          )}
        </>
      )}
      <br />
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-5 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste your SQL here</label>
              </div>
              <div>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputSqlData", e.target.value);
                  }}
                  placeholder="Copy-paste your SQL here"
                  className="text-area-formatter-new-formatter"
                />
              </div>
            </div>
            <div className="col-12 col-md-2 column-2-center mobile-responsive-col left-container-padding">
              <div className="copy-paste ">
                <label>Upload SQL file</label>
              </div>
              <div>
                <Dropzone
                  classNames="dropzone-container"
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                      border: "1px dashed  #C6C3C3",
                      background: "#f5f5f5",
                      width: "100%",
                      height: "145px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    inputLabelWithFiles: { margin: "20px 3%" },
                  }}
                  canRemove={true}
                  maxFiles={1}
                  PreviewComponent={Preview}
                  accept=".sql"
                  inputContent={<CustomInputContent />}
                />
              </div>

              <div className="col-span-1  col-12 col-md-12  py-2 text-center">
                <button className="format-btn" type="submit">
                  Format SQL
                </button>
              </div>
            </div>

            <div className="col-12 col-md-5 mobile-responsive-col">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Formatted SQL</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>
              <div
                className={`editor-border-new-fomatter ${
                  formattedJsonValue ? "editor-scroll-enable" : ""
                }`}
              >
                <SyntaxHighlighter
                  className="syntax-hiligheter-sql"
                  language="sql"
                  style={docco}
                  customStyle={customStyle}
                >
                  {formattedJsonValue}
                </SyntaxHighlighter>
              </div>
              {/* <Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                disabled
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
            <div className="button-section row">
              <div className="col-span-1 col-12 copy-paste  col-md-3 bg-gray-200 py-2 text-start">
                <label className="label-drop-down">File Encoding</label>
                <Select
                  options={fileEncodingOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={fileEncodingOptions[0]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFileEncodingValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    setValue("fileEncoding", e.value);
                  }}
                  classNamePrefix="select"
                  value={fileEncodingValue}
                />
              </div>

              <div className="col-span-1 col-12 col-md-3 copy-paste  bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Indentation level</label>
                <Select
                  options={indentationLevelOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={indentationLevelOptions[1]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setIndentationLevelValue(e);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    // setValue("courseName", selectedValues);
                    setValue("incantationalLevel", e.value);
                  }}
                  classNamePrefix="select"
                  value={indentationLevelValue}
                />
              </div>
              <div className="col-span-1 col-12  col-md-3 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Change of keywords</label>
                <Select
                  options={changeOfKeyWordsOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={changeOfKeyWordsOptions[2]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setChangeOfKeyWordsValue(e);
                    setValue("changeOfKeyWords", e.value);
                    // setCourseName(e);
                    // const selectedValues = e.map((option: any) => option.value);
                    // console.log("selectedValues", selectedValues);
                    // setValue("courseName", selectedValues);
                  }}
                  classNamePrefix="select"
                  value={changeOfKeyWordsStyleValue}
                />
              </div>
              <div className="col-span-1 col-12 col-md-3 copy-paste bg-gray-200 py-2 text-start">
                <label className="label-drop-down">Case of identifiers</label>
                <Select
                  options={caseOfIdentifiersOptions}
                  isClearable={false}
                  styles={styles}
                  defaultValue={caseOfIdentifiersOptions[2]}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setCaseIdentifiersValue(e);
                    setValue("caseOfIdentifiers", e.value);
                  }}
                  classNamePrefix="select"
                  value={caseIdentifiersStyleValue}
                />
              </div>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">
              Free SQL Formatter Online
            </h4>
            <p className="heading-paragraph">
              This Free SQL Formatter Tool beautifies your SQL queries by
              arranging and structuring them with appropriate line breaks,
              indentation, and alignment. It will assist you in change of
              keywords and case of identifiers including not modifying cases as
              well as modifying upper and lower case. Uses UTF-8 and UTF-16 for
              encoding the text and offers a total of four levels of
              indentation: 2 spaces, 3 spaces, 4 spaces, and tab delimited.
            </p>
          </div>
          <h2 className="main-heading">Benefits of Our SQL Formatter:</h2>
          <ul className="sub-list-ul">
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                className="bold"
              >
                Effortless Formatting:
              </h3>
              Paste your SQL query into the designated input box, and the SQL
              Formatter will instantly generate an output that is visually
              appealing. Indentation is automatically optimized for a clear and
              organized representation of your query's logic.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Improved Readability:
              </h3>
              A well-formatted SQL code significantly improves readability for
              all those collaborating on database projects. Clear indentation
              and structure improves the code maintainability and prevents
              confusion in the future.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Easy Debugging:
              </h3>
              A clean and organized SQL codebase facilitates the identification
              of potential errors and inefficiencies. The intuitive structure
              allows for efficient pinpointing of issues and implementation of
              corrective measures.
            </li>
            <li>
              <h3
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                Online Accessibility and Free Use:
              </h3>
              Our SQL Formatter is readily available online, accessible from any
              device with internet access. There's no need for downloads or
              installations; simply copy and paste your query, and receive your
              formatted SQL in seconds!
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What is SQL?</h6>
          <p className="main-heading-paragraph">
            SQL formatter takes an input SQL query and formats it to provide an
            efficiently structured result while retaining the authenticity of
            the fundamental SQL principles. Code written in SQL (Structured
            Query Language) can get complicated and challenging to read,
            particularly for complicated searches. In order to address this, the
            SQL Formatter automatically formats your SQL queries using line
            breaks, uniform indentation, and appropriate alignment. As a result,
            your queries' rationale and structure become significantly clearer.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why use SQL?</h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                <strong> Effective Debugging:</strong> An efficient debugging
                process is supported by properly organized code, which makes it
                easier to identify issues with your SQL queries.
              </li>
              <li>
                <strong> Enhancing Readability: </strong> It is simpler to
                figure out the general idea and recognize individual sections
                when the various components of the search (SELECT, FROM, WHERE,
                etc.) are visually arranged through uniform indentation and
                alignment.
              </li>
              <li>
                <strong> Improved Maintenance: </strong> A properly structured
                SQL code would help you to save a lot of time and effort as it
                is more simple to change and update.
              </li>
            </ul>
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SQLFormatter;
