/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, forwardRef } from "react";
import "./generattor.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { useQRCode } from "next-qrcode";
import { saveAs } from "file-saver";
import * as yup from "yup";
import Prism from "prismjs";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import ReactDiffViewer from "react-diff-viewer";
const DifferenceChecker = () => {
    const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // form validation
    const FormValidationSchema = yup.object({
        inputJsonData: yup.string().notRequired("Json data is required"),
        inputJsonFile: yup.mixed().notRequired("Json file  is required"),
        fileEncoding: yup.string().required("Json file  is required"),
        incantationalLevel: yup
            .string()
            .required("Incantational level  is required"),
        nameOfRootElement: yup.string().required("Root element name  is required"),
        elementNameForJsonArrays: yup
            .string()
            .required("Root element name  is required"),
    });
    let parseJSON;

    const {
        register,
        reset,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm({
        defaultValues: {
            inputJsonData: "",
            inputJsonFile: "",
            fileEncoding: "UTF-8",
            incantationalLevel: "THREE_SPACES",
            nameOfRootElement: "root",
            elementNameForJsonArrays: "element",
        },
        resolver: yupResolver(FormValidationSchema),
    });

    const CustomInputContentOne = () => {
        const fileInputRef = useRef(null);
 
        const [file, setFile] = useState("");

        const handleBrowseClick = () => {
            fileInputRef.current.click();  
        };

        const handleFileChange = (e) => {


            const selectedFile = e.target.files[0];
            // setFile(selectedFile.name);
            console.log("selectedFile", selectedFile);
            console.log("Selected,file", selectedFile.name);
            if (selectedFile) {



                setInputOneFile(selectedFile);
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;

                    setInputOne(content);
                };
                reader.readAsText(selectedFile);



            }
        };
        console.log("fileUplod", file);


        return (
            <div className="main-container">
                <div className="custom-dnd">
                    <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

                    <h6 className="custom-input-text">
                        Choose a file or drag & drop it here
                    </h6>
                </div>
                <div>
                    <button
                        type="button"
                        onClick={handleBrowseClick}
                        className="browse-file-button"
                    >
                        Browse File
                    </button>
                    <input
                        accept=".txt"
                        type="file"

                        multiple={false}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                </div>
                {file && <h6 className="custom-input-text">{file}</h6>}
            </div>
        );
    };
    const CustomInputContentTwo = () => {
        const fileInputRef = useRef(null);

        const [fileTwo, setFileTwo] = useState("");
        let fileName;
        const handleBrowseClick = () => {
            fileInputRef.current.click(); // Trigger click event on file input
        };

        const handleFileChange = (e) => {
            // Handle file change event here
            const selectedFile = e.target.files[0];

            fileName = selectedFile.name;
            setValue("inputJsonFile", selectedFile);
            setFileTwo(selectedFile?.name);
            setInputTwoFile(selectedFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;

                setInputTwo(content);
            };

            reader.readAsText(selectedFile);
        };


        return (
            <div className="main-container">
                <div className="custom-dnd">
                    <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

                    <h6 className="custom-input-text">
                        Choose a file or drag & drop it here
                    </h6>
                </div>
                <div>
                    <button
                        type="button"
                        onClick={handleBrowseClick}
                        className="browse-file-button"
                    >
                        Browse File
                    </button>
                    <input
                        accept=".txt"
                        type="file"
                        multiple={false}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                </div>
                {fileTwo && <h6 className="custom-input-text">{fileTwo}</h6>}
            </div>
        );
    };

    // preview component
    const Preview = ({ meta }) => {
        const { name, size, percent, status, previewUrl } = meta;
        console.log("dropZOneFile.meta", meta);
        return (
            <div className="preview-box">
                <span className="name">{name}</span> -{" "}
                <span className="status">{size}KB</span>
            </div>
        );
    };
    const PreviewTwo = ({ meta }) => {
        const { name, size, percent, status, previewUrl } = meta;
        console.log("dropZOneFile.meta", meta);
        return (
            <div className="preview-box">
                <span className="name">{name}</span> -{" "}
                <span className="status">{size}KB</span>
            </div>
        );
    };
    const downloadJson = () => {
        const jsonBlob = new Blob([formattedJsonValue], {
            type: "application/txt",
        });
        const url = URL.createObjectURL(jsonBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "convertedXml.txt";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const onSubmit = async (data) => {
        console.log("submitedDAtaJsonFormatter", data);
        setFormattedJsonValue("");

        try {
            if (data?.inputJsonData && data?.inputJsonFile) {
                toast.warning("You need to upload file or use copy Paste only");
            } else if (data?.inputJsonFile) {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                };
                let formData = new FormData();

                formData.append("inputJsonFile", data.inputJsonFile);
                formData.append("fileEncoding", data.fileEncoding);
                formData.append("incantationalLevel", data.incantationalLevel);
                formData.append("rootElement", data.nameOfRootElement);
                formData.append(
                    "elementNameForJsonArrays",
                    data.elementNameForJsonArrays
                );

                try {
                    axios
                        .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formData, config)
                        .then((res) => {
                            console.log("response", res);
                            if (showFormatNewWindow) {
                                setShowEditor(false);
                            } else {
                                setShowEditor(true);
                            }
                            setFormattedJsonValue(res.data.data);
                            toast.success("Formatting done successfully!");
                            setError(false);
                            document.getElementById("editor_main_id")?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                        })
                        .catch((error) => {
                            console.error("API request failed:", error);
                            if (error.response && error.response.data) {
                                // Display the error message from the API response
                                setError(true);
                                setErrorMessage(error.response.data);
                            } else {
                                // Display a generic error message
                                setError(true);
                                setErrorMessage(error.response.data);
                            }
                        });
                } catch (error) {
                    console.log("error1", error);
                    toast.warning("Something went wrong!");
                }
            } else {
                try {
                    let formatData = {
                        inputJsonData: data.inputJsonData,
                        incantationalLevel: data.incantationalLevel,
                        rootElement: data.nameOfRootElement,
                        elementNameForJsonArrays: data.elementNameForJsonArrays,
                    };
                    axios
                        .post(`${process.env.REACT_APP_BACKEND_URL}/converter/json-to-xml`, formatData)
                        .then((res) => {
                            console.log("response", res);
                            if (showFormatNewWindow) {
                                setShowEditor(false);
                            } else {
                                setShowEditor(true);
                            }
                            setFormattedJsonValue(res.data.data);
                            toast.success("Formatting done successfully!");
                            setError(false);
                            document.getElementById("editor_main_id")?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                            // }
                        })
                        .catch((error) => {
                            console.error("API request failed:", error);
                            if (error.response && error.response.data) {
                                // Display the error message from the API response
                                setError(true);
                                setErrorMessage(error.response.data);
                            } else {
                                // Display a generic error message
                                toast.error("An error occurred while processing your request.");
                            }
                        });
                } catch (error) {
                    console.log("error2", error);
                    toast.warning("Something went wrong!");
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const QRCode = forwardRef((props, ref) => {
        const { Canvas } = useQRCode();

        return (
            <div ref={ref}>
                <Canvas {...props} />
            </div>
        );
    });
    const qrRef = useRef();

    const [maxCount, setMaxCount] = useState(1);
    const [showUUIDCode, setShowUUIDCode] = useState(false);
    const [errorMessageDifference, setErrorMessageDifference] = useState(false);

    const [inputOne, setInputOne] = useState("");
    const [inputOneFile, setInputOneFile] = useState();
    const [inputTwo, setInputTwo] = useState("");
    const [inputTwoFile, setInputTwoFile] = useState();

    const [inputJsonValueFile, setInputJsonValueFile] = useState();
    const [formattedJsonValue, setFormattedJsonValue] = useState("");
    const [showDifference, setShowDifference] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);
    const [uuids, setUuids] = useState([]);
    const [isFileDropped, setIsFileDropped] = useState(false);
    const handleChangeStatus = (fileStatus) => {
        if (fileStatus === "done") {
            setIsFileDropped(true);
        } else {
            setIsFileDropped(false);
        }
    };

    const handleShowDifference = () => {
        if (inputOne && inputTwo) {
            setShowDifference(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

        }
        else {
            setShowDifference(false);
            setErrorMessageDifference(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }
    const getUploadParamsOne = ({ file }) => {
        const body = new FormData();
        setValue("inputJsonFile", file);
        setInputJsonValueFile(file);
        setInputOneFile(file)
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            console.log("File content1:", content);
            setInputOne(content);
        };
        reader.readAsText(file);


        body.append("dataFiles", file);
        return { url: "http://localhost:3000/uploadmultifile", body };
    };
    // console.log("input One",inputOne)

    const getUploadParamsTwo = ({ file }) => {
        const body = new FormData();
        console.log("2file", file)
        setValue("inputJsonFile2", file);

        setInputTwoFile(file)
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            console.log("File content2:", content);
            setInputTwo(content);
        };

        reader.readAsText(file);
        body.append("dataFiles", file);
        return { url: "http://localhost:3000/uploadmultifile", body };
    };

    const onDownload = () => {
        const text = uuids.join("\n");
        const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "uuids.txt");
    };
    let highlightSyntax = (str) => (
        <pre
            style={{ display: "inline" }}
            dangerouslySetInnerHTML={{
                __html: Prism.highlight(str, Prism.languages.javascript),
            }}
        />
    );
    const generateUUIDs = () => {
        const newUuids = [];
        for (let i = 0; i < maxCount; i++) {
            newUuids.push(uuidv4());
        }
        setUuids(newUuids);
    };

    return (
      <div className="container">
        {errorMessageDifference && (
          <div className="error-message">
            <button className="closeButton">
              <MdOutlineClose
                onClick={() => setErrorMessageDifference(false)}
              />
            </button>
            Original text and Change text are required !
          </div>
        )}
        <form>
          <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
            {showDifference && (
              <div className="" id="differenceCheck">
                <ReactDiffViewer
                  oldValue={inputOne}
                  newValue={inputTwo}
                  splitView={true}
                  hideLineNumbers={false}
                  leftTitle="Original Text"
                  rightTitle="Changed Text"
                  disableWordDiff={false}

                  //   renderContent={this.highlightSyntax}
                />
              </div>
            )}

            <div className="row">
              <div className="col-12 col-md-6 mobile-responsive-col">
                <div className="copy-paste">
                  <label>Original text</label>
                  <textarea
                    onChange={(e) => {
                      setInputOne(e.target.value);
                      setValue("inputJsonData", e.target.value);
                    }}
                    placeholder="Copy-paste your original text"
                    className="text-area-formatter"
                  />
                </div>

                <div className="copy-paste mt-4">
                  <label>Or upload your Original text file</label>
                </div>
                <div>
                  <Dropzone
                    classNames="dropzone-container"
                    getUploadParams={getUploadParamsOne}
                    onChangeStatus={handleChangeStatus}
                    styles={{
                      dropzone: {
                        overflow: "auto",
                        border: "1px dashed  #C6C3C3",
                        background: "#f5f5f5",
                        width: "100%",
                        height: "145px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      inputLabelWithFiles: { margin: "20px 3%" },
                    }}
                    canRemove={true}
                    maxFiles={1}
                    PreviewComponent={Preview}
                    accept=".txt"
                    inputContent={<CustomInputContentOne />}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 mobile-responsive-col">
                <div className="copy-paste">
                  <label>Changed text</label>
                  <textarea
                    onChange={(e) => {
                      setInputTwo(e.target.value);
                      setValue("inputJsonData", e.target.value);
                    }}
                    placeholder="Copy-paste your Changed text"
                    className="text-area-formatter"
                  />
                </div>

                <div className="copy-paste mt-4">
                  <label>Or upload your Changed text file</label>
                </div>
                <div>
                  <Dropzone
                    classNames="dropzone-container"
                    getUploadParams={getUploadParamsTwo}
                    onChangeStatus={handleChangeStatus}
                    styles={{
                      dropzone: {
                        overflow: "auto",
                        border: "1px dashed  #C6C3C3",
                        background: "#f5f5f5",
                        width: "100%",
                        height: "145px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      inputLabelWithFiles: { margin: "20px 3%" },
                    }}
                    canRemove={true}
                    maxFiles={1}
                    PreviewComponent={PreviewTwo}
                    accept=".txt"
                    inputContent={<CustomInputContentTwo />}
                  />
                </div>
              </div>
              <div className="button-section-2 cursor-pointer difference-checker  ">
                <div className="col-span-1   py-2 text-center">
                  <button
                    className="format-btn-difference"
                    onClick={() => handleShowDifference()}
                    type="button"
                  >
                    Find Difference
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </form>

        <div className="main-content-container">
          <div className="sub-heading">
            <div className="heading-formatter">
              <h4 className="heading-formatter-text">Difference Checker</h4>
              <p className="heading-paragraph">
                SDE Tools Editor’s integrated Difference Checker allows you to
                identify the differences and similarities between two pieces of
                content. You can easily generate the difference between two
                different versions of the same file using our tool.
              </p>
            </div>
            <h6 className="main-heading">About the SDE Tools Editor</h6>
            <p className="main-heading-paragraph">
              The SDE Tools Editor is a flexible code editor designed
              specifically for software development. It offers various features
              and programming languages such as formatting tools integrations,
              highlighting syntax, and completion of code. Additionally, it also
              provides built-in integrated converters for different formats that
              helps you in exchange of your data by easily converting your data
              to various formats like JSON, XML, CSV, and YAML. SDE Tools Editor
              helps you in converting timestamps to date using Epoch and Unix
              Timestamp Converter. Now, with the assistance of QR Code
              Generator, you can easily generate QR Code by just providing the
              URL or text that you desire to encode. The seamless difference
              checker lets you quickly identify the difference between your
              input code enabling you to keep track of changes in the version.
            </p>
          </div>
          <div className="sub-heading">
            <h6 className="main-heading">What is a Difference Checker?</h6>
            <p className="main-heading-paragraph">
              The Difference Checker makes it simpler to figure out the precise
              changes and edits done by displaying the differences between the
              two specified text files. Identify the differences between your
              two content or files easily using our difference checker. It helps
              to clarify the transformation of the files by providing a visual
              representation of the modifications.
            </p>
          </div>
          <div className="sub-heading">
            <h6 className="main-heading">Why use a Difference Checker?</h6>

            <p className="main-heading-paragraph">
              <ul className="sub-list-ul">
                <li>
                  You can know the difference between your two content/ files
                  without any hassle.
                </li>
                <li>
                  You can keep your code quality up to a certain level by seeing
                  possible mistakes or inconsistencies caused during changes.
                </li>
                <li>
                  Save a lot of time and effort on reviewing tasks manually.
                </li>
              </ul>
            </p>
          </div>
          <div className="sub-heading">
            <h6 className="main-heading">
              Why use SDE Tools Validator and Formatter?
            </h6>
            <p className="main-heading-paragraph">
              It automatically formats your code in accordance with the coding
              standards. SDE Tools offers you various advantages:
            </p>

            <p className="main-heading-paragraph">
              <ul className="sub-list-ul">
                <li>
                  It helps in increasing the productivity as it provides you
                  features like code completion and highlighting the syntax
                  minimizing errors.
                </li>
                <li>
                  To fit your coding style, the editor lets you adjust
                  formatting choices and handles several file encodings.
                </li>

                <li>
                  It enhances the readability by providing clarity to all the
                  developers.
                </li>
              </ul>
            </p>
          </div>

          <div className="sub-heading">
            <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
            <p className="main-heading-paragraph">
              The validator functions similarly to a computerized code reviewer.
              It goes line by line through your code. It checks:
            </p>
            <p className="main-heading-paragraph">
              <ul className="sub-list-ul">
                <li>
                  Mistakes such as mismatched braces, improper function calls,
                  and misplaced semicolons.
                </li>
                <li>
                  The coding style differentiation such as indentation spaces.
                </li>

                <li>
                  A report is provided by the validator which includes
                  identified problems and enables you to fix those problems.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
};

export default DifferenceChecker;
