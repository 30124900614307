import React from 'react'
import "./subscribe.css"

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
const Subscribe = () => {

    const validationSchema = Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      accepted: Yup.boolean()
        .oneOf([true], "You must accept the terms and conditions")
        .required("You must accept the terms and conditions"),
    });


     const handleSubmit = async (values, { setSubmitting, resetForm }) => {
       try {
         // Replace with your actual backend URL
         const response = await axios.post(
           `${process.env.REACT_APP_BACKEND_URL}/blog/subscribe/add`,
           {
             email: values.email,
           }
         );
     
toast.success("Successfully Subscribed!")
         // Optionally reset form after successful submission
         resetForm();
       } catch (error) {
         console.error("Error submitting form:", error);
         toast.error("Unable to Subscribed!");
       } finally {
         setSubmitting(false);
       }
     };
  return (
    <div className="cs-site-subscribe ">
      <div className="cs-container container container-lg ">
        <div className="cs-site-subscribe__item">
          <div className="cs-site-subscribe__form">
            <div className='class="cs-site-subscribe__info"'>
              <span className="h5 cs-site-subscribe__title">
                Subscribe to The SDE Tools Blog
              </span>
              <div className="cs-site-subscribe__info-text">
                Get updates on latest posts and exclusive deals straight to your
                inbox
              </div>
              <div className="pk-subscribe-form-wrap pk-subscribe-form-block">
                <div className="pk-subscribe-container ">
                  <Formik
                    initialValues={{ email: "", accepted: false }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <div className="pk-input-group">
                              <Field
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                className="form-control"
                              />

                              {errors.email && touched.email && (
                                <div className="error-message-text">
                                  {errors.email}
                                </div>
                              )}

                              <div className="pk-privacy">
                                <Field type="checkbox" name="accepted" />
                                By checking this box, you confirm that you have
                                read and are agreeing to our terms of use
                                regarding the storage of the data submitted
                                through this form.
                              </div>
                              {errors.accepted && touched.accepted && (
                                <div className="error-message-text">
                                  {errors.accepted}
                                </div>
                              )}
                              <div className="spacer"></div>
                            </div>
                          </div>
                          <div className="col-2 mt-4 subscribe-btn">
                            <div className="button-container">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="pk-subscribe-submit"
                              >
                                Subscribe
                              </button>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe