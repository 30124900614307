import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      navigate("/admin/auth/login");
    }

    setLoading(false);
  }, [navigate]);

  return { isAuthenticated, loading };
};

export default useAuth;
