/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./converter.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";

const EpochToDate = () => {
  const [redirectToHtmlPage, setRedirectToHtmlPage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // form validation
  const FormValidationSchema = yup.object({
    timestamp: yup.string().notRequired("Timestamp is required"),
  });
  const FormValidationSchemaDateToEpoch = yup.object({
    year: yup.number().required("Year is required"),
    month: yup.number().required("Month is required"),
    date: yup.number().required("Date is required"),
    hours: yup.number().required("Hours is required"),
    minutes: yup.number().required("Minutes is required"),
    seconds: yup.number().required("seconds is required"),
    timeZone: yup.number().required("Time Zone is required"),
  });
  let parseJSON;

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      timestamp: "",
      year: "2024",
      month: "1",
      date: "1",
      hours: "0",
      minutes: "0",
      seconds: "0",
      timeZone: "LOCAL",
    },
    resolver: yupResolver(
      FormValidationSchemaDateToEpoch
        ? FormValidationSchema
        : FormValidationSchemaDateToEpoch
    ),
  });
  //    const fileForm = useForm({
  //      defaultValues: {
  //    year: "",
  //    month: "",
  //    date: "",
  //    hours: "",
  //    minutes: "",
  //    seconds: "",
  //    timeZone: "LOCAL",
  //        // Add default values for file form here
  //      },
  //      register,
  //      reset,
  //      formState: { errors },
  //      setValue,
  //      handleSubmit,
  //      resolver: yupResolver(FormValidationSchemaDateToEpoch),
  //    });

  useEffect(() => {
    setValue("year", 2024);
  }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const fileForm = useForm({
    defaultValues: {
      year: "2024",
      month: "",
      date: "",
      hours: "",
      minutes: "",
      seconds: "",
      timeZone: "LOCAL",
      // Add default values for file form here
    },
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit: handleSubmit, // Use handleSubmit from the second form instance
    resolver: yupResolver(FormValidationSchemaDateToEpoch),
  });

  const copyJson = () => {
    navigator.clipboard.writeText(formattedJsonValue).then(
      () => {
        if (formattedJsonValue != "") {
          toast.success("JSON copied to clipboard");
        }
      },
      () => {
        if (formattedJsonValue != "") {
          toast.warning("Failed to copy JSON to clipboard");
        }
      }
    );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([formattedJsonValue], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "convertedXml.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
     if (url) {
       toast.success("File downloaded successfully!");
     }
  };

  const onSubmit = async (data) => {
    // setFormattedJsonValue("");

    try {
      if (data?.timestamp && data?.inputYamlFile) {
        toast.warning("You need to upload file or use copy Paste only");
      } else {
        try {
          let formattedData = {
            timestamp: parseInt(data.timestamp),
            userTimezone: userTimeZone,
          };

          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/converter/unixtodate`, formattedData)
            .then((res) => {
              console.log("response", res.data.data.local);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }

              


              setLocalTime(res.data.data.local);
              setGmtTime(res.data.data.gmt);
              setFormattedJsonValue(JSON.stringify(res.data.data));
              setSuccess(true);
              setSuccessMessage("Conversion Done Successfully!");
              setError(false);
              
              // reset();
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
                // Display the error message from the API response
                setError(true);
                                setSuccess(false);

                setErrorMessage(error.response.data);
              } else {
                // Display a generic error message
                // toast.error(
                //   "An error occurred while processing your request."
                // );
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const onSubmitDefault = async (data) => {
    // setFormattedJsonValue("");

    try {
      if (data?.timestamp && data?.inputYamlFile) {
        toast.warning("You need to upload file or use copy Paste only");
      } else {
        try {
          let formattedData = {
            timestamp: parseInt(data.timestamp),
            userTimezone: userTimeZone,
          };

          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/converter/unixtodate`,
              formattedData
            )
            .then((res) => {
              console.log("response", res.data.data.local);
              if (showFormatNewWindow) {
                setShowEditor(false);
              } else {
                setShowEditor(true);
              }

              setLocalTime(res.data.data.local);
              setGmtTime(res.data.data.gmt);
              setFormattedJsonValue(JSON.stringify(res.data.data));
             
              setError(false);
              // reset();
              document.getElementById("editor_main_id")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              // }
            })
            .catch((error) => {
              console.error("API request failed:", error);
              if (error.response && error.response.data) {
              
                setError(true);
                setSuccess(false);

                setErrorMessage(error.response.data);
              } else {
                 
              }
            });
        } catch (error) {
          console.log("error2", error);
          toast.warning("Something went wrong!");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmitDateToEpoch = async (data) => {
    console.log("submitedDAtaJsonFormatter", data);
    setFormattedJsonValue("");

    try {
      try {
        let formattedData = {
          year: data.year,
          month: data.month,
          date: data.date,
          hours: data.hours,
          minutes: data.minutes,
          seconds: data.seconds,
          timeZone: data.timeZone,
          userTimezone: userTimeZone,
        };

        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/converter/datetounix`, formattedData)
          .then((res) => {
            // console.log("response", res.data.data);
            if (showFormatNewWindow) {
              setShowEditor(false);
            } else {
              setShowEditor(true);
            }
            setFormattedDateToEpoch(res?.data?.data?.toString());
            setSuccess(true);
            setSuccessMessage("Conversion Done Successfully!");
            setError(false);
              //  reset();
            document.getElementById("editor_main_id")?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            // reset();
          })
          .catch((error) => {
            console.error("API request failed:", error);
            if (error.response && error.response.data) {
              // Display the error message from the API response
              setError(true);
                              setSuccess(false);

              setErrorMessage(error.response.data);
            } else {
              // Display a generic error message
              toast.error("An error occurred while processing your request.");
            }
          });
      } catch (error) {
        console.log("error2", error);
        // toast.warning("Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [localTime, setLocalTime] = useState("");
  const [GmtTime, setGmtTime] = useState("");
  

  const XMLtoHTMLConverter = ({ xml }) => {
    console.log("xml", xml);
    // Convert XML to HTML
    const convertToHtml = (xml) => {
      // Your XML to HTML conversion logic here
      return `<div>${xml}</div>`; // For demonstration, just wrapping XML in a <div>
    };

    const htmlContent = convertToHtml(xml);
    console.log("htmlContent", htmlContent);
    // Get the navigate function for programmatic navigation

    // Navigate to a new page with the converted HTML content
    // navigate("/html-page", { state: { htmlContent } });
    <Link
      to={{
        pathname: "/converted",
        state: { htmlContent: formattedJsonValue },
      }}
      target="_blank" // Open the link in a new tab
    ></Link>;

    // This component doesn't render anything directly as it's just for conversion and navigation
    return null;
  };

  const handleClick = () => {
    setRedirectToHtmlPage(true);
  };

  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>XML Converted Page</title>
        </head>
        <body>
        <XMLDisplay/>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(<XMLtoHTMLConverter xml={formattedJsonValue} />);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputYamlFile", selectedFile);
      setFile(selectedFile?.name);
    };
    console.log("fileee", file);

    if (redirectToHtmlPage) {
      return <XMLtoHTMLConverter xml={formattedJsonValue} />;
    }

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file or drag & drop it here
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".yaml"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };

  const [inputJsonValue, setInputJsonValue] = useState();
  const [inputJsonValueFile, setInputJsonValueFile] = useState();
  const [formattedJsonValue, setFormattedJsonValue] = useState("");
  const [formattedDateToEPochValue, setFormattedDateToEpoch] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [showFormatNewWindow, setShowFormatNewWindow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  

  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleChangeStatus = (fileStatus) => {
    if (fileStatus === "done") {
      setIsFileDropped(true);
    } else {
      setIsFileDropped(false);
    }
  };
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputYamlFile", file);
    setInputJsonValueFile(file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };

  const months = [
    { value: 1, label: "01 - January" },
    { value: 2, label: "02 - February" },
    { value: 3, label: "03 - March" },
    { value: 4, label: "04 - April" },
    { value: 5, label: "05 - May" },
    { value: 6, label: "06 - June" },
    { value: 7, label: "07 - July" },
    { value: 8, label: "08 - August" },
    { value: 9, label: "09 - September" },
    { value: 10, label: "10 - October" },
    { value: 11, label: "11 - November" },
    { value: 12, label: "12 - December" },
  ];
  const timeZone = [
    { value: "LOCAL", label: "Local" },
    { value: "GMT", label: "GMT" },
  ];
  const dates = [
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
  ];
  const hours = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },

    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
  ];
  const minute = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },

    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
    { value: 32, label: "32" },
    { value: 33, label: "33" },
    { value: 34, label: "34" },
    { value: 35, label: "35" },
    { value: 36, label: "36" },
    { value: 37, label: "37" },
    { value: 38, label: "38" },
    { value: 39, label: "39" },
    { value: 40, label: "40" },
    { value: 41, label: "41" },
    { value: 42, label: "42" },
    { value: 43, label: "43" },
    { value: 44, label: "44" },
    { value: 45, label: "45" },
    { value: 46, label: "46" },
    { value: 47, label: "47" },
    { value: 48, label: "48" },
    { value: 49, label: "49" },
    { value: 50, label: "50" },
    { value: 51, label: "51" },
    { value: 52, label: "52" },
    { value: 53, label: "53" },
    { value: 54, label: "54" },
    { value: 55, label: "55" },
    { value: 56, label: "56" },
    { value: 57, label: "57" },
    { value: 58, label: "58" },
    { value: 59, label: "59" },
  ];

  const [epochTime, setEpochTime] = useState(Date.now());

  const [milliseconds, setMilliseconds] = useState(Date.now());
  //  const [initialTimestamp, setInitialTimestamp] = useState(Date.now());
   const [initialTimestamp, setInitialTimestamp] = useState(
     Math.floor(Date.now() / 1000)
   );
  const [millisTimerID, setMillisTimerID] = useState(null);
  const [timerID, setTimerID] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isMillisRunning, setIsMillisRunning] = useState(false);

  const [userTimeZone,setUserTimeZone]=useState("")
  const [userTimeZoneData,setUserTimeZoneData]=useState("")

  const startTimer = () => {
    if (isRunning) {

      const timer = setInterval(() => {
      setEpochTime(Date.now());
        // setEpochTime((prevTime) => prevTime + 1000); // Update time every second
      }, 1000);
      console.log("timer", timer);
      setTimerID(timer);
      setIsRunning(true);
    }
  };

  // Function to stop the timer
  const stopTimer = () => {
    clearInterval(timerID);
    setTimerID(null);

    setIsRunning(false);
  };

  const startMillisTimer = () => {

    if (isMillisRunning) {

      const millisTimer = setInterval(() => {
        setMilliseconds(Date.now()); // Update milliseconds continuously
      }, 1);
      setMillisTimerID(millisTimer);
      setIsMillisRunning(true);
    }
  };

  // Function to stop the milliseconds timer
  const stopMillisTimer = () => {
    clearInterval(millisTimerID);
    setMillisTimerID(null);
    setIsMillisRunning(false);
  };

  useEffect(() => {
    if (isRunning) {
      startTimer();
    } else {
      stopTimer();
    }
     

    return () => {
      // Cleanup
      clearInterval(timerID);
     
    };
  }, [isRunning]);

  useEffect(() => {


    if (isMillisRunning) {
      startMillisTimer();
    } else {
      stopMillisTimer();
    }

    return () => {
      // Cleanup

      clearInterval(millisTimerID);
    };
  }, [isMillisRunning]);

useEffect(() => {
  const timer = setTimeout(() => {
    setIsRunning(true);
    setIsMillisRunning(true);
  }, 3000); // 3000 milliseconds = 3 seconds

  return () => {
    clearTimeout(timer); // Cleanup if component unmounts before the timer expires
  };
}, []);
 useEffect(() => {
   // Get the user's timezone
   const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   const userTimezone2 = Intl.DateTimeFormat().resolvedOptions().locale;
    
  setUserTimeZone(userTimezone);
  setUserTimeZoneData(userTimezone2);
  //  const initialData = { timestamp: Date.now(), userTimezone: userTimezone }; 
  //  onSubmit(initialData);
 
 }, []);

  useEffect(() => {
   const initialData = {
     timestamp: Math.floor(Date.now() / 1000),
     userTimezone: userTimeZone,
   }; 
      setValue("timestamp", Math.floor(Date.now() / 1000));
    onSubmitDefault(initialData);
  
  }, [initialTimestamp]);

   
 

 
  return (
    <div className="container">
      {error && (
        <div className="error-message">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setError(false)} />
          </button>
          {errorMessage}
        </div>
      )}
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-6 mobile-responsive-col">
              <div className="formatted-heading-epoch">
                Epoch Time in Seconds
              </div>{" "}
              <div className="row epoch-input">
                <div className="col-md-2 col-12 epoch-time-showing">
                  {Math.floor(epochTime / 1000)}
                </div>

                <div className="col-md-6 col-12 epoch-button">
                  <button
                    className="start-stop"
                    type="button"
                    onClick={stopTimer}
                  >
                    Stop
                  </button>
                  |
                  <button
                    type="button"
                    className="start-stop-end"
                    onClick={() => setIsRunning(true)}
                  >
                    Resume
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mobile-responsive-col">
              {" "}
              <div className="formatted-heading-epoch">
                Epoch Time in Milliseconds
              </div>
              <div className="row epoch-input">
                <div className=" col-md-3 col-12 epoch-time-showing">
                  {milliseconds}
                </div>

                <div className="col-md-6 col-12 epoch-button ">
                  <button
                    type="button"
                    className="start-stop"
                    onClick={stopMillisTimer}
                  >
                    Stop
                  </button>
                  |
                  <button
                    type="button"
                    className="start-stop-end"
                    onClick={() => setIsMillisRunning(true)}
                  >
                    Resume
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="card-formatter d-none mt-5 border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="formatted-heading-epoch">
            Epoch Time in Milliseconds
          </div>
          <div className="row epoch-input">
            <div className=" col-md-3 col-12 epoch-time-showing">
              {milliseconds}
            </div>

            <div className="col-md-9 col-12 d-none">
              <button type="button" onClick={() => stopMillisTimer}>
                Stop
              </button>
              |
              <button type="button" onClick={() => startMillisTimer}>
                Resume
              </button>
            </div>
          </div>
        </Card>
        <Card className="card-formatter mt-5 border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-4">
              <h6 className="formatted-heading-epoch">
                Epoch timestamp to date
              </h6>
            </div>
            <div className="col-2"></div>

            <div className="col-6 ">
              <h6 className="formatted-heading-epoch mobile-responsive-timer">
                Converted Date
              </h6>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <div className="copy-paste">
                <label>Enter Epoch</label>
                <input
                  type="text"
                  defaultValue={initialTimestamp}
                  onChange={(e) => {
                    setInputJsonValue(e.target.vlue);
                    setValue("timestamp", e.target.value);
                  }}
                  placeholder="Epoch in seconds or millis"
                  className="epoch-converter-input"
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <div className="button-section-epoch mt-3 grid grid-cols-2 gap-2">
                <div className="col-span-1 format-btn   py-2 text-center">
                  <button
                    onClick={() => setShowFormatNewWindow(false)}
                    type="submit"
                  >
                    Convert
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 d-block d-md-none">
              <h6 className="formatted-heading-epoch mobile-responsive-timer-show">
                Converted Date
              </h6>
            </div>
            <div className="col-12 col-md-6 col-lg-6 ">
              <div className="row" id="editor_main_id">
                <label>&nbsp;</label>
                {GmtTime && localTime && (
                  <div>
                    <div className="container-epoch-converted-date">
                      <h6 className="formatted-heading-epoch-Date">
                        Local Time:
                      </h6>

                      {localTime}
                    </div>
                    <div className="container-epoch-converted-date">
                      <h6 className="formatted-heading-epoch-Date">
                        GMT Time :
                      </h6>
                      {GmtTime}
                    </div>
                  </div>
                )}
              </div>

              {/*<Editor
                  value={formattedJsonValue}
                  onValueChange={(code) => setFormattedJsonValue(code)}
                  highlight={(code) => highlight(code, languages.js)}
                  padding={10}
                  disabled
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    border: "2px solid ",
                    borderColor: "#e4e4e4",
                    borderRadius: "10px",
                    minHeight: "80%",
                  }}
                />*/}
            </div>
          </div>
        </Card>

        {showEditor && (
          <div>
            <div>
              <h6 className="formatted-heading hidden d-none">
                -ConvertedJSON-
              </h6>
            </div>

            <Card
              id="editor_main_id"
              className="card-formatter d-none hidden border border-0 card rounded-md bg-white shadow shadow-sm"
            >
              <div className="editor_holder_button">
                <button
                  onClick={copyJson}
                  type="button"
                  className="editor_holder_button_bg"
                >
                  <BiCopyAlt />
                </button>
                <button
                  onClick={downloadJson}
                  type="button"
                  className="editor_holder_button_bg ms-3"
                >
                  <RiFileDownloadFill />
                </button>
              </div>
              <div className="main_editor">
                <Editor
                  value={formattedJsonValue}
                  onValueChange={(code) => setFormattedJsonValue(code)}
                  highlight={(code) => highlight(code, languages.js)}
                  padding={10}
                  disabled
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                  }}
                />
              </div>
            </Card>
          </div>
        )}
      </form>
      <form onSubmit={handleSubmit(onSubmitDateToEpoch)}>
        <Card className="card-formatter border mt-5 border-0 card rounded-md bg-white shadow shadow-sm">
          <h6 className="formatted-heading-epoch">
            Convert date to epoch timestamp
          </h6>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="copy-paste">
                <div className="row">
                  <div className="col-sm-auto">
                    <div>
                      <label>Year</label>
                      <input
                        defaultValue={"2024"}
                        type="number"
                        onChange={(e) => {
                          setInputJsonValue(e.target.value);
                          setValue("year", e.target.value);
                        }}
                        placeholder="Year"
                        className="epoch-converter-input"
                      />
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <label>Month</label>

                      <select
                        onChange={(e) => {
                          setValue("month", e.target.value);
                        }}
                        className="epoch-select"
                      >
                        {months.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <label>Date</label>

                      <select
                        onChange={(e) => {
                          setValue("date", e.target.value);
                        }}
                        className="epoch-select"
                      >
                        {dates.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <label>Hours</label>

                      <select
                        onChange={(e) => {
                          setValue("hours", e.target.value);
                        }}
                        className="epoch-select"
                      >
                        {hours.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <label>Minutes</label>

                      <select
                        onChange={(e) => {
                          setValue("minutes", e.target.value);
                        }}
                        className="epoch-select"
                      >
                        {minute.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <label>Seconds</label>

                      <select
                        onChange={(e) => {
                          setValue("seconds", e.target.value);
                        }}
                        className="epoch-select"
                      >
                        {minute.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <label>Time zone</label>

                      <select
                        onChange={(e) => {
                          setValue("timeZone", e.target.value);
                        }}
                        className="epoch-select"
                      >
                        {timeZone.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <label>&nbsp;</label>
              <div className="button-section-epoch mt-3  ">
                <div className="col-span-1 button-container py-2 text-center">
                  <button
                    className="format-btn-epoch "
                    onClick={() => setShowFormatNewWindow(false)}
                    type="submit"
                  >
                    Convert
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 ">
            <div className="row" id="editor_main_id">
              <div className="col-8">
                <h6 className="formatted-heading-epoch">Converted Epoch</h6>
              </div>

              <div className="editor_holder_button col-4 mb-2">
                <button
                  onClick={copyJson}
                  type="button"
                  className="editor_holder_button_bg"
                >
                  <BiCopyAlt />
                </button>
                <button
                  onClick={() => {
                    if (formattedJsonValue != "") {
                      downloadJson();
                    }
                  }}
                  type="button"
                  className="editor_holder_button_bg ms-3"
                >
                  <RiFileDownloadFill />
                </button>
              </div>
            </div>
            <Editor
              // value={formattedJsonValue}
              // onValueChange={(code) => setFormattedJsonValue(code)}
              value={formattedDateToEPochValue}
              onValueChange={(code) => setFormattedDateToEpoch(code)}
              highlight={(code) => highlight(code, languages.js)}
              padding={10}
              disabled
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                border: "2px solid ",
                borderColor: "#e4e4e4",
                borderRadius: "10px",
                minHeight: "80%",
              }}
            />
          </div>
        </Card>

        {showEditor && (
          <div>
            <div>
              <h6 className="formatted-heading hidden d-none">
                -ConvertedJSON-
              </h6>
            </div>

            <Card
              id="editor_main_id"
              className="card-formatter d-none hidden border border-0 card rounded-md bg-white shadow shadow-sm"
            >
              <div className="editor_holder_button">
                <button
                  onClick={copyJson}
                  type="button"
                  className="editor_holder_button_bg"
                >
                  <BiCopyAlt />
                </button>
                <button
                  onClick={downloadJson}
                  type="button"
                  className="editor_holder_button_bg ms-3"
                >
                  <RiFileDownloadFill />
                </button>
              </div>
              <div className="main_editor">
                <Editor
                  value={formattedDateToEPochValue}
                  onValueChange={(code) => setFormattedDateToEpoch(code)}
                  highlight={(code) => highlight(code, languages.js)}
                  padding={10}
                  disabled
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                  }}
                />
              </div>
            </Card>
          </div>
        )}
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">
              Epoch & Unix Timestamp Converter
            </h4>
            <p className="heading-paragraph">
              The Epoch timestamp, also known as the Unix timestamp, is the
              total number of seconds that have passed since January 1, 1970
              (midnight UTC/GMT), excluding leap seconds (in ISO 8601:
              1970-01-01T00:00:00Z). Using the Epoch & Unix Timestamp Converter,
              you can convert between times and dates that are readable by
              humans and the Unix timestamps that relate to them. You can enter
              epoch in seconds or milliseconds and get the converted date easily
              in SDE Tools. The timestamp can be shown in seconds, milliseconds,
              or any other preferred device, and it allows several date and time
              input types.
            </p>
          </div>
          <h6 className="main-heading">About the SDE Tools Editor</h6>
          <p className="main-heading-paragraph">
            The SDE Tools Editor is a flexible code editor designed specifically
            for software development. It offers various features and programming
            languages such as formatting tools integrations, highlighting
            syntax, and completion of code. Additionally, it also provides
            built-in integrated converters for different formats that helps you
            in exchange of your data by easily converting your data to various
            formats like JSON, XML, CSV, and YAML. SDE Tools Editor helps you in
            converting timestamps to date using Epoch and Unix Timestamp
            Converter. Now, with the assistance of QR Code Generator, you can
            easily generate QR Code by just providing the URL or text that you
            desire to encode. The seamless difference checker lets you quickly
            identify the difference between your input code enabling you to keep
            track of changes in the version.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            What is an Epoch & Unix Timestamp Converter?{" "}
          </h6>
          <p className="main-heading-paragraph">
            It's a time-based display system. It makes no difference where you
            are at this point. Periods are frequently depicted by computers
            using timestamps. This converter fills in the gap between this
            numerical depiction of time and dates that are visible to humans.
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">
            Why use Epoch & Unix Timestamp Converter?
          </h6>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                {" "}
                They can be used for API (Application Programming Interfaces)
                interactions as some APIs tend to use Epoch time stamps for the
                interchange of data.
              </li>
              <li>
                Unix timestamps are widely used in system log files and file
                timestamps. For simpler understanding, you can use this
                converter to transform numerical data into dates and times that
                are straightforward to humans.
              </li>
              <li>
                Time stamps are very useful and important for interpreting
                time-series data. With the help of this converter, timestamps
                can be transformed into a structure that is appropriate for
                additional research and analysis.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EpochToDate;
