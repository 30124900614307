import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation } from "react-router-dom";
import { SidebarData } from "./../SideBar/SidebarData"

function BreadcrumbComponent() {
  const location = useLocation();

  // Function to find the current sidebar item based on the URL pathname
  const findCurrentItem = (path, items) => {
    for (const item of items) {
      if (item.path === path) {
        return item;
      }
      if (item.subNav) {
        const foundItem = findCurrentItem(path, item.subNav);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  };

  // Get the current sidebar item based on the URL pathname
  const currentItem = findCurrentItem(location.pathname, SidebarData);

  // Generate breadcrumbs based on the current sidebar item
  const generateBreadcrumbs = (item) => {
    const breadcrumbs = [];
    let parentItem = item;
    while (parentItem) {
      breadcrumbs.unshift(parentItem);
      parentItem = findCurrentItem(parentItem.parentPath, SidebarData);
    }
    return breadcrumbs;
  };

  // Render the breadcrumbs
  return (
    <Breadcrumb>
      {generateBreadcrumbs(currentItem).map((item, index) => (
        <Breadcrumb.Item
          key={index}
          href={item.path}
          active={index === generateBreadcrumbs(currentItem).length - 1}
        >
          {item.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default BreadcrumbComponent;
