/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./minifier.css";
import Card from "react-bootstrap/Card";
import { Formik, Field } from "formik";
import { useForm, Controller } from "react-hook-form";
import chroma from "chroma-js";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactJson from "react-json-view";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { JSONViewer } from "react-json-editor-viewer";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { MdOutlineClose } from "react-icons/md";
import MonacoEditor from "react-monaco-editor";
import UploadIcon from "../../assets/DND/upload.png";
import Select from "react-select";
import axios from "axios";
import { BiCopyAlt } from "react-icons/bi";
import { RiFileDownloadFill } from "react-icons/ri";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  blockCommentFolding,
  markdownFolding,
  readOnlyCodeFolding,
} from "prism-code-editor/code-folding";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/mode-javascript";

// Optionally import other languages or themes you need
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/ext-language_tools";

const JsMinifier = () => {
  // form validation
  const FormValidationSchema = yup.object({
    inputJsonData: yup.string().notRequired("Json data is required"),
    inputJsonFile: yup.mixed().notRequired("Json file  is required"),
    fileEncoding: yup.string().required("Json file  is required"),
    incantationalLevel: yup
      .string()
      .required("Incantational level  is required"),
    bracketStyle: yup.string().required("Bracket Style is required"),
  });
  let parseJSON;

  const [indentationLevel, setIndentationLevel] = useState(3);
  const [bracketStylesView, setBracketStylesView] = useState(false);

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inputJsonData: "",
      inputJsonFile: "",
      fileEncoding: "UTF-8",
      incantationalLevel: "THREE_SPACES",
      bracketStyle: "COLLAPSE",
    },
    resolver: yupResolver(FormValidationSchema),
  });

  const copyJson = () => {
    navigator?.clipboard
      ?.writeText(JSON.stringify(formattedJsonValue, null, indentationLevel))
      .then(
        () => {
          if (formattedJsonValue != "") {
            toast.success("Minified JS copied to clipboard");
          }
        },
        () => {
          if (formattedJsonValue != "") {
            toast.warning("Failed to copy Minified JS to clipboard");
          }
        }
      );
  };

  const downloadJson = () => {
    const jsonBlob = new Blob([JSON.stringify(formattedJsonValue)], {
      type: "application/txt",
    });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "MinifiedJS.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    if (url) {
      toast.success("File downloaded successfully!");
    }
  };
  const { REACT_APP_BACKEND_URL } = process.env;

  const onSubmit = async (data) => {};

  useEffect(() => {
    setValue("fileEncoding", "UTF-8");
    setValue("incantationalLevel", "THREE_SPACES");

    setValue("bracketStyle", "COLLAPSE");
    setBracketStylesView(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleButtonClick = () => {
    const newTab = window.open("", "_blank");

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>JSON Formatted Page</title>
        </head>
        <body>
            <pre>${formattedJsonValue}</pre>
        </body>
        </html>
    `;

    newTab.document.write(htmlContent);
  };

  console.log("errors", errors);
  const CustomInputContent = () => {
    const fileInputRef = useRef(null);

    const [file, setFile] = useState("");
    let fileName;
    const handleBrowseClick = () => {
      fileInputRef.current.click(); // Trigger click event on file input
    };

    const handleFileChange = (e) => {
      // Handle file change event here
      const selectedFile = e.target.files[0];
      console.log("Selected,file", selectedFile.name);
      fileName = selectedFile.name;
      setValue("inputJsonFile", selectedFile);
      setFile(selectedFile?.name);
    };
    console.log("fileee", file);

    return (
      <div className="main-container">
        <div className="custom-dnd">
          <img src={UploadIcon} className="uploadIcon" alt="upload-icon" />

          <h6 className="custom-input-text">
            Choose a file or drag & drop it here
          </h6>
        </div>
        <div>
          <button
            type="button"
            onClick={handleBrowseClick}
            className="browse-file-button"
          >
            Browse File
          </button>
          <input
            accept=".json"
            type="file"
            multiple={false}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && <h6 className="custom-input-text">{file}</h6>}
      </div>
    );
  };
  const fileEncodingOptions = [
    {
      label: "UTF-8",
      value: "UTF-8",
    },
    {
      label: "UTF-16",
      value: "UTF-16",
    },
  ];
  const bracketStyleOptions = [
    {
      label: "Collapsed (braces on same line)",
      value: "COLLAPSE",
    },
    {
      label: "Expanded (braces on different line)",
      value: "EXPAND",
    },
  ];
  const indentationLevelOptions = [
    {
      value: "TWO_SPACES",
      label: "2 spaces per indent level",
    },
    {
      value: "THREE_SPACES",
      label: "3 spaces per indent level",
    },
    {
      value: "FOUR_SPACES",
      label: "4 spaces per indent level",
    },
    {
      value: "TABS",
      label: "Tab delimited",
    },
    {
      value: "COMPACT",
      label: "Compact (1 line)",
    },
    {
      value: "JAVASCRIPT",
      label: "JavaScript escaped",
    },
  ];

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused = false, isSelected }) => {
      // const color = chroma(data.color);
      console.log("isFocused", isFocused);
      return {
        ...styles,

        // backgroundColor: isDisabled
        //   ? undefined
        //   : isFocused
        //   ? "#BF66B6"
        //   : isSelected
        //   ? "#c00eae"
        //   : undefined,

        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#BF66B6"
          : isFocused
          ? "#c00eae"
          : undefined,

        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [inputJsonValue, setInputJsonValue] = useState();

  const [formattedJsonValue, setFormattedJsonValue] = useState("");

  const [escapedClicked, setEscapeClicked] = useState(false);

  const [isFileDropped, setIsFileDropped] = useState(false);

  function minifyJS(js) {
    // Remove single-line comments
    js = js.replace(/\/\/[^\n\r]*/g, "");

    // Remove multi-line comments
    js = js.replace(/\/\*[\s\S]*?\*\//g, "");

    // Remove unnecessary whitespace
    js = js.replace(/\s{2,}/g, " ");
    js = js.replace(/\s*([{}();,:])\s*/g, "$1");
    js = js.replace(/;\s*;/g, ";");

    // Remove leading and trailing whitespace
    js = js.trim();

    return js;
  }
  const JsMinifySubmit = () => {


     if (!inputJsonValue) {
       setError(true);
       setErrorMessage("JS is required");
       setSuccess(false);
     }
     else{

let escapedJson = minifyJS(inputJsonValue);

if (escapedJson === null) {
  setError(true);
  setEscapeClicked(false);
  setSuccess(false);
  setErrorMessage("Error JS minifying JS");
} else {
  setFormattedJsonValue(escapedJson);

  setEscapeClicked(false);
  setError(false);
  setSuccess(true);
  setSuccessMessage("JS minified Successfully");
  // Handle success as needed
  // setSuccessMessage("JSON escaped successfully");
}
     }

    
  };

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    setValue("inputJsonFile", file);

    body.append("dataFiles", file);
    return { url: "http://localhost:3000/uploadmultifile", body };
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, size, percent, status, previewUrl } = meta;
    // console.log("dropZOneFile.meta", meta);
    return (
      <div className="preview-box">
        <span className="name">{name}</span> -{" "}
        <span className="status">{size}KB</span>
      </div>
    );
  };

  // Function to format JSON data based on selected options
  const options = {
    selectOnLineNumbers: true,
    readOnlyCodeFolding: true,

    bracketPairColorization: 100,
    blockCommentFolding: true,
    fontSize: 16,
    minimap: {
      enabled: false,
    },
    contextmenu: false,
    createFoldingRangeFromSelection: true,
  };

  const stylesEditor = {
    dualView: {
      display: "flex",
    },
    jsonViewer: {
      borderLeft: "1px dashed white",
      lineHeight: 1.25,
      width: "50%",
      borderLeft: "1px solid lightgrey",
      margin: 10,
    },
    jsonEditor: {
      width: "50%",
      fontSize: 12,
      fontFamily: "Lucida Console, monospace",
      lineHeight: 1.25,
    },
    root: {
      fontSize: 12,
      fontFamily: "Lucida Console, monospace",
      lineHeight: 1.25,
      /*color: "#3E3D32"*/
    },
    label: {
      color: "DeepPink",
      marginTop: 3,
    },
    value: {
      marginLeft: 10,
    },
    row: {
      display: "flex",
    },
    withChildrenLabel: {
      color: "DeepPink",
    },
    select: {
      borderRadius: 3,
      borderColor: "grey",
      backgroundColor: "DimGray",
      color: "khaki",
    },
    input: {
      borderRadius: 3,
      border: "1px solid #272822",
      padding: 2,
      fontFamily: "Lucida Console, monospace",
      fontSize: 12,
      backgroundColor: "gray",
      color: "khaki",
      width: "200%",
    },
    addButton: {
      cursor: "pointer",
      color: "LightGreen",
      marginLeft: 15,
      fontSize: 12,
    },
    removeButton: {
      cursor: "pointer",
      color: "magenta",
      marginLeft: 15,
      fontSize: 12,
    },
    saveButton: {
      cursor: "pointer",
      color: "green",
      marginLeft: 15,
      fontSize: 12,
    },
    builtin: {
      color: "green",
      fontSize: 12,
    },
    text: {
      color: "black",
      fontSize: 12,
    },
    number: {
      color: "purple",
      fontSize: 12,
    },
    property: {
      color: "DeepPink",
      fontSize: 12,
    },
    collapseIcon: {
      cursor: "pointer",
      fontSize: 10,
      color: "teal",
    },
  };
  return (
    <div className="container">
      {error && (
        <div className="error-message">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setError(false)} />
          </button>
          {errorMessage}
        </div>
      )}
      {success && (
        <div className="success-message ">
          <button className="closeButton">
            <MdOutlineClose onClick={() => setSuccess(false)} />
          </button>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
            <div className="col-12 col-md-6 mobile-responsive-col left-container-padding">
              <div className="copy-paste">
                <label>Copy-paste the JS to minify here</label>
                <textarea
                  onChange={(e) => {
                    setInputJsonValue(e.target.value);
                    setValue("inputJsonData", e.target.value);
                  }}
                  placeholder="Copy-paste your JS here"
                  className="text-area-formatter"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 mobile-responsive-col">
              <div className="row mobile-res-row" id="editor_main_id">
                <div className="col-8">
                  <h6 className="formatted-heading">Minified JS</h6>
                </div>

                <div className="editor_holder_button col-4">
                  <button
                    onClick={copyJson}
                    type="button"
                    className="editor_holder_button_bg"
                  >
                    <BiCopyAlt />
                  </button>
                  <button
                    onClick={() => {
                      if (formattedJsonValue != "") {
                        downloadJson();
                      }
                    }}
                    type="button"
                    className="editor_holder_button_bg ms-3"
                  >
                    <RiFileDownloadFill />
                  </button>
                </div>
              </div>

              <div className="editor-border-minifier">
                <div className="max-h-60   react-json-editor ">
                  {isFileDropped ? (
                    <ReactJson
                      displayArrayKey={false}
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      indentWidth={indentationLevel || 3}
                      src={formattedJsonValue}
                      collapsed={bracketStylesView}
                      name={false}
                    />
                  ) : (
                    <Editor
                      value={formattedJsonValue}
                      onValueChange={(code) => setFormattedJsonValue(code)}
                      highlight={(code) => highlight(code, languages.js)}
                      padding={10}
                      readOnly={true}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        // border: "2px solid ",
                        borderColor: "#e4e4e4",
                        borderRadius: "10px",
                        minHeight: "80%",
                      }}
                    />
                  )}
                </div>
              </div>
              {/* <Editor
                value={formattedJsonValue}
                onValueChange={(code) => setFormattedJsonValue(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                readOnly
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: "2px solid ",
                  borderColor: "#e4e4e4",
                  borderRadius: "10px",
                  minHeight: "80%",
                }}
              />*/}
            </div>
          </div>
          <div className="button-section-2 row">
            <div className="col-span-1  col-12 col-md-6  py-2 text-center">
              <button
                className="format-btn-minify"
                onClick={JsMinifySubmit}
                type="submit"
              >
                Minify
              </button>
            </div>
          </div>
        </Card>
      </form>

      <div className="main-content-container">
        <div className="sub-heading">
          <div className="heading-formatter">
            <h4 className="heading-formatter-text">JS Minify </h4>
            <p className="heading-paragraph">
              This tool compresses JavaScript files by removing unnecessary
              characters, such as whitespace, comments, and line breaks, without
              altering the functionality of the JavaScript code. Minifying
              JavaScript files is crucial for improving web performance as it
              reduces file size, thereby decreasing page load times.
            </p>
          </div>
          <h6 className="main-heading">About the SDE Tools Editor</h6>
          <p className="main-heading-paragraph">
            The SDE Tools Editor is a flexible code editor designed specifically
            for software development. It offers various features and programming
            languages such as formatting tools integrations, highlighting
            syntax, and completion of code. Additionally, it also provides
            built-in integrated converters for different formats that helps you
            in exchange of your data by easily converting your data to various
            formats like JSON, XML, CSV, and YAML. SDE Tools Editor helps you in
            converting timestamps to date using Epoch and Unix Timestamp
            Converter. Now, with the assistance of QR Code Generator, you can
            easily generate QR Code by just providing the URL or text that you
            desire to encode. The seamless difference checker lets you quickly
            identify the difference between your input code enabling you to keep
            track of changes in the version.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">What Is JSON?</h6>
          <p className="main-heading-paragraph">
            Java Script Object Notation (JSON) is an open lightweight data
            format used for storing and transferring information through
            human-readable text. It is an independent language format for the
            interchange. It depends on JavaScript object literal syntax, however
            is not language-specific. This makes it easier for different
            programming languages to analyze and process the data.
          </p>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">Why Use JSON?</h6>
          <p className="main-heading-paragraph">
            JSON serves as a great tool for exchanging the data. There are
            several reasons that justifies its use:
          </p>
          <ul className="sub-list-ul">
            <li>
              <strong>Simplicity:</strong> Being a lightweight data formatter,
              it is very straightforward for transmitting the data easily.
            </li>
            <li>
              <strong>Readability:</strong> Simple structure and plain text
              makes it very easy for humans to read.
            </li>
            <li>
              <strong>Variety:</strong> Tons of JSON libraries are available for
              programming as well as it includes different arrays, strings, data
              structure and objects.
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h6 className="main-heading">
            Why use SDE Tools Validator and Formatter?
          </h6>
          <p className="main-heading-paragraph">
            It automatically formats your code in accordance with the coding
            standards. SDE Tools offers you various advantages:
          </p>

          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                It helps in increasing the productivity as it provides you
                features like code completion and highlighting the syntax
                minimizing errors.
              </li>
              <li>
                To fit your coding style, the editor lets you adjust formatting
                choices and handles several file encodings.
              </li>

              <li>
                It enhances the readability by providing clarity to all the
                developers.
              </li>
            </ul>
          </p>
        </div>

        <div className="sub-heading">
          <h6 className="main-heading">How does a SDE Tools Editor work?</h6>
          <p className="main-heading-paragraph">
            The validator functions similarly to a computerized code reviewer.
            It goes line by line through your code. It checks:
          </p>
          <p className="main-heading-paragraph">
            <ul className="sub-list-ul">
              <li>
                Mistakes such as mismatched braces, improper function calls, and
                misplaced semicolons.
              </li>
              <li>
                The coding style differentiation such as indentation spaces.
              </li>

              <li>
                A report is provided by the validator which includes identified
                problems and enables you to fix those problems.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default JsMinifier;
