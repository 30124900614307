// src/components/Loader.js
import React from "react";
import "./Loader.css"; // Import the CSS file for styling
 
import Logo from "../../assets/Logo/logo.png";
const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <img src={Logo} alt="Logo" className="logo" />
    </div>
  );
};

export default Loader;
