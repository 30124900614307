import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import { LuText, LuUtilityPole } from "react-icons/lu";
import { SiCss3 } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { Helmet } from "react-helmet";
 import { RiTimeZoneLine } from "react-icons/ri";
import BlogSlider from "../blogs/blog-slider/BlogSlider";
const Dashboard = ({ isSidebarOpen }) => {
 


    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div
      className={`${
        isSidebarOpen ? "container container-sm " : " container-lg"
      }   `}
    >
      <Helmet>
        <meta charset="UTF-8" />

        <title>
          Free Online Code Editor, Formatter and Converter | SDE Tools
        </title>
        <meta
          name="description"
          content="SDE Tools is the best free online code-editor tool to effortlessly format, edit, and convert your code across various formats including HTML, JSON, CSV, & more!"
        />

        <meta
          name="keywords"
          content="Code Editor Tool, Free Code Editor Tool, Online Code Editor Tool, SDE Tools"
        />

        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://sdetools.io/" />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SDE Tools" />
        <meta
          property="og:title"
          content="Free Online Code Editor, Formatter and Converter | SDE Tools"
        />
        <meta
          property="og:description"
          content="SDE Tools is the best free online code-editor tool to effortlessly format, edit, and convert your code across various formats including HTML, JSON, CSV, & more!"
        />
        <meta property="og:url" content="https://sdetools.io/" />
      </Helmet>
      <div className="heading-formatter">
        <h1
          style={{ textTransform: "uppercase" }}
          className="heading-formatter-text"
        >
          Best Code Formatter & Converter Online
        </h1>
        <p className="heading-paragraph">
          SDE Tools is a flexible code editor designed specifically for web and
          software developers. It offers various features like formatting tools
          integrations, highlighting syntax, and completion of code across
          various programming languages. Additionally, it also provides
          integrated converters for different formats that helps you in exchange
          of your data by easily converting your data to various formats like
          JSON, XML, CSV, and YAML. SDE Tools Editor also helps you in
          converting timestamps to date using Epoch and Unix Timestamp
          Converter. Not just that, with the assistance of QR Code Generator of
          SDE Tools, you can easily generate QR Code by just providing the URL
          or text that you desire to encode. Go ahead and explore SDE Tools -
          The Best Code Formatter & Converter Online!
        </p>
      </div>
      <br />

      <h4
        style={{ textTransform: "uppercase" }}
        className="heading-formatter-text mb-10 uppercase"
      >
        Formatter Tools
      </h4>
      <div className="row mb-30">
        <div className="col-6 col-md-3">
          <Link to="/formatter/json-formatter">
            <Card className="dashboard-tools-card background1 card-formatter dashboard-card border border-0 card rounded-md  shadow shadow-sm">
              {/* <div className="tools-icon-heading"> */}
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <path d="M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1"></path>
                  <path d="M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1"></path>
                </svg>
              </span>
              <h5
                style={{ textTransform: "uppercase" }}
                className="tools-heading-text "
              >
                JSON Formatter
              </h5>
              {/* </div> */}
              {/* <p className="heading-paragraph tools-description">
                          Formats a JSON string or file with the chosen indentation level, creating a tree object with color highlights.
                      </p>
                      <Link to="/your-link">
                          <button className="tools-btn">Go to Link</button>
                      </Link> */}
            </Card>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link to="/formatter/xml-formatter">
            <Card className="dashboard-tools-card background2 dashboard-card card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999zm4.71-.674h1.696v.674H8.4V11.85h.791z"
                  ></path>
                </svg>
              </span>
              <h5
                style={{ textTransform: "uppercase" }}
                className="tools-heading-text"
              >
                XML Formatter
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 mt-3  mt-md-0  col-md-3">
          <Link to="/formatter/html-formatter">
            <Card className="dashboard-tools-card background3 dashboard-card card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                HTML Formatter
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 mt-3  mt-md-0  col-md-3">
          <Link to="/formatter/sql-formatter">
            <Card className="dashboard-tools-card background4 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM0 14.841a1.13 1.13 0 0 0 .401.823q.194.162.478.252c.284.09.411.091.665.091q.507 0 .858-.158.355-.159.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.187-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.776.15-.337.149-.528.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.325q0 .165-.085.29A.56.56 0 0 1 2 15.31q-.167.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zm6.878 1.489-.507-.739q.264-.243.401-.6.138-.358.138-.806v-.501q0-.556-.208-.967a1.5 1.5 0 0 0-.589-.636q-.383-.225-.917-.225-.527 0-.914.225-.384.223-.592.636a2.14 2.14 0 0 0-.205.967v.5q0 .554.205.965.208.41.592.636a1.8 1.8 0 0 0 .914.222 1.8 1.8 0 0 0 .6-.1l.294.422h.788ZM4.262 14.2v-.522q0-.369.114-.63a.9.9 0 0 1 .325-.398.9.9 0 0 1 .495-.138q.288 0 .495.138a.9.9 0 0 1 .325.398q.115.261.115.63v.522q0 .246-.053.445-.053.196-.155.34l-.106-.14-.105-.147h-.733l.451.65a.6.6 0 0 1-.251.047.87.87 0 0 1-.487-.147.9.9 0 0 1-.32-.404 1.7 1.7 0 0 1-.11-.644m3.986 1.057h1.696v.674H7.457v-3.999h.79z"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                SQL Formatter
              </h5>
            </Card>
          </Link>
        </div>
      </div>

      {/* <Card className="dashboard-tools-card card-formatter border border-0 card rounded-md bg-white shadow shadow-sm">
          <div className="row">
              <h4 className="heading-formatter-text mb-10">Our Formatter Tools</h4>
              <div className="col-3">
                  <div className="card">
                      <span className="dashboard-sub-icons"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><path d="M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1"></path><path d="M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1"></path></svg></span>
                      <h5 className="tools-heading-text">JSON Formatter</h5>
                  </div>
              </div>
              <div className="col-3">
                  <div className="card">
                      <span className="dashboard-sub-icons"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><path d="M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1"></path><path d="M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1"></path></svg></span>
                      <h5 className="tools-heading-text">JSON Formatter</h5>
                  </div>
              </div>
              <div className="col-3">
                  <div className="card">
                      <span className="dashboard-sub-icons"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><path d="M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1"></path><path d="M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1"></path></svg></span>
                      <h5 className="tools-heading-text">JSON Formatter</h5>
                  </div>
              </div>
              <div className="col-3">
                  <div className="card">
                      <span className="dashboard-sub-icons"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><path d="M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1"></path><path d="M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1"></path></svg></span>
                      <h5 className="tools-heading-text">JSON Formatter</h5>
                  </div>
              </div>
          </div>
      </Card> */}

      <h4
        style={{ textTransform: "uppercase" }}
        className="heading-formatter-text mb-10"
      >
        Converter Tools
      </h4>
      <div className="row ">
        <div className="col-6 col-md-3">
          <Link to="/converters/json-xml-converter">
            <Card className="dashboard-tools-card background1 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999zm4.71-.674h1.696v.674H8.4V11.85h.791z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                JSON to XML
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link to="/converters/xml-json-converter">
            <Card className="dashboard-tools-card background2 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM4.151 15.29a1.2 1.2 0 0 1-.111-.449h.764a.58.58 0 0 0 .255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .084-.29.39.39 0 0 0-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.352-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.246-.181.9.9 0 0 0-.37-.068q-.324 0-.512.152a.47.47 0 0 0-.185.384q0 .18.144.3a1 1 0 0 0 .404.175l.621.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-3.104-.033a1.3 1.3 0 0 1-.082-.466h.764a.6.6 0 0 0 .074.27.5.5 0 0 0 .454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 0 1-.568-.094 1.15 1.15 0 0 1-.407-.266 1.1 1.1 0 0 1-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.47.126.9.9 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .47-.129q.265 0 .47.129a.86.86 0 0 1 .322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 0 0-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 0 0-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                XML to JSON
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 mt-3  mt-md-0   col-md-3">
          <Link to="/converters/csv-json-converter">
            <Card className="dashboard-tools-card background3 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM4.151 15.29a1.2 1.2 0 0 1-.111-.449h.764a.58.58 0 0 0 .255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .084-.29.39.39 0 0 0-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.352-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.246-.181.9.9 0 0 0-.37-.068q-.324 0-.512.152a.47.47 0 0 0-.185.384q0 .18.144.3a1 1 0 0 0 .404.175l.621.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-3.104-.033a1.3 1.3 0 0 1-.082-.466h.764a.6.6 0 0 0 .074.27.5.5 0 0 0 .454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 0 1-.568-.094 1.15 1.15 0 0 1-.407-.266 1.1 1.1 0 0 1-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.47.126.9.9 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .47-.129q.265 0 .47.129a.86.86 0 0 1 .322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 0 0-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 0 0-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                CSV to JSON
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6  mt-3   mt-md-0  col-md-3">
          <Link to="/converters/csv-xml-converter">
            <Card className="dashboard-tools-card background4 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999zm4.71-.674h1.696v.674H8.4V11.85h.791z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                CSV to XML
              </h5>
            </Card>
          </Link>
        </div>
      </div>
      <div className="row mb-30 mt-3  mt-md-0">
        <div className="col-md-3 mt-md-3 col-6">
          <Link to="/converters/json-yaml-converter">
            <Card className="dashboard-tools-card background5 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  role="img"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m0 .97 4.111 6.453v4.09h2.638v-4.09L11.053.969H8.214L5.58 5.125 2.965.969Zm12.093.024-4.47 10.544h2.114l.97-2.345h4.775l.804 2.345h2.26L14.255.994Zm1.133 2.225 1.463 3.87h-3.096zm3.06 9.475v10.29H24v-2.199h-5.454v-8.091zm-12.175.002v10.335h2.217v-7.129l2.32 4.792h1.746l2.4-4.96v7.295h2.127V12.696h-2.904L9.44 17.37l-2.455-4.674Z"></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                JSON to YAML
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-md-3 mt-md-3 col-6">
          <Link to="/converters/yaml-json-converter">
            <Card className="dashboard-tools-card background6 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM4.151 15.29a1.2 1.2 0 0 1-.111-.449h.764a.58.58 0 0 0 .255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .084-.29.39.39 0 0 0-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.352-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.246-.181.9.9 0 0 0-.37-.068q-.324 0-.512.152a.47.47 0 0 0-.185.384q0 .18.144.3a1 1 0 0 0 .404.175l.621.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-3.104-.033a1.3 1.3 0 0 1-.082-.466h.764a.6.6 0 0 0 .074.27.5.5 0 0 0 .454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 0 1-.568-.094 1.15 1.15 0 0 1-.407-.266 1.1 1.1 0 0 1-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.47.126.9.9 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .47-.129q.265 0 .47.129a.86.86 0 0 1 .322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 0 0-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 0 0-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z"
                  ></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                YAML to JSON
              </h5>
            </Card>
          </Link>
        </div>
      </div>

      <h4
        style={{ textTransform: "uppercase" }}
        className="heading-formatter-text mb-10"
      >
        Generator Tools
      </h4>
      <div className="row mb-30">
        <div className="col-md-3 col-6">
          <Link to="/generator/qr-code-generator">
            <Card className="dashboard-tools-card background1 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="80"
                    height="80"
                    x="336"
                    y="336"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="64"
                    height="64"
                    x="272"
                    y="272"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="64"
                    height="64"
                    x="416"
                    y="416"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="48"
                    height="48"
                    x="432"
                    y="272"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="48"
                    height="48"
                    x="272"
                    y="432"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="80"
                    height="80"
                    x="336"
                    y="96"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="176"
                    height="176"
                    x="288"
                    y="48"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    rx="16"
                    ry="16"
                  ></rect>
                  <rect
                    width="80"
                    height="80"
                    x="96"
                    y="96"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="176"
                    height="176"
                    x="48"
                    y="48"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    rx="16"
                    ry="16"
                  ></rect>
                  <rect
                    width="80"
                    height="80"
                    x="96"
                    y="336"
                    rx="8"
                    ry="8"
                  ></rect>
                  <rect
                    width="176"
                    height="176"
                    x="48"
                    y="288"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    rx="16"
                    ry="16"
                  ></rect>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                QR Code
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-md-3 col-6">
          <Link to="/generator/random-number-generator">
            <Card className="dashboard-tools-card background2 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 21h-4v-4"></path>
                  <path d="M16 21l5 -5"></path>
                  <path d="M6.5 9.504l-3.5 -2l2 -3.504"></path>
                  <path d="M3 7.504l6.83 -1.87"></path>
                  <path d="M4 16l4 -1l1 4"></path>
                  <path d="M8 15l-3.5 6"></path>
                  <path d="M21 5l-.5 4l-4 -.5"></path>
                  <path d="M20.5 9l-4.5 -5.5"></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Random Number
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-md-3 mt-3  mt-md-0  col-6">
          <Link to="/generator/difference-checker">
            <Card className="dashboard-tools-card background3 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M18 23H4c-1.1 0-2-.9-2-2V7h2v14h14v2zM14.5 7V5h-2v2h-2v2h2v2h2V9h2V7h-2zm2 6h-6v2h6v-2zM15 1H8c-1.1 0-1.99.9-1.99 2L6 17c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V7l-6-6zm4 16H8V3h6.17L19 7.83V17z"></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Difference Checker
              </h5>
            </Card>
          </Link>
        </div>

        <div className="col-md-3 mt-3  mt-md-0  col-6">
          <Link to="/generator/lorem-ipsum">
            <Card className="dashboard-tools-card background1 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <LuText />
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Lorem Ipsum
              </h5>
            </Card>
          </Link>
        </div>
      </div>

      <h4
        style={{ textTransform: "uppercase" }}
        className="heading-formatter-text mb-10"
      >
        Date Time Tools
      </h4>
      <div className="row mb-30">
        <div className="col-6 col-md-3">
          <Link to="/converters/epoch-timestamp-to-date-converter">
            <Card className="dashboard-tools-card background1 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Calendar_Date">
                    <path
                      d="M18.435,4.955h-1.94v-1.41c0-0.26-0.23-0.51-0.5-0.5c-0.27,0.01-0.5,0.22-0.5,0.5v1.41h-7v-1.41
                c0-0.26-0.23-0.51-0.5-0.5c-0.27,0.01-0.5,0.22-0.5,0.5v1.41h-1.93c-1.38,0-2.5,1.12-2.5,2.5v11c0,1.38,1.12,2.5,2.5,2.5h12.87
                c1.38,0,2.5-1.12,2.5-2.5v-11C20.935,6.075,19.815,4.955,18.435,4.955z M19.935,18.455c0,0.83-0.67,1.5-1.5,1.5H5.565
                c-0.83,0-1.5-0.67-1.5-1.5v-8.42h15.87V18.455z M19.935,9.035H4.065v-1.58c0-0.83,0.67-1.5,1.5-1.5h1.93v0.59
                c0,0.26,0.23,0.51,0.5,0.5c0.27-0.01,0.5-0.22,0.5-0.5v-0.59h7v0.59c0,0.26,0.23,0.51,0.5,0.5c0.27-0.01,0.5-0.22,0.5-0.5v-0.59
                h1.94c0.83,0,1.5,0.67,1.5,1.5V9.035z"
                    ></path>
                    <path
                      d="M11.492,17.173v-3.46c0-0.059-0.064-0.095-0.114-0.064l-0.638,0.392
                c-0.1,0.061-0.228-0.01-0.228-0.128v-0.651c0-0.105,0.055-0.203,0.146-0.257l0.764-0.457c0.047-0.028,0.1-0.043,0.154-0.043h0.626
                c0.166,0,0.3,0.134,0.3,0.3v4.367c0,0.166-0.134,0.3-0.3,0.3h-0.409C11.626,17.473,11.492,17.339,11.492,17.173z"
                    ></path>
                  </g>
                </svg>
              </span>
              <h5
                className="tools-heading-text "
                style={{ textTransform: "uppercase" }}
              >
                Datetime Converter
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6   mt-md-0  col-md-3">
          <Link to="/date-time/online-stop-watch">
            <Card className="dashboard-tools-card background2 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M398.5,190.91l.59-.61,26.59-26.58a16,16,0,0,0,0-22.63L403,118.41a16,16,0,0,0-22.63,0l-24.68,24.68A206.68,206.68,0,0,0,256,98.5V64h32a16,16,0,0,0,16-16V16A16,16,0,0,0,288,0H160a16.05,16.05,0,0,0-16,16V48a16.05,16.05,0,0,0,16,16h32V98.5A207.92,207.92,0,0,0,16.09,297.57C12.64,411.5,106.76,510.22,220.72,512,337.13,513.77,432,420,432,304A206,206,0,0,0,398.5,190.91ZM204.37,377.55a8.2,8.2,0,0,1,8.32,8.07v22.31a8.2,8.2,0,0,1-8.32,8.07H121.52a16.46,16.46,0,0,1-16.61-17.62c2.78-35.22,14.67-57.41,38.45-91.37,20.42-29.19,27.1-37.32,27.1-62.34,0-16.92-1.79-24.27-12.21-24.27-9.39,0-12.69,7.4-12.69,22.68v5.23a8.2,8.2,0,0,1-8.33,8.07h-24.9a8.2,8.2,0,0,1-8.33-8.07v-4.07c0-27.3,8.48-60.24,56.43-60.24,43,0,55.57,25.85,55.57,61,0,35.58-12.44,51.21-34.35,81.31-11.56,15-24.61,35.57-26.41,51.2ZM344,352.32c0,35.16-12.3,63.68-57.23,63.68C243.19,416,232,386.48,232,352.55V247.22c0-40.73,19.58-63.22,56.2-63.22C325,184,344,206.64,344,245.3ZM287.87,221.73c-9.41,0-13.23,7.5-13.23,20V357.68c0,13.11,3.59,20.59,13.23,20.59s13-8,13-21.27V241.06C300.89,229.79,297.88,221.73,287.87,221.73Z"></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Online Stopwatch
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 mt-3  mt-md-0  col-md-3">
          <Link to="/date-time/online-count-down">
            <Card className="dashboard-tools-card background3 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  version="1.1"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 3.019v-1.019h2v-1c0-0.552-0.448-1-1-1h-3c-0.552 0-1 0.448-1 1v1h2v1.019c-3.356 0.255-6 3.059-6 6.481 0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5c0-3.422-2.644-6.226-6-6.481zM11.036 13.036c-0.944 0.944-2.2 1.464-3.536 1.464s-2.591-0.52-3.536-1.464c-0.944-0.944-1.464-2.2-1.464-3.536s0.52-2.591 1.464-3.536c0.907-0.907 2.101-1.422 3.377-1.462l-0.339 4.907c-0.029 0.411 0.195 0.591 0.497 0.591s0.527-0.18 0.497-0.591l-0.339-4.907c1.276 0.040 2.47 0.555 3.377 1.462 0.944 0.944 1.464 2.2 1.464 3.536s-0.52 2.591-1.464 3.536z"></path>
                </svg>
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Online Counter
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 mt-3  mt-md-0  col-md-3">
          <Link to="/date-time/time-zone-converter">
            <Card className="dashboard-tools-card background4 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <RiTimeZoneLine />
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Time Zone Converter
              </h5>
            </Card>
          </Link>
        </div>
      </div>
      <h4
        style={{ textTransform: "uppercase" }}
        className="heading-formatter-text mb-10"
      >
        Escaper
      </h4>
      <div className="row mb-30">
        <div className="col-6 col-md-3">
          <Link to="/escaper/json-escaper">
            <Card className="dashboard-tools-card background1 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <LuUtilityPole />
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                JSON Escaper
              </h5>
            </Card>
          </Link>
        </div>
      </div>
      <h4
        style={{ textTransform: "uppercase" }}
        className="heading-formatter-text mb-10"
      >
        Minify
      </h4>
      <div className="row mb-30">
        <div className="col-6 col-md-3">
          <Link to="/minify/css-minify">
            <Card className="dashboard-tools-card background1  card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <SiCss3 />
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                CSS Minify
              </h5>
            </Card>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link to="/minify/js-minify">
            <Card className="dashboard-tools-card background2 card-formatter dashboard-card border border-0 card rounded-md bg-white shadow shadow-sm">
              <span className="dashboard-sub-icons">
                <IoLogoJavascript />
              </span>
              <h5
                className="tools-heading-text"
                style={{ textTransform: "uppercase" }}
              >
                Js Minify
              </h5>
            </Card>
          </Link>
        </div>
      </div>
       <div className="row">
        <BlogSlider />
      </div> 
    </div>
  );
};

export default Dashboard